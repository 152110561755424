import { Components, Theme } from "@mui/material";

export const muiButton: Components<Omit<Theme, "components">> = {
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      variant: "contained"
    },
    variants: [
      {
        props: { variant: "subtle" },
        style: ({ theme }) => ({
          color: theme.palette.text.primary,
          backgroundColor: "rgba(0, 0, 0, 0.08)",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.11)"
          }
        })
      },
      {
        props: { variant: "subtle", size: "small" },
        style: ({ theme }) => ({
          padding: "4px 10px",
          fontSize: theme.typography.pxToRem(13)
        })
      },
      {
        props: { variant: "subtle", size: "large" },
        style: ({ theme }) => ({
          padding: "8px 22px",
          fontSize: theme.typography.pxToRem(15)
        })
      },
      {
        props: { variant: "serious" },
        style: ({ theme }) => ({
          color: "var(--variant-containedColor)",
          "&.Mui-disabled": {
            opacity: 0.3,
            color: "var(--variant-containedColor)"
          },
          backgroundColor: theme.palette.error.main,
          "&:hover": {
            backgroundColor: theme.palette.error.dark
          },
          "&:focus-visible": {
            outlineOffset: "2px"
          }
        })
      },
      {
        props: { variant: "serious", size: "small" },
        style: ({ theme }) => ({
          padding: "4px 10px",
          fontSize: theme.typography.pxToRem(13)
        })
      },
      {
        props: { variant: "serious", size: "large" },
        style: ({ theme }) => ({
          padding: "8px 22px",
          fontSize: theme.typography.pxToRem(15)
        })
      }
    ],
    styleOverrides: {
      root: ({ theme }) => ({
        fontWeight: 400,
        borderRadius: "6px",
        "&.MuiButton-containedPrimary.Mui-disabled": {
          background: "#BDD3FF",
          color: "white"
        },
        "&:focus-visible": {
          outline: `4px solid ${theme.palette.darkBlue.main}`
        }
      }),
      contained: {
        "&:focus-visible": {
          outlineOffset: "2px"
        }
      }
    }
  }
};
