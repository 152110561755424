import { Box, FormGroup } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import { ProgressButton } from "@qubit/autoparts";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PickingConfigurationOption } from "~/api/warehouseTypes/fulfillmentCenter";
import { useAppDispatch, useAppSelector } from "~/app/store";

import envConstants from "~/config/envConstants";
import { ThisMachineInfo } from "~/features/status/ThisMachineInfo";
import { togglePTLSimulationEnabled } from "~/redux/actions/ptl";
import { toggleAutomatedOperationsEnabled } from "~/redux/actions/site";
import { getServerVersion } from "~/redux/actions/version";
import { toggleConveyanceSimEnabled } from "~/redux/reducers/conveyance";
import {
  selectUserIsAdmin,
  selectUsersProfileName
} from "~/redux/selectors/authSelectors";
import {
  selectClientConfig,
  selectLanguageCode
} from "~/redux/selectors/siteSelectors";

import { FulfillmentCenterSelector } from "./FulfillmentCenterSelector";
import { PrinterSettings } from "./PrinterSettings";
import SettingsPanel from "./SettingsPanel";
import SyncGridStateButton from "./SyncGridStateButton";
import { UserSettings } from "./UserSettings";
import {
  selectEnableDevInformation,
  toggleDevInfomation
} from "./devSettings.slice";

export const Settings = () => {
  const selectedFulfillmentCenter = useAppSelector(
    (state) => state.store.usersFulfillmentCenter
  );
  const clientConfig = useAppSelector(selectClientConfig);
  const ptlSimulationEnabled = useAppSelector(
    (state) => state.ptl.ptlSimulationEnabled
  );
  const conveyanceSimEnabled = useAppSelector(
    (state) => state.conveyance.conveyanceSimEnabled
  );
  const automatedOperationsEnabled = useAppSelector(
    (state) => state.site.automatedOperationsEnabled
  );
  const serverVersion = useAppSelector((state) => state.version.serverVersion);
  const usersName = useAppSelector(selectUsersProfileName);
  const loginPath = useAppSelector((state) => state.site.loginPath);
  const customLogoutRedirectPath = useAppSelector(
    (state) => state.site.customLoginPath
  );

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isAdmin = useAppSelector(selectUserIsAdmin);
  const devInformationEnabled = useAppSelector(selectEnableDevInformation);
  const languageCode = useAppSelector(selectLanguageCode);

  // show and hide beamer selector upon mount and dismount
  useEffect(() => {
    const intervalId = setInterval(() => {
      const beamerSelector = document.getElementById("beamerSelector");
      if (beamerSelector) {
        clearInterval(intervalId);
        if (isAdmin) {
          beamerSelector.style.display = "block";
        }
      }
    }, 100);

    return () => {
      clearInterval(intervalId);
      const beamerSelector = document.getElementById("beamerSelector");

      if (beamerSelector) {
        beamerSelector.style.display = "none";
      }
    };
  }, [isAdmin]);

  const {
    dev_orderCreation,
    dev_ptlSimulation,
    dev_conveyanceSimulation,
    dev_automatedOperations
  } = clientConfig;

  useEffect(() => {
    if (usersName?.includes("autostoresystem.com")) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      dispatch(getServerVersion());
    }
  }, [usersName, dispatch]);

  const isWorkstationConfigurer = useAppSelector(
    (state) => state.login.profile?.roles || []
  ).some((role) => role === "qubit-workstation-configurer");
  const areDevCheatsEnabled =
    envConstants.DEV_CHEATS_UAT === "true" ||
    envConstants.DEV_CHEATS_STAGING === "true";

  const showDevSection =
    dev_ptlSimulation ||
    dev_conveyanceSimulation ||
    dev_automatedOperations ||
    dev_orderCreation ||
    areDevCheatsEnabled;

  return (
    <Box
      id="settings-container"
      p={2}
      sx={{
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <Box
        id="settings-row-1"
        sx={{
          display: "flex",
          width: "100%"
        }}
      >
        <Box
          id="settings-row-1-left"
          sx={{
            width: "75%",
            boxSizing: "border-box",
            padding: "10px",
            display: "flex",
            flexDirection: "column"
          }}
        >
          {isWorkstationConfigurer && (
            <SettingsPanel panelTitle={t("fulfillment center")}>
              <Stack gap={2}>
                <FulfillmentCenterSelector />
                <SyncGridStateButton />
              </Stack>
            </SettingsPanel>
          )}

          {isWorkstationConfigurer && (
            <SettingsPanel panelTitle={t("this workstation")}>
              <Box sx={{ minHeight: "200px" }}>
                {selectedFulfillmentCenter?.pickingConfigurations.includes(
                  "Autostore" as PickingConfigurationOption
                ) && <ThisMachineInfo />}
              </Box>
            </SettingsPanel>
          )}
        </Box>

        <Box
          id="settings-row-1-right"
          style={{
            width: "25%",
            boxSizing: "border-box",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start"
          }}
        >
          <SettingsPanel panelTitle={t("nav.viewname.user settings")}>
            <UserSettings />
          </SettingsPanel>

          {usersName?.includes("autostoresystem.com") && (
            <SettingsPanel panelTitle={t("nav.viewname.version")}>
              <Box data-testid="version-info">
                <Typography>
                  Client Version:{" "}
                  {envConstants.VERSION_TAG || envConstants.VERSION}
                </Typography>
                <Typography>
                  Server Version: {serverVersion.tag || serverVersion.hash}
                </Typography>
              </Box>
            </SettingsPanel>
          )}
        </Box>
      </Box>

      <Box
        id="settings-row-2"
        sx={{
          width: "100%",
          display: "flex"
        }}
      >
        {isWorkstationConfigurer &&
          selectedFulfillmentCenter?.printerConfiguration ===
            "BrowserPrint" && (
            <Box
              id="settings-row-2-left"
              sx={{
                width: "50%",
                boxSizing: "border-box",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start"
              }}
            >
              <SettingsPanel panelTitle={t("nav.viewname.printer")}>
                <PrinterSettings />
              </SettingsPanel>
            </Box>
          )}

        {/* dev section */}
        {showDevSection && (
          <Box
            id="settings-row-2-right"
            sx={{
              width: "50%",
              boxSizing: "border-box",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start"
            }}
          >
            <SettingsPanel panelTitle={t("nav.viewname.dev")}>
              <Stack sx={{ width: "100%" }} gap={2}>
                <ProgressButton
                  variant="subtle"
                  onClick={() => navigate("/order-creation")}
                >
                  {t("order creation")}
                </ProgressButton>

                {areDevCheatsEnabled && isAdmin && (
                  <FormGroup>
                    <InputLabel htmlFor="dev-info-enabled">
                      {t("dev information enabled")}
                    </InputLabel>
                    <Switch
                      id="dev-info-enabled"
                      checked={devInformationEnabled}
                      onChange={(e) =>
                        dispatch(toggleDevInfomation(e.target.checked || false))
                      }
                    />
                  </FormGroup>
                )}
                {(envConstants.ENABLE_PTL_SIMULATION === "true" ||
                  dev_ptlSimulation) && (
                  <FormGroup>
                    <InputLabel htmlFor="ptl-simulation-enabled">
                      {t("ptl simulation enabled")}
                    </InputLabel>
                    <Switch
                      id="ptl-simulation-enabled"
                      checked={ptlSimulationEnabled}
                      onChange={() => dispatch(togglePTLSimulationEnabled())}
                    />
                  </FormGroup>
                )}
                {(envConstants.ENABLE_CONVEYANCE_SIMULATION === "true" ||
                  dev_conveyanceSimulation) && (
                  <FormGroup>
                    <InputLabel htmlFor="conveyance-simulation-enabled">
                      {t("conveyance simulation enabled")}
                    </InputLabel>
                    <Switch
                      id="conveyance-simulation-enabled"
                      checked={conveyanceSimEnabled}
                      onChange={() => dispatch(toggleConveyanceSimEnabled())}
                    />
                  </FormGroup>
                )}
                {(envConstants.ENABLE_AUTOMATED_OPERATIONS ||
                  dev_automatedOperations) && (
                  <FormGroup>
                    <InputLabel htmlFor="automated-picking-enabled">
                      {t("automated operations enabled")}
                    </InputLabel>
                    <Switch
                      id="automated-picking-enabled"
                      checked={automatedOperationsEnabled}
                      onChange={() =>
                        dispatch(toggleAutomatedOperationsEnabled())
                      }
                    />
                  </FormGroup>
                )}
                {/* Enable i18next cimode to show only translation keys */}
                <FormGroup>
                  <InputLabel htmlFor="show-translation-keys">
                    {t("show translation keys")}
                  </InputLabel>
                  <Switch
                    id="show-translation-keys"
                    checked={i18n.language === "cimode"}
                    onChange={() => {
                      const newLanguage =
                        i18n.language !== "cimode" ? "cimode" : languageCode;
                      void i18n.changeLanguage(newLanguage);
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <InputLabel htmlFor="login-path">
                    {t("login path")}
                  </InputLabel>
                  <Box p={1}>
                    <Typography id="login-path">{loginPath}</Typography>
                  </Box>
                </FormGroup>
                <FormGroup>
                  <InputLabel htmlFor="custom-logout-redirect-path">
                    {t("custom logout redirect path")}
                  </InputLabel>
                  <Box p={1}>
                    <Typography id="custom-logout-redirect-path">
                      {customLogoutRedirectPath || ""}
                    </Typography>
                  </Box>
                </FormGroup>
              </Stack>
            </SettingsPanel>
          </Box>
        )}
      </Box>
    </Box>
  );
};
