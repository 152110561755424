import { Add } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  TextField,
  Autocomplete,
  Skeleton,
  Pagination,
  useMediaQuery,
  Chip,
  Stack,
  DialogTitle
} from "@mui/material";
import {
  autostoreTheme,
  ProgressButton,
  BigChoiceModal,
  ConfirmationModal,
  formatUtcDate,
  ScanningIndicator,
  useScanIndicator,
  AutostoreTable,
  AlertBanner
} from "@qubit/autoparts";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import { getUserClientId, isClientAdmin } from "~/api/usersTypes/auth0Profile";
import { warehouseService } from "~/api/warehouse";
import { useAppDispatch, useAppSelector } from "~/app/store";

import useFlag from "~/config/flags";
import { createProductSearchOptions } from "~/config/searchProductConfig";
import { AutostoreBin } from "~/features/autostoreBin";
import { CycleCountFrequencyModal } from "~/features/inventory/components/modals/CycleCountFrequencyModal";
import { InventoryAddDialog } from "~/features/inventoryAddDialog/InventoryAddDialog";
import InventoryAdjustDialog, {
  InventoryToModify
} from "~/features/inventoryAdjustDialog/InventoryAdjustDialog";
import { InventoryHoldModal } from "~/features/inventoryHoldModal/InventoryHoldModal";
import { InventoryProductCard } from "~/features/inventoryProductCard/InventoryProductCard";

import { useCloseWorkstationWithErrorToast } from "~/hooks/useCloseWorkstationWithErrorToast";
import { useDevCheats } from "~/hooks/useDevCheats";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";
import { useShouldListenToGridEvents } from "~/hooks/useShouldListenToGridEvents";
import { useBarcodeScanner, useKeyDownHandler } from "~/lib/barCodeScan";

import {
  generateLocationNameFromBin,
  generateLocationNameFromInventorySummary,
  ternaryIff,
  inventoryDateLabel,
  temperatureZoneColors,
  checkIsExpiration,
  getVariantDisplayNameByDtoFe,
  isAutostoreView,
  getAxiosErrorMessage,
  getHoldTypeOptions,
  formatHoldText,
  searchProduct,
  HoldType
} from "~/lib/helpers";
import { useGridV2Subscription } from "~/lib/signalr";
import usePromiseInterval from "~/lib/usePromiseIntervalEffect";
import {
  fetchPortStatus,
  openAutostoreBin,
  getNextBin,
  createBinRequest,
  closeBin,
  closePort,
  GetPortResponse,
  openPort,
  binAtPortEvent,
  binNotAtPort,
  fetchBinLogPublisherState,
  nextEmptyBin,
  openPortByContentCode,
  setPortStatus,
  setEmptyBinBtnState,
  setSelectedAutostoreBinId,
  setNoMoreBins
} from "~/redux/actions/autostore";
import { TempZone } from "~/redux/actions/batch";
import { getCycleCountFrequencyByVid } from "~/redux/actions/cycleCounts";
import {
  getInventorySummaries,
  setSelectedInventoryId,
  getVariantByVariantId,
  getSlottingInfo,
  getBinById,
  getSlotBinById,
  adjustInventory,
  clearSelectedVariant,
  clearSelectedInventoryId,
  clearInventory,
  updateSlottingInfo,
  createBinSlot,
  clearSlotBin,
  getInventoryReport,
  findInventoryByAutostoreBinNumber,
  placeInventoryHold,
  clearInventoryToModify
} from "~/redux/actions/inventory";
import { setUserMessage } from "~/redux/actions/site";
import { InventoryHoldReasonCode } from "~/redux/public/inventory.openApi";
import { StoreState } from "~/redux/reducers";
import {
  selectInventorySummaryToDisplay,
  selectSelectedInventoryAtPort
} from "~/redux/selectors/inventoryOldSelectors";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { useGetBinConfigurationsQuery } from "~/redux/warehouse/bin.hooks";
import {
  SearchBinRecord,
  AutostoreBinConfigurationDto,
  OpenBinByIdResponse,
  InventorySummaryDto,
  InventoryDto,
  AutostoreEvent
} from "~/types/api";

import { InventoryOldSearch } from "./InventoryOldSearch";
import { InventoryOldToolbar } from "./InventoryOldToolbar";
import { createBinSearchOptions } from "./createBinSearchOptions";
import {
  selectRows,
  selectSummaries,
  setGetBinsIndex,
  setIsAddPanelOpen,
  setIsAdjustingBins,
  setIsAdjustPanelOpen,
  setIsBinComponentShown,
  setIsBinHoldModalOpen,
  setIsFetchingBin,
  setPortOpened,
  setPortPollingActive
} from "./inventoryOld.slice";
import { useSearchBinsByText } from "./useSearchBinsByText";
import { getViewType } from "./viewType";

const mapStateToProps = (state: StoreState) => ({
  inventoryAtPort: state.inventory.inventory,
  inventorySummaries: state.inventory.inventorySummaries,
  loadingInventorySummaries: state.inventory.loadingInventorySummaries,
  inventorySummariesCount: state.inventory.inventorySummariesCount,
  inv_inventoryDateLabel: state.site.clientConfig.inv_inventoryDateLabel,
  loadingBin: state.inventory.loadingBin,
  loadingSlottingInfo: state.inventory.loadingSlottingInfo,
  selectedBin: state.inventory.bin,
  slotBin: state.inventory.slotBin,
  selectedInventoryId: state.inventory.selectedInventoryId,
  selectedAutostoreGridId: state.workstations.siteWorkstation?.autostoreGridId,
  selectedPortId: state.workstations.sitePortId,
  selectedVariant: state.inventory.selectedVariant,
  binSearchRecords: state.inventory.binSearchRecords,
  slottingInfo: state.inventory.slottingInfo,
  clientId: getUserClientId(state.login.profile),
  isAdmin: state.login.profile ? isClientAdmin(state.login.profile) : false,
  portState: state.autostore.portState,
  selectedAutostoreBinId: state.autostore.selectedAutostoreBinId,
  requestedAutostoreBin: state.autostore.requestedAutostoreBin || null,
  binAtPort: state.autostore.binAtPort,
  inventoryReports: state.inventory.inventoryReport,
  usersFulfillmentCenter: state.store.usersFulfillmentCenter,
  currentEmptyBin: state.autostore.currentEmptyBin,
  getEmptyBinBtn: state.autostore.getEmptyBinBtn,
  siteAllPortIds: state.workstations.siteAllPortIds,
  thisWorkstationId: state.workstations.siteWorkstation?.id,
  initialFrequency: state.cycleCounts.cycleCountFrequency?.frequency,
  excludeRecalledHold: state.site.clientConfig.ap_excludeRecalledHold,
  noMoreBins: state.autostore.noMoreBins
});

const connector = connect(mapStateToProps, {
  getInventorySummaries,
  getVariantByVariantId,
  setSelectedInventoryId,
  setSelectedAutostoreBinId,
  getSlottingInfo,
  getBinById,
  getSlotBinById,
  adjustInventory,
  getNextBin,
  createBinRequest,
  clearSelectedVariant,
  clearSelectedInventoryId,
  clearInventory,
  openAutostoreBin,
  updateSlottingInfo,
  createBinSlot,
  fetchPortStatus,
  closeBin,
  closePort,
  clearSlotBin,
  openPort,
  binAtPortEvent,
  binNotAtPort,
  fetchBinLogPublisherState,
  getInventoryReport,
  nextEmptyBin,
  openPortByContentCode,
  setPortStatus,
  setEmptyBinBtnState,
  findInventoryByAutostoreBinNumber,
  placeInventoryHold,
  clearInventoryToModify,
  getCycleCountFrequencyByVid,
  setUserMessage,
  setNoMoreBins
});
type InventoryOldInheritedProps = { viewTitle?: ViewNameTranslation };
type PropsFromRedux = ConnectedProps<typeof connector>;
export type InventoryOldProps = PropsFromRedux & InventoryOldInheritedProps;

export type BinOrProductResult = {
  type: "bin" | "product";
  variantId?: Guid;
  binId?: Guid;
  displayText: string;
  autostore_compartment_number?: number;
};

export function InventoryOld(props: InventoryOldProps) {
  const {
    inventoryAtPort,
    inventorySummaries,
    loadingInventorySummaries,
    inventorySummariesCount,
    selectedVariant,
    selectedAutostoreGridId,
    selectedBin,
    slotBin,
    selectedPortId,
    selectedInventoryId,
    clientId,
    slottingInfo,
    loadingSlottingInfo,
    portState,
    selectedAutostoreBinId,
    loadingBin,
    requestedAutostoreBin,
    binAtPort,
    inventoryReports,
    currentEmptyBin,
    usersFulfillmentCenter,
    inv_inventoryDateLabel,
    siteAllPortIds,
    thisWorkstationId,
    initialFrequency,
    viewTitle,
    excludeRecalledHold,
    noMoreBins,
    clearInventoryToModify,
    closePort
  } = props;

  const { data: availableBinConfigurations } = useGetBinConfigurationsQuery();

  const { t } = useTranslation();
  const shouldListenToGridEvents = useShouldListenToGridEvents();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const locationInfo = useLocation();
  const { pathname, search } = locationInfo;
  const { variantId: variantIdParam, binId: binIdParam } = useParams<{
    variantId?: string;
    binId?: string;
  }>();
  const closeWorkstation = useCloseWorkstationWithErrorToast();

  const searchBinsByText = useSearchBinsByText();

  const getBinsIndex = useAppSelector(
    (state) => state.inventoryOld.getBinsIndex
  );
  const isAdjustingBins = useAppSelector(
    (state) => state.inventoryOld.isAdjustingBins
  );
  const isAddPanelOpen = useAppSelector(
    (state) => state.inventoryOld.isAddPanelOpen
  );
  const isAdjustPanelOpen = useAppSelector(
    (state) => state.inventoryOld.isAdjustPanelOpen
  );
  const isBinComponentShown = useAppSelector(
    (state) => state.inventoryOld.isBinComponentShown
  );
  const isBinHoldModalOpen = useAppSelector(
    (state) => state.inventoryOld.isBinHoldModalOpen
  );
  const isFetchingBin = useAppSelector(
    (state) => state.inventoryOld.isFetchingBin
  );
  const inventorySummaryToDisplay = useAppSelector(
    selectInventorySummaryToDisplay
  );
  const isMovePanelOpen = useAppSelector(
    (state) => state.inventoryNew.isMovePanelOpen
  );
  const portOpened = useAppSelector((state) => state.inventoryOld.portOpened);
  const portPollingActive = useAppSelector(
    (state) => state.inventoryOld.portPollingActive
  );
  const selectedInventoryAtPort = useAppSelector(selectSelectedInventoryAtPort);
  const selectedRows = useAppSelector(
    (state) => state.inventoryOld.selectedRows
  );
  const selectedSummaries = useAppSelector(
    (state) => state.inventoryOld.selectedSummaries
  );
  const siteWorkstation = useAppSelector(selectThisWorkstation);

  const [isFrequencyModalOpen, setIsFrequencyModalOpen] = useState(false);
  const [scannedBarcode, setScannedBarcode] = useState<string | null>(null);

  // edit slotted bin
  const [newBinSlotModalOpen, setNewBinSlotModalOpen] =
    useState<boolean>(false);
  const [newBinSlotInputValue, setNewBinSlotInputValue] = useState<
    string | null
  >(null);
  const [newBinSlotAutocomplete, setNewBinSlotAutcomplete] = useState<
    SearchBinRecord[]
  >([]);
  const [selectedBinSlot, setSelectedBinSlot] =
    useState<SearchBinRecord | null>(null);
  const [scanChoices, setScanChoices] = useState<BinOrProductResult[]>(
    [] as BinOrProductResult[]
  );
  const [showScanChoiceModal, setShowScanChoiceModal] =
    useState<boolean>(false);

  const [autostoreBinConfiguration, setAutostoreBinConfiguration] =
    useState<AutostoreBinConfigurationDto | null>(null);
  const [compartment, setCompartment] = useState<number | null>(null);

  const [isNavigateAwayModalOpen, setIsNavigateAwayModalOpen] = useState(false);

  const [page, setPage] = useState<number>(1);
  const [isNoInventoryBin, setIsNoInventoryBin] = useState(false);
  const [binAtPortSeconds, setBinAtPortSeconds] = useState(0);
  const [scanState, setScanState] = useScanIndicator();

  const isMobile = useMediaQuery(autostoreTheme.breakpoints.down("sm"));
  const limit = 10;
  const offset = (page - 1) * limit;
  const totalPageCount = inventorySummariesCount
    ? Math.ceil(inventorySummariesCount / limit)
    : 0;

  const viewType = getViewType(pathname);
  const isBinView = viewType === "bin";
  const isProductView = viewType === "product";
  const isProductsView = viewType === "products";

  const isAnySelect: boolean = selectedRows.length >= 1;

  const invSumToModify = isAdjustingBins
    ? selectedInventoryAtPort
    : inventorySummaryToDisplay;

  let selectedLocationName = "";
  if (invSumToModify) {
    selectedLocationName =
      invSumToModify.binType === "autostore"
        ? "Autostore"
        : generateLocationNameFromInventorySummary(invSumToModify);
  }

  const selectedInventoryToModify: InventoryToModify = invSumToModify
    ? {
        binType: invSumToModify.binType,
        binId: invSumToModify.binId,
        inventoryId: invSumToModify.inventoryId,
        locationName: selectedLocationName,
        count: invSumToModify.count,
        committedCount: invSumToModify.committedCount,
        expiration: invSumToModify.expiration,
        manufactureDate: invSumToModify.manufactureDate,
        lotNumber: invSumToModify.lotNumber,
        variantId: invSumToModify.variantId
      }
    : null;

  const showCycleCountFrequencyButton = useFlag().cycleCountOverhaul;

  const binConfiguration = availableBinConfigurations?.find(
    (config) =>
      config.configurationId === selectedBin?.autostoreBin?.binConfigurationId
  );
  const binHasCompartments =
    selectedBin?.binType === "autostore" &&
    (binConfiguration?.numberOfCompartments || 0) > 1;

  const clearSelectedData = useCallback(() => {
    dispatch(clearSelectedVariant());
    dispatch(clearSelectedInventoryId());
  }, [dispatch]);

  const resetView = async (resetInv = true) => {
    if (resetInv) {
      clearSelectedData();
      props.clearInventory();
    }
    if (selectedAutostoreBinId || portState?.selectedBin) {
      await closeWorkstation();
    }
    if (!shouldListenToGridEvents) {
      dispatch(setPortPollingActive(false));
    } else {
      props.binAtPortEvent();
    }
    dispatch(setIsFetchingBin(false));
  };

  const handleCloseBin = useCallback(() => {
    dispatch(binNotAtPort());
    dispatch(setIsBinComponentShown(false));
  }, [dispatch]);

  // component did unmount
  useEffect(
    () => () => {
      void resetView();
      if (binAtPort && shouldListenToGridEvents) {
        props.binNotAtPort();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    props.setSelectedAutostoreBinId(
      inventorySummaryToDisplay?.autostoreBinNumber || null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventorySummaryToDisplay]);

  useEffect(() => {
    if (pathname === "/inventory/bin" || pathname === "/inventory/product") {
      props.clearSelectedVariant();
      props.clearSelectedInventoryId();
      props.clearInventory();
    }
    if (!shouldListenToGridEvents) {
      dispatch(setPortPollingActive(false));
    } else if (binAtPort) props.binNotAtPort();

    dispatch(setIsFetchingBin(false));
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleGetInventorySummaries = useCallback(
    (args: { variantId?: Guid; binId?: Guid; offsetZero?: boolean }) => {
      const { variantId, binId, offsetZero } = args;

      let autostoreGridIdToSearch: Guid | undefined;

      // assumption: if accessed via autostore menu, only show inventory within the selected grid
      // if accessed from the side menu, show all inventory for the client
      if (isAutostoreView(search)) {
        autostoreGridIdToSearch = selectedAutostoreGridId;
      }

      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      dispatch(
        getInventorySummaries({
          variantId,
          binId,
          limit,
          offset: offsetZero ? 0 : offset,
          autostoreGridId: autostoreGridIdToSearch
        })
      );
    },
    [dispatch, offset, search, selectedAutostoreGridId]
  );

  // * fetch inventory, etc when url changes
  useEffect(() => {
    if (variantIdParam) {
      handleGetInventorySummaries({
        variantId: variantIdParam
      });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.getSlottingInfo({ variantId: variantIdParam });
    } else if (binIdParam) {
      handleGetInventorySummaries({ binId: binIdParam, offsetZero: true });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.getBinById(binIdParam);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.getSlottingInfo({ binId: binIdParam });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantIdParam, binIdParam]);

  const handleRefetchInventory = useCallback(() => {
    if (variantIdParam) {
      handleGetInventorySummaries({ variantId: variantIdParam });
    } else if (binIdParam) {
      handleGetInventorySummaries({ binId: binIdParam });
    } else {
      handleGetInventorySummaries({});
    }
  }, [binIdParam, handleGetInventorySummaries, variantIdParam]);

  // fetch inventory when page changes (pagination)
  useEffect(() => {
    handleRefetchInventory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);

  // get variant info for selected inventory, or variant from params if no inventory records
  const searchVariantId: Guid | null =
    (isAdjustingBins && selectedInventoryAtPort?.variantId) ||
    inventorySummaryToDisplay?.variantId ||
    variantIdParam ||
    null;

  useEffect(() => {
    if (searchVariantId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.getVariantByVariantId(searchVariantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVariantId]);

  // slotting
  const firstSlottingInfo = !!slottingInfo.length && slottingInfo[0];

  // get bin data for product slot to display bin location
  useEffect(() => {
    // get bin for most recent/last slot of slotting info
    if (isProductView && firstSlottingInfo) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.getSlotBinById(firstSlottingInfo.binId);
    } else {
      props.clearSlotBin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewType, slottingInfo]);

  // get inventory report for selected variant. Used for total qty/total committed
  useEffect(() => {
    if (selectedVariant) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.getInventoryReport(selectedVariant.variantId);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.getCycleCountFrequencyByVid(selectedVariant.variantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariant]);

  useEffect(() => {
    if (portState?.mode === "OPEN" && portState?.selectedBin !== 0) {
      props.setEmptyBinBtnState(false);
    } else props.setEmptyBinBtnState(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portOpened, portState]);

  const autostoreGridFunc =
    useCallback(async (): Promise<GetPortResponse | null> => {
      if (!!selectedAutostoreGridId && !!selectedPortId) {
        const response = await warehouseService.get<GetPortResponse>(
          `/api/autostore-grid/${selectedAutostoreGridId}/port/${selectedPortId}`
        );
        return response.data;
      }
      return null;
    }, [selectedAutostoreGridId, selectedPortId]);

  // Get Empty Bin
  const openPortFunc = useCallback(() => {
    if (!selectedAutostoreGridId || !selectedPortId) return;
    dispatch(
      openPortByContentCode({
        defaultBinConfigurations: availableBinConfigurations || []
      })
    )
      .then(() => {
        dispatch(nextEmptyBin())
          .then(() => {
            dispatch(setEmptyBinBtnState(false));
            if (!isFetchingBin && isAddPanelOpen) {
              dispatch(setIsFetchingBin(true));
            }
          })
          .catch((error: AxiosError) => {
            dispatch(
              setUserMessage({
                title:
                  getAxiosErrorMessage(error) ||
                  `Next empty bin failed. Grid id: ${selectedAutostoreGridId}. Port id: ${selectedPortId}`,
                severity: "error",
                origin: "inventory/NEXT_EMPTY_BIN_FAILED"
              })
            );
            closePort().catch((err: AxiosError) => {
              dispatch(
                setUserMessage({
                  title:
                    getAxiosErrorMessage(err) ||
                    `Close port failed. Grid id: ${selectedAutostoreGridId}. Port id: ${selectedPortId}`,
                  severity: "error",
                  origin: "inventory/CLOSE_PORT_FAILED"
                })
              );
            });
            dispatch(setPortOpened(false));
            if (!shouldListenToGridEvents) {
              dispatch(setPortPollingActive(false));
            }
          });
        dispatch(setPortOpened(true));
        if (!shouldListenToGridEvents) {
          dispatch(setPortPollingActive(true));
        } else {
          dispatch(binNotAtPort());
        }
      })
      .catch((error: AxiosError) => {
        dispatch(
          setUserMessage({
            title:
              getAxiosErrorMessage(error) ||
              `Open port failed. Grid id: ${selectedAutostoreGridId}. Port id: ${selectedPortId}`,
            severity: "error",
            origin: "inventory/OPEN_PORT_FAILED"
          })
        );
      });
  }, [
    availableBinConfigurations,
    closePort,
    dispatch,
    isAddPanelOpen,
    isFetchingBin,
    selectedAutostoreGridId,
    selectedPortId,
    shouldListenToGridEvents
  ]);

  const handleGetEmptyBin = useCallback(async () => {
    if (!!selectedAutostoreGridId && !!selectedPortId) {
      await autostoreGridFunc()
        .then((response) => {
          if (response) {
            dispatch(setPortStatus(response));
            if (response.mode === "OPEN") {
              closePort()
                .then(() => {
                  if (!shouldListenToGridEvents && binAtPort) {
                    dispatch(setIsFetchingBin(true));
                    dispatch(binNotAtPort());
                  }
                  openPortFunc();
                })
                .catch((error: AxiosError) => {
                  dispatch(
                    setUserMessage({
                      title:
                        getAxiosErrorMessage(error) ||
                        `Close port failed. Grid id: ${selectedAutostoreGridId}. Port id: ${selectedPortId}`,
                      severity: "error",
                      origin: "inventory/CLOSE_PORT_FAILED"
                    })
                  );
                });
            } else {
              openPortFunc();
            }
          }
        })
        .catch((error: AxiosError) => {
          dispatch(setIsFetchingBin(false));
          dispatch(
            setUserMessage({
              title:
                getAxiosErrorMessage(error) ||
                `Autostore grid failed. Grid id: ${selectedAutostoreGridId}. Port id: ${selectedPortId}`,
              severity: "error",
              origin: "inventory/AUTOSTORE_GRID_FAILED"
            })
          );
        });
    }
  }, [
    autostoreGridFunc,
    binAtPort,
    closePort,
    dispatch,
    openPortFunc,
    selectedAutostoreGridId,
    selectedPortId,
    shouldListenToGridEvents
  ]);

  const handleGetEmptyBinClick = useCallback(async () => {
    if (!selectedAutostoreGridId) return;

    if (!isAddPanelOpen) {
      setIsAddPanelOpen(true);
    }
    if (!isFetchingBin) {
      dispatch(setIsFetchingBin(true));
    }
    if (currentEmptyBin && currentEmptyBin.openBinResponse.binId) {
      const inv = await dispatch(
        findInventoryByAutostoreBinNumber(
          selectedAutostoreGridId,
          currentEmptyBin.openBinResponse.binId
        )
      );

      if (inv?.length === 0) {
        dispatch(setIsAddPanelOpen(true));
      } else {
        Sentry.captureMessage(
          `Bin was brought to induction but inventory records were found in bin ${currentEmptyBin.openBinResponse.binId}`,
          "info"
        );
        await dispatch(
          closeBin({
            binId: currentEmptyBin?.openBinResponse.binId,
            taskId: currentEmptyBin?.openBinResponse.taskId
          })
        );
        await handleGetEmptyBin();
      }
    } else {
      await handleGetEmptyBin();
    }
  }, [
    currentEmptyBin,
    dispatch,
    handleGetEmptyBin,
    isAddPanelOpen,
    isFetchingBin,
    selectedAutostoreGridId
  ]);

  const autocompleteBinSearch = async (input: string | null): Promise<void> => {
    // search bins
    if (!input) {
      return;
    }
    if (input.length) {
      const binSearch = await searchBinsByText(input.replace("*", ""));
      if (binSearch.length === 1) {
        setNewBinSlotAutcomplete([]);
        setNewBinSlotInputValue(null);
        setSelectedBinSlot(binSearch[0]);
      } else {
        setNewBinSlotAutcomplete(binSearch);
      }
    }
  };

  const confirmBinSlotUpdate = () => {
    if (selectedBinSlot && firstSlottingInfo) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props
        .updateSlottingInfo({
          slotId: firstSlottingInfo.slotId,
          binId: selectedBinSlot.bin_id
        })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          props.getSlottingInfo({ variantId: variantIdParam });
          setSelectedBinSlot(null);
        });
    }
  };

  const confirmCreateBinSlot = () => {
    if (selectedBinSlot && variantIdParam) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props
        .createBinSlot({
          binId: selectedBinSlot.bin_id,
          variantId: variantIdParam
        })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          props.getSlottingInfo({ variantId: variantIdParam });
          setSelectedBinSlot(null);
        });
    }
  };

  const handleAutocompleteSelect = useCallback(
    (option: BinOrProductResult | null): void => {
      setShowScanChoiceModal(false);

      // don't redirect to the same page if same search result is selected
      if (option && option.variantId && pathname.includes(option.variantId)) {
        return;
      }

      if (option && option.type !== "bin") {
        clearSelectedData();
        navigate({
          pathname: `/inventory/product/${option?.variantId ?? ""}`,
          search
        });
      } else if (option?.type === "bin") {
        clearSelectedData();
        navigate({
          pathname: `/inventory/bin/${option?.binId ?? ""}`,
          search
        });
      }
    },
    [clearSelectedData, navigate, pathname, search]
  );

  const { setMenuItems, setToolbar } = useNavbar({
    centerComponent: useMemo(
      () => (
        <InventoryOldSearch
          handleAutocompleteSelect={handleAutocompleteSelect}
        />
      ),
      [handleAutocompleteSelect]
    ),
    viewTitle
  });
  useDevCheats({
    isPortPolling:
      (portPollingActive && !shouldListenToGridEvents) ||
      (!binAtPort && shouldListenToGridEvents)
  });

  useKeyDownHandler();

  interface ScanObj {
    scanType:
      | "search bin record"
      | "bin result"
      | "product result"
      | "products result";
    searchBinRecord?: SearchBinRecord;
    binOrProductResult?: BinOrProductResult;
    productsResult?: BinOrProductResult[];
  }

  useBarcodeScanner<ScanObj | null>({
    disabled: isMovePanelOpen,
    findScanMatch: async (buffer: string) => {
      if (newBinSlotModalOpen) {
        const binSearch = await searchBinsByText(buffer.replace("*", ""));
        if (binSearch.length === 1) {
          return {
            scanType: "search bin record",
            searchBinRecord: binSearch[0]
          };
        }
      } else {
        setScannedBarcode(buffer);
        setScanState("success");
        const hits = clientId ? await searchProduct(buffer) : [];
        const hitsAsAutocompleteRecords = createProductSearchOptions({
          hits,
          exactUpcMatchFilter: buffer
        });
        if (hitsAsAutocompleteRecords.length === 1) {
          return {
            scanType: "product result",
            binOrProductResult: hitsAsAutocompleteRecords[0]
          };
        }
        if (hitsAsAutocompleteRecords.length > 1) {
          return {
            scanType: "products result",
            productsResult: hitsAsAutocompleteRecords
          };
        }

        // if search product returns no results, search bins
        const binSearch = await warehouseService.get<SearchBinRecord[]>(
          `/api/bins/search/${buffer}`
        );
        const binRecords = binSearch.data;

        const binsAsAutocompleteRecords = createBinSearchOptions(binRecords);
        if (binsAsAutocompleteRecords.length === 1) {
          return {
            scanType: "bin result",
            binOrProductResult: binsAsAutocompleteRecords[0]
          };
        }
      }
      return null;
    },
    processScanMatch: (scanObj) => {
      if (!scanObj) return null;
      if (isAddPanelOpen) return null;
      if (scanObj.scanType === "products result" && scanObj.productsResult) {
        setScanChoices(scanObj.productsResult);
        setShowScanChoiceModal(true);
        return null;
      }

      const matchedItem = scanObj.searchBinRecord || scanObj.binOrProductResult;
      if (!matchedItem) {
        return null;
      }
      setScanState("success");

      if (scanObj.scanType === "search bin record") {
        setNewBinSlotAutcomplete([]);
        setNewBinSlotInputValue(null);
        setSelectedBinSlot(matchedItem as SearchBinRecord);
      } else if (scanObj.scanType === "product result") {
        navigate({
          pathname: `/inventory/product/${
            (matchedItem as BinOrProductResult).variantId as string
          }`,
          search
        });
      } else if (scanObj.scanType === "bin result") {
        props.clearSelectedVariant();
        navigate({
          pathname: `/inventory/bin/${
            (matchedItem as BinOrProductResult).binId as string
          }`,
          search
        });
      }
      return null;
    },
    deps: [viewType, newBinSlotModalOpen]
  });

  // POLL FOR BIN AT PORT INFORMATION IF EVENT ISN'T RECEIVED LONGER THAN 7 SECONDS
  usePromiseInterval(
    async () => {
      setBinAtPortSeconds((binAtPortSecondsState) => binAtPortSecondsState + 1);
      if (binAtPortSeconds > 7) {
        const portStatus = await props.fetchPortStatus();
        if (portStatus && selectedAutostoreGridId && portStatus?.selectedBin) {
          const binStateResponse = await props.fetchBinLogPublisherState(
            selectedAutostoreGridId,
            portStatus.selectedBin
          );
          if (
            binStateResponse?.binState.binMode === "O" &&
            binStateResponse.binState.portId === selectedPortId
          ) {
            props.binAtPortEvent();
          }
        }
      }
    },
    1000,
    !binAtPort &&
      shouldListenToGridEvents &&
      isBinComponentShown &&
      isAutostoreView(search)
  );

  useEffect(() => {
    if (binAtPort && binAtPortSeconds > 0) {
      setBinAtPortSeconds(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [binAtPort]);

  // polling
  usePromiseInterval(
    async () => {
      if (!selectedAutostoreGridId || !selectedPortId) return;
      await props.fetchPortStatus();
    },
    500,
    portPollingActive && !shouldListenToGridEvents && isAutostoreView(search)
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!isAdjustingBins && getBinsIndex !== 0) {
      dispatch(setGetBinsIndex(0));
    }
  });

  const resetPage = useCallback(() => {
    dispatch(clearSelectedVariant());
    dispatch(clearInventory());
    dispatch(selectRows([]));
    dispatch(selectSummaries([]));
    dispatch(setIsAdjustingBins(false));
    dispatch(setIsBinComponentShown(false));
    dispatch(setPortPollingActive(false));
    dispatch(setIsFetchingBin(false));
    dispatch(setPortOpened(false));
    handleRefetchInventory();
    setPage(1);
    dispatch(setNoMoreBins(false));
  }, [dispatch, handleRefetchInventory]);

  const softReset = () => {
    clearInventoryToModify();
    dispatch(setIsAdjustingBins(false));
    dispatch(setIsBinComponentShown(false));
    dispatch(setPortPollingActive(false));
    dispatch(setPortOpened(false));
    dispatch(setIsFetchingBin(false));
  };

  // reset state and refetch inventory summaries
  useEffect(() => {
    void (async () => {
      if (noMoreBins) {
        await closeWorkstation();
      }
      resetPage();
    })();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noMoreBins]);

  // Cleanup task group on page render (first hit on page or on page refresh)
  useEffect(() => {
    void closeWorkstation();
  }, [closeWorkstation]);

  const handleClosePort = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    dispatch(fetchPortStatus()).then((data: GetPortResponse | void) => {
      if (data && (data.selectedBin > 0 || data.selectedTask > 0)) {
        setIsNavigateAwayModalOpen(true);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        closePort();
        dispatch(setIsFetchingBin(false));
        dispatch(setIsBinComponentShown(false));
        resetPage();
      }
    });
  }, [closePort, dispatch, resetPage]);

  const handleGetNextBin = useCallback(async () => {
    await dispatch(fetchPortStatus()).then(async (portStatus) => {
      if (portStatus) {
        const { selectedTask } = portStatus;
        // close bin
        await dispatch(
          closeBin({
            binId: portStatus.selectedBin,
            taskId: selectedTask
          })
        );
        // get next bin if more than one bin is selected
        if (selectedRows.length > 1) {
          await dispatch(getNextBin({ shouldSoftFail: true }));
        } else {
          resetPage();
        }
        // start polling
        if (!shouldListenToGridEvents) {
          dispatch(setPortPollingActive(true));
        } else {
          dispatch(binNotAtPort());
        }
        dispatch(setGetBinsIndex(getBinsIndex + 1));
      }
    });
    if (!isFetchingBin) {
      dispatch(setIsFetchingBin(true));
    }
    await dispatch(fetchPortStatus()).then((data: GetPortResponse | void) => {
      if (!data) return;

      const portIsOpen = data.mode.toLowerCase().includes("open");
      const portIsReady = data.isReady;

      // the right bin is here and open.  Enable the adjust button.
      if (portIsOpen && portIsReady) {
        if (shouldListenToGridEvents && !binAtPort) return;
        dispatch(setIsFetchingBin(false));
      }
    });
  }, [
    binAtPort,
    dispatch,
    getBinsIndex,
    isFetchingBin,
    resetPage,
    selectedRows.length,
    shouldListenToGridEvents
  ]);

  const handleExitGetBins = useCallback(async () => {
    if (siteWorkstation && portState && isAutostoreView(search)) {
      const { selectedTask, isReady } = portState;
      if (isReady) {
        await dispatch(
          closeBin({
            binId: portState.selectedBin,
            taskId: selectedTask
          })
        );
      }
      await closeWorkstation();
    }
  }, [dispatch, portState, search, siteWorkstation, closeWorkstation]);

  const handleGetInventoryBin = async () => {
    if (!selectedAutostoreGridId || !selectedPortId) return null;

    const selectedAutostoreBin = inventorySummaryToDisplay
      ? inventorySummaryToDisplay.autostoreBinNumber
      : selectedBin?.autostoreBin;
    if (!selectedAutostoreBin) return null;
    if (!isFetchingBin) {
      dispatch(setIsFetchingBin(true));
    }
    if (!isBinComponentShown) {
      dispatch(setIsBinComponentShown(true));
    }

    try {
      await props
        .fetchPortStatus()
        .then(async (data: GetPortResponse | void) => {
          if (!data) return;

          const portIsOpen = data.mode.toLowerCase().includes("open");
          const portIsReady = data.isReady;

          const correctBinIsSelected =
            typeof selectedAutostoreBin === "object" &&
            selectedAutostoreBin !== null
              ? data.selectedBin === selectedAutostoreBin.autostoreBinId
              : data.selectedBin === selectedAutostoreBin;

          // if port is not ready or open
          const categoryId = 6000 + selectedPortId;
          if (!portIsOpen) {
            await props.openPort({ categoryId });
          }

          // if port is open but not ready
          if (!portIsReady && !shouldListenToGridEvents) {
            dispatch(setPortPollingActive(true));
          }

          if (!portIsReady && shouldListenToGridEvents) {
            props.binNotAtPort();
          }

          // If port is open, we need to close it to get the next bin
          /* If the correct bin is already selected, then we don't need to close it,
          but we need bin configuration info for showing the compartments
          */

          // is there a reason we don't close the bin instead of closing the port?
          if (portIsOpen && !correctBinIsSelected) {
            await closePort();
          }

          // the port is closed.  Open for inventory.
          if (
            typeof selectedAutostoreBin === "object" &&
            selectedAutostoreBin !== null
          ) {
            await props.openAutostoreBin({
              binId: selectedAutostoreBin.autostoreBinId
            });

            setIsNoInventoryBin(true);
            if (!shouldListenToGridEvents) {
              dispatch(setPortPollingActive(true));
            } else {
              props.binNotAtPort();
            }
          } else if (!correctBinIsSelected && selectedAutostoreBinId) {
            try {
              await props.openAutostoreBin({
                binId: selectedAutostoreBinId
              });
              dispatch(setIsFetchingBin(false));

              if (!shouldListenToGridEvents) {
                dispatch(setPortPollingActive(true));
              } else {
                props.binNotAtPort();
              }
            } catch {
              void resetView(false);
              setBinAtPortSeconds(0);
              if (isAdjustPanelOpen) {
                dispatch(setIsAdjustPanelOpen(false));
              }
            }
          } else if (selectedAutostoreGridId && selectedAutostoreBin) {
            // get info about bin component
            const binStateResponse =
              await warehouseService.get<OpenBinByIdResponse>(
                `/api/autostore-grid/${selectedAutostoreGridId}/bin/${selectedAutostoreBin}`
              );

            setAutostoreBinConfiguration(
              binStateResponse?.data.binConfiguration || null
            );
          }
          if (portIsOpen && correctBinIsSelected && portIsReady) {
            // the right bin is here and open.  Enable the adjust button.

            if (shouldListenToGridEvents && !binAtPort) return;

            dispatch(setIsFetchingBin(false));
          }
          // return null;
        });
    } catch {
      dispatch(setIsFetchingBin(false));
    }
    return null;
  };

  useEffect(() => {
    setAutostoreBinConfiguration(requestedAutostoreBin);
  }, [requestedAutostoreBin]);

  // set compartment
  useEffect(() => {
    if (selectedInventoryAtPort?.autostoreCompartmentNumber) {
      setCompartment(selectedInventoryAtPort.autostoreCompartmentNumber - 1);
    } else setCompartment(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInventoryId, selectedInventoryAtPort]);

  // listen for portState changes
  useEffect(() => {
    if (!portState) return;
    if (
      (!shouldListenToGridEvents && portState.isReady) ||
      (binAtPort && shouldListenToGridEvents && isBinComponentShown)
    ) {
      dispatch(setPortPollingActive(false));
      dispatch(setIsFetchingBin(false));
    } else if (portState.isReady && isNoInventoryBin) {
      dispatch(setPortPollingActive(false));
      dispatch(setIsFetchingBin(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portState, isNoInventoryBin, binAtPort]);

  // close bin / port when modal(s) closes
  useEffect(() => {
    // check that they are are closed, since they are by default false
    if (
      !isAddPanelOpen &&
      !isAdjustPanelOpen &&
      !isAdjustingBins &&
      isAutostoreView(search)
    ) {
      void resetView(false);
      setBinAtPortSeconds(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddPanelOpen, isAdjustingBins]);

  const gridSub = (data: AutostoreEvent) => {
    if (data.case !== "BinModeChange" || data.event.binMode !== "O") return;
    if (
      data.event.binId === selectedAutostoreBinId &&
      data.event.gridId === props.selectedAutostoreGridId &&
      !!data.event.portId &&
      siteAllPortIds.includes(data.event.portId)
    ) {
      // Dispatch redux action
      props.binAtPortEvent();
    }
  };
  useGridV2Subscription(gridSub);

  // eslint-disable-next-line react/no-unstable-nested-components
  function NoInventoryFound() {
    return selectedBin || selectedVariant ? (
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">{t("no inventory found")}</Typography>
        </Grid>
        {isAutostoreView(search) &&
          selectedBin &&
          selectedBin.autostoreBin &&
          !(
            selectedBin.autostoreBin.autostoreBinId === portState?.selectedBin
          ) &&
          isBinView && (
            <Grid item xs={12}>
              <ProgressButton
                variant="contained"
                color="primary"
                onClick={() => {
                  if (!isBinComponentShown) {
                    dispatch(setIsBinComponentShown(true));
                  }
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                  handleGetInventoryBin();
                  dispatch(setIsAdjustPanelOpen(true));
                }}
              >
                {t("adjust bin")}
              </ProgressButton>
            </Grid>
          )}
      </Grid>
    ) : (
      <Typography variant="h5" align="center">
        {t("no inventory found")}
      </Typography>
    );
  }

  const handleScanChoiceSelect = (value: string) => {
    const selectedProductResult: BinOrProductResult | undefined =
      scanChoices.find((scanChoice) => scanChoice.variantId === value);

    if (selectedProductResult) handleAutocompleteSelect(selectedProductResult);
  };

  const handleFetchAllInventory = () => {
    clearSelectedData();
    resetPage();
    navigate({
      pathname: `/inventory/product`,
      search
    });
    handleGetInventorySummaries({ offsetZero: true });
  };

  useEffect(() => {
    if (selectedRows.length) {
      setToolbar(
        <InventoryOldToolbar
          handleCloseBin={handleCloseBin}
          handleExitGetBins={handleExitGetBins}
          handleGetEmptyBinClick={handleGetEmptyBinClick}
          handleGetInventorySummaries={handleGetInventorySummaries}
          handleGetNextBin={handleGetNextBin}
          resetPage={resetPage}
        />
      );
    } else {
      setToolbar(undefined);
    }
  }, [
    handleCloseBin,
    handleExitGetBins,
    handleGetEmptyBinClick,
    handleGetInventorySummaries,
    handleGetNextBin,
    resetPage,
    selectedRows.length,
    setToolbar
  ]);

  useEffect(() => {
    const menuItems =
      isAutostoreView(search) && selectedAutostoreGridId && selectedPortId
        ? [
            {
              textContent: t("close port"),
              actionCb: handleClosePort
            }
          ]
        : [];
    setMenuItems(menuItems);
  }, [
    handleClosePort,
    search,
    selectedAutostoreGridId,
    selectedPortId,
    setMenuItems,
    t
  ]);

  const warning =
    isAutostoreView(search) && !selectedPortId
      ? t("no workstation selected")
      : null;

  return (
    <>
      {warning && (
        <AlertBanner sx={{ width: "100%", borderRadius: 0 }}>
          {warning}
        </AlertBanner>
      )}
      <Container maxWidth="xl" sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <InventoryProductCard
              variantId={
                isAnySelect || isProductView
                  ? selectedVariant?.variantId
                  : undefined
              }
              isInventorySelected={isAnySelect}
              addClickCb={() => dispatch(setIsAddPanelOpen(true))}
              getEmptyBin={() => {
                if (isAutostoreView(search)) {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                  handleGetEmptyBinClick();
                  if (!isBinComponentShown) {
                    dispatch(setIsBinComponentShown(true));
                  }
                }
              }}
              getInventoryBin={() => {
                if (isAutostoreView(search)) {
                  if (!isBinComponentShown) {
                    dispatch(setIsBinComponentShown(true));
                  }
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                  handleGetInventoryBin();
                }
                dispatch(setIsAdjustPanelOpen(true));
              }}
              inventoryReports={inventoryReports}
              usersFulfillmentCenter={usersFulfillmentCenter}
              isFetchingBin={isFetchingBin}
              selectedInventorySummary={inventorySummaryToDisplay}
              initialCycleCountFrequency={initialFrequency}
              hideAddInventory={selectedRows.length > 1}
            />
            {showCycleCountFrequencyButton && (
              <Grid item xs={12}>
                <Box mt={2}>
                  <ProgressButton
                    variant="contained"
                    color="primary"
                    onClick={() => setIsFrequencyModalOpen(true)}
                    disabled={
                      !selectedVariant?.variantId ||
                      selectedSummaries?.length > 1
                    }
                  >
                    {t("add cycle count frequency")}
                  </ProgressButton>
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box mt={2}>
                {binAtPort && isBinComponentShown && (
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    {t("bin")}{" "}
                    {portState !== null && portState.selectedBin
                      ? portState.selectedBin
                      : selectedAutostoreBinId}
                  </Typography>
                )}
                {((portPollingActive && !shouldListenToGridEvents) ||
                  (!binAtPort &&
                    shouldListenToGridEvents &&
                    isBinComponentShown) ||
                  (binAtPort && isBinComponentShown)) && (
                  <Stack alignItems="center">
                    <AutostoreBin
                      state={isFetchingBin ? "Waiting for Bin" : "Bin Opened"}
                      numberOfColumns={
                        autostoreBinConfiguration?.verticalCompartmentCount || 1
                      }
                      numberOfRows={
                        autostoreBinConfiguration?.horizontalCompartmentCount ||
                        1
                      }
                      selectedCompartment={compartment ?? undefined}
                      pickCompartment={null}
                      pickQuantity=""
                      binId={
                        !shouldListenToGridEvents
                          ? ternaryIff(
                              portState !== null &&
                                portState?.selectedBin !== 0,
                              portState?.selectedBin || 0,
                              undefined
                            )
                          : ternaryIff(
                              selectedAutostoreBinId !== null && binAtPort,
                              selectedAutostoreBinId || 0,
                              undefined
                            )
                      }
                      hideBinId
                    />
                  </Stack>
                )}
              </Box>
            </Grid>
          </Grid>

          <Grid item container xs={12} md={8}>
            <Grid item xs={12}>
              <Paper>
                <Grid container justifyContent="flex-end">
                  <Box style={{ margin: "15px 5px 5px" }}>
                    <Button onClick={handleFetchAllInventory}>
                      <Typography>{t("return to all inventory")}</Typography>
                    </Button>
                  </Box>
                </Grid>
                <Box padding="15px">
                  <Grid>
                    {/* product view - top of table */}
                    {isProductView && selectedVariant && (
                      <Grid item xs={12}>
                        <Typography variant="h6" component="div">
                          {getVariantDisplayNameByDtoFe(
                            selectedVariant,
                            usersFulfillmentCenter
                          )}
                        </Typography>
                        <Box minHeight="36px">
                          {loadingBin ||
                            (loadingSlottingInfo && (
                              <Skeleton height={36} width={130} />
                            ))}
                          {!isAutostoreView(search) &&
                            !loadingBin &&
                            !loadingSlottingInfo &&
                            (slotBin ? (
                              <Grid container>
                                <Typography
                                  component="div"
                                  sx={{ color: "darkGray.main" }}
                                >
                                  {`${t(
                                    "slotted bin"
                                  )}: ${generateLocationNameFromBin(slotBin)}`}
                                </Typography>
                                {!!slottingInfo.length && (
                                  <IconButton
                                    aria-label="edit"
                                    style={{ padding: 0 }}
                                    onClick={() => setNewBinSlotModalOpen(true)}
                                    size="large"
                                  >
                                    <EditIcon
                                      sx={{
                                        color: "success.main",
                                        fontSize: 20,
                                        paddingLeft: 2,
                                        paddingBottom: 2
                                      }}
                                    />
                                  </IconButton>
                                )}
                              </Grid>
                            ) : (
                              <Button
                                sx={{ color: "success.main" }}
                                variant="text"
                                startIcon={<Add />}
                                size="large"
                                onClick={() => {
                                  setNewBinSlotModalOpen(true);
                                }}
                              >
                                {t("assign slot")}
                              </Button>
                            ))}
                        </Box>
                      </Grid>
                    )}
                    {/* bin view - top of table */}
                    {isBinView && selectedBin && (
                      <Grid item xs={12}>
                        <Box display="flex">
                          <Typography
                            variant="h6"
                            role="heading"
                            component="div"
                            marginRight={1}
                          >
                            {generateLocationNameFromBin(selectedBin)}
                          </Typography>
                          {!!binHasCompartments &&
                            !!selectedBin.autostoreCompartmentNumber && (
                              <Chip
                                label={
                                  `${t("compartment")}` +
                                  ` ${selectedBin.autostoreCompartmentNumber}`
                                }
                                sx={{
                                  backgroundColor: "primary.main",
                                  color: "primary.contrastText"
                                }}
                              />
                            )}
                        </Box>
                        {selectedBin &&
                          selectedBin.binType.toLowerCase() !== "autostore" && (
                            <Grid container>
                              {firstSlottingInfo && (
                                <Typography
                                  component="div"
                                  sx={{ color: "gray.dark" }}
                                >
                                  {`${t("slotted product")}: ${
                                    firstSlottingInfo.name
                                  }`}
                                </Typography>
                              )}
                            </Grid>
                          )}
                      </Grid>
                    )}
                    {!loadingInventorySummaries &&
                      inventorySummaries &&
                      !inventorySummaries.length && (
                        <Grid item xs={12}>
                          <NoInventoryFound />
                        </Grid>
                      )}
                  </Grid>
                </Box>
                {/* inventory bin table */}
                {!isAdjustingBins &&
                  ((!loadingInventorySummaries &&
                    inventorySummaries &&
                    inventorySummaries.length > 0) ||
                    loadingInventorySummaries) && (
                    <AutostoreTable<InventorySummaryDto>
                      widthOfCols={
                        !isMobile
                          ? ["30%", "20%", "10%", "10%", "10%", "20%"]
                          : ["40%", "20%", "20%"]
                      }
                      headerVariant="overline"
                      bodyVariant="body2"
                      headerColNames={
                        !isMobile
                          ? [
                              t("product"),
                              t("bin"),
                              t("quantity"),
                              t("hold type"),
                              t("committed"),
                              t(inventoryDateLabel(inv_inventoryDateLabel))
                            ]
                          : [
                              isBinView || isProductsView
                                ? t("product")
                                : t("bin"),
                              t("quantity"),
                              t(inventoryDateLabel(inv_inventoryDateLabel))
                            ]
                      }
                      rowId={(row: InventorySummaryDto) => row.inventoryId}
                      renderColumns={[
                        (row: InventorySummaryDto) => {
                          let firstColumnData = row.productName;
                          if (isMobile && isProductView) {
                            firstColumnData =
                              row.binType === "autostore"
                                ? `${row.autostoreBinNumber || ""}`
                                : generateLocationNameFromInventorySummary(row);
                          }
                          return (
                            <div>
                              <Typography variant="body2">
                                {firstColumnData}
                              </Typography>
                              <Grid sx={{ marginTop: 1 }}>
                                {!!row.temperatureZone && (
                                  <Chip
                                    label={t(
                                      `${row.temperatureZone.toLowerCase() as TempZone}`
                                    )}
                                    sx={{
                                      bgcolor:
                                        temperatureZoneColors[
                                          row.temperatureZone.toLowerCase() as TempZone
                                        ],
                                      color:
                                        row.temperatureZone.toLowerCase() +
                                        ".contrastText",
                                      marginRight: 1
                                    }}
                                  />
                                )}
                                <Chip
                                  label={row.binType}
                                  sx={{
                                    textTransform: "capitalize"
                                  }}
                                />
                              </Grid>
                            </div>
                          );
                        },
                        isMobile
                          ? null
                          : (row) => {
                              let secondColumn = "";
                              if (row.binType === "autostore") {
                                secondColumn = `${row.autostoreBinNumber || ""}`;
                              } else {
                                secondColumn =
                                  generateLocationNameFromInventorySummary(row);
                              }
                              return (
                                <div>
                                  <Typography variant="body2">
                                    {secondColumn}
                                  </Typography>
                                  <Grid sx={{ marginTop: 1 }}>
                                    {!!row.autostoreCompartmentNumber && (
                                      <Chip
                                        label={`${t("compartment")} ${
                                          row.autostoreCompartmentNumber
                                        }`}
                                        sx={{
                                          backgroundColor: "primary.main",
                                          color: "primary.contrastText",
                                          marginRight: 1
                                        }}
                                      />
                                    )}
                                  </Grid>
                                </div>
                              );
                            },
                        (row: InventorySummaryDto) =>
                          `${row.count?.value !== undefined ? row.count?.value : t("n/a")} ${row.count?.units || ""}`,
                        isMobile
                          ? null
                          : (row: InventorySummaryDto) => {
                              return (
                                <Box>
                                  {row.holds?.map((hold, index) => (
                                    <Box key={`hold-${index}`}>
                                      <Typography>
                                        {t(
                                          hold.reasonCode.toLowerCase() as InventoryHoldReasonCode
                                        )}
                                      </Typography>
                                    </Box>
                                  )) || ""}
                                </Box>
                              );
                            },
                        isMobile
                          ? null
                          : (row: InventorySummaryDto) =>
                              `${row.committedCount?.value !== undefined ? row.committedCount?.value : ""} ${row.committedCount?.units || ""}`,
                        (row: InventorySummaryDto) =>
                          formatUtcDate(
                            checkIsExpiration(inv_inventoryDateLabel)
                              ? row.expiration
                              : row.manufactureDate
                          )
                      ]}
                      rowData={inventorySummaries}
                      selectedRows={selectedRows}
                      selectRowCallback={(row: InventorySummaryDto) => {
                        const rowId = row.inventoryId;
                        if (selectedRows.includes(rowId)) {
                          dispatch(
                            selectRows(
                              selectedRows.filter((id) => id !== rowId)
                            )
                          );
                          dispatch(
                            selectSummaries(
                              selectedSummaries.filter(
                                (summary) => summary?.inventoryId !== rowId
                              )
                            )
                          );
                          if (
                            selectedRows.length === 1 &&
                            (isBinView || isProductsView)
                          ) {
                            props.clearSelectedVariant();
                          }
                        } else {
                          dispatch(selectRows([...selectedRows, rowId]));
                          dispatch(
                            selectSummaries([
                              ...selectedSummaries,
                              inventorySummaries.find(
                                (summary) => summary.inventoryId === rowId
                              ) || null
                            ])
                          );
                        }
                        if (isBinComponentShown) {
                          dispatch(setIsBinComponentShown(false));
                        }
                      }}
                      loading={loadingInventorySummaries}
                    />
                  )}
                {isAdjustingBins &&
                  ((inventorySummaries && inventorySummaries.length > 0) ||
                    loadingInventorySummaries) && (
                    <AutostoreTable<InventoryDto>
                      widthOfCols={
                        !isMobile
                          ? ["40%", "20%", "20%", "10%", "10%"]
                          : ["40%", "20%", "20%", "20%"]
                      }
                      headerVariant="overline"
                      bodyVariant="body2"
                      headerColNames={
                        !isMobile
                          ? [
                              t("bin"),
                              t("quantity"),
                              t("hold type"),
                              t("committed"),
                              t(inventoryDateLabel(inv_inventoryDateLabel))
                            ]
                          : [
                              t("bin"),
                              t("quantity"),
                              t(inventoryDateLabel(inv_inventoryDateLabel))
                            ]
                      }
                      rowId={(row: InventoryDto) => row.inventoryId}
                      renderColumns={[
                        (row: InventoryDto) =>
                          row.bin.autostoreBin?.autostoreBinId?.toString() ??
                          "",
                        (row: InventoryDto) =>
                          `${row.count?.value !== undefined ? row.count?.value : t("n/a")} ${row.count?.units || ""}`,
                        (row: InventoryDto) => {
                          return (
                            <Box>
                              {row.holds?.map((hold, index) => (
                                <Box key={`hold-${index}`}>
                                  <Typography>
                                    {formatHoldText(hold)}
                                  </Typography>
                                </Box>
                              )) || null}
                            </Box>
                          );
                        },
                        (row: InventoryDto) =>
                          `${row.committedCount?.value !== undefined ? row.committedCount?.value : ""} ${row.committedCount?.units || ""}`,
                        (row: InventoryDto) =>
                          formatUtcDate(
                            checkIsExpiration(inv_inventoryDateLabel)
                              ? row.expiration
                              : row.manufactureDate
                          )
                      ]}
                      rowData={
                        selectedInventoryAtPort ? [inventoryAtPort[0]] : []
                      }
                      selectedRows={selectedRows}
                      loading={!selectedInventoryAtPort}
                    />
                  )}
              </Paper>
            </Grid>
            {!selectedInventoryAtPort && totalPageCount > 1 && (
              <Grid container justifyContent="center" style={{ marginTop: 16 }}>
                <Pagination
                  count={totalPageCount}
                  page={page}
                  onChange={(_e, p) => {
                    setPage(p);
                    if (window.scrollTo) window.scrollTo(0, 0);
                  }}
                  shape="rounded"
                />
              </Grid>
            )}
          </Grid>

          <InventoryAddDialog
            onClose={() => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await thishandleExitGetBins();
              softReset();
              dispatch(setIsAddPanelOpen(false));
            }}
            open={isAddPanelOpen}
            variant={selectedVariant || undefined}
            autostorePortId={selectedPortId}
            refreshCb={() => {
              handleGetInventorySummaries({
                variantId: variantIdParam
              });
              // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
              if (variantIdParam) props.getInventoryReport(variantIdParam);
              if (isAutostoreView(search)) handleCloseBin();
            }}
            getEmptyBin={handleGetEmptyBinClick}
            disableClosePortOnClose
            isFetchingBin={!binAtPort}
          />

          <InventoryAdjustDialog
            open={isAdjustPanelOpen}
            onClose={() => {
              if (!isAdjustingBins) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                handleExitGetBins();
                softReset();
              }
              dispatch(setIsAdjustPanelOpen(false));
            }}
            disabled={isAutostoreView(search) ? isFetchingBin : false}
            invToModify={selectedInventoryToModify}
            selectedVariant={selectedVariant || null}
            autostoreBinConfiguration={autostoreBinConfiguration}
            refreshCb={({ inventoryWasEmptied }) => {
              dispatch(setIsAdjustPanelOpen(false));
              if (
                !inventoryWasEmptied &&
                inventoryAtPort.length &&
                inventoryAtPort[0].bin.autostoreBin &&
                selectedAutostoreGridId
              )
                // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                props.findInventoryByAutostoreBinNumber(
                  selectedAutostoreGridId,
                  inventoryAtPort[0].bin.autostoreBin?.autostoreBinId
                );
              if (isAutostoreView(search) && inventoryWasEmptied) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                handleGetNextBin();
              }
              if (!isAdjustingBins && selectedVariant) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                props.getInventoryReport(selectedVariant?.variantId);
                dispatch(selectRows([]));
                dispatch(selectSummaries([]));
                handleRefetchInventory();
              }
            }}
            canceledReason=""
            isAdjustOnMain
          />
        </Grid>
        <Dialog
          key="new-bin-dialog"
          open={newBinSlotModalOpen}
          onClose={() => {
            setNewBinSlotModalOpen(false);
          }}
        >
          <DialogTitle>{slotBin ? "Update Slot" : "Assign Slot"}</DialogTitle>
          <DialogContent style={{ textAlign: "center" }}>
            <FormControl style={{ margin: 10 }}>
              <Autocomplete
                id="combo-box-demo"
                options={newBinSlotAutocomplete}
                filterOptions={(ops) => ops}
                getOptionLabel={(option) => option.location}
                style={{ width: 220 }}
                open={!!newBinSlotAutocomplete.length}
                onChange={(_e, option) => {
                  setNewBinSlotInputValue(null);
                  setSelectedBinSlot(option);
                }}
                inputValue={
                  newBinSlotInputValue || selectedBinSlot?.location || ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    type="text"
                    label={t("scan new bin")}
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                      setSelectedBinSlot(null);
                      setNewBinSlotInputValue(
                        (e.target.value as string).replace("*", "")
                      );
                      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                      autocompleteBinSearch(e.target.value as string);
                    }}
                  />
                )}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                setNewBinSlotAutcomplete([]);
                setNewBinSlotInputValue(null);
                setNewBinSlotModalOpen(false);
              }}
              color="primary"
            >
              {t("cancel")}
            </Button>
            <Button
              onClick={() => {
                if (slotBin && firstSlottingInfo) {
                  confirmBinSlotUpdate();
                } else {
                  confirmCreateBinSlot();
                }
                setNewBinSlotAutcomplete([]);
                setNewBinSlotInputValue(null);
                setNewBinSlotModalOpen(false);
              }}
              color="primary"
              autoFocus
              disabled={!selectedBinSlot}
            >
              {t("confirm")}
            </Button>
          </DialogActions>
        </Dialog>
        <CycleCountFrequencyModal
          closeModalCb={() => setIsFrequencyModalOpen(false)}
          initialFrequency={initialFrequency}
          open={isFrequencyModalOpen}
        />
        <BigChoiceModal
          isOpen={showScanChoiceModal}
          choiceTitle="Select Variant"
          choices={scanChoices.map((scanChoice) => ({
            label: scanChoice.displayText,
            value: scanChoice.variantId || ""
          }))}
          selectCb={(selected) => {
            handleScanChoiceSelect(selected);
          }}
          closeCb={() => setShowScanChoiceModal(false)}
        />
        <ConfirmationModal
          isOpen={isNavigateAwayModalOpen}
          confirmCb={() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
            closePort();
            if (binAtPort) {
              props.binNotAtPort();
            }
            dispatch(setIsFetchingBin(false));
            setIsBinComponentShown(false);
            dispatch(setPortPollingActive(false));
            setIsNavigateAwayModalOpen(false);
            resetPage();
          }}
          closeCb={() => {
            setIsNavigateAwayModalOpen(false);
          }}
          modalTitle={t("are you sure you want to close the workstation")}
          modalText=""
        />
        {/* Change to modify multiple rows to the same hold status */}
        {isAnySelect && (
          <InventoryHoldModal
            confirmCb={async (holdType: HoldType) => {
              if (!isAdjustingBins) {
                await Promise.any(
                  selectedRows.map(async (rowId: string) => {
                    await props.placeInventoryHold(
                      rowId,
                      holdType.reasonCode,
                      thisWorkstationId || null
                    );
                  })
                );
                // if we are not adjusting bins & update hold, we want to reset the page.
                dispatch(selectRows([]));
                dispatch(selectSummaries([]));
                handleRefetchInventory();
              } else if (selectedInventoryAtPort) {
                // if not adjusting bins, don't reset selected inventory & don't reset page
                await props.placeInventoryHold(
                  selectedInventoryAtPort.inventoryId,
                  holdType.reasonCode,
                  thisWorkstationId || null
                );
                // after hold is placed, update inventory data
                if (
                  inventoryAtPort.length &&
                  inventoryAtPort[0].bin.autostoreBin &&
                  selectedAutostoreGridId
                )
                  await props.findInventoryByAutostoreBinNumber(
                    selectedAutostoreGridId,
                    inventoryAtPort[0].bin.autostoreBin?.autostoreBinId
                  );
              }

              dispatch(setIsBinHoldModalOpen(false));
            }}
            open={isBinHoldModalOpen}
            closeModalCallback={() => dispatch(setIsBinHoldModalOpen(false))}
            maxWidth="sm"
            options={getHoldTypeOptions(excludeRecalledHold)}
            confirmButtonText={t("set hold")}
            currentHold=""
          />
        )}
        <ScanningIndicator
          scanState={scanState}
          scannedBarcode={scannedBarcode}
          placeholderText="Scan Product or Bin Barcode"
        />
      </Container>
    </>
  );
}

export default connector(InventoryOld);
