import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  Box,
  Typography,
  AccordionDetails,
  CircularProgress
} from "@mui/material";
import { ErrorPanel } from "@qubit/autoparts";

import { skipToken } from "@reduxjs/toolkit/query";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectWorkstationAutostoreGridId } from "~/redux/selectors/workstationsSelectors";

import { useAvailableBinReportQuery } from "~/redux/warehouse/autostoreGrid.hooks";

type CompartmentCount = 1 | 2 | 4;

export function BinQuantityWidget() {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const autostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const {
    data: availableBinReport,
    isLoading,
    error
  } = useAvailableBinReportQuery(
    autostoreGridId && expanded ? autostoreGridId : skipToken
  );

  return (
    <Box py={4} minWidth="245px">
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: "secondary.dark",
            borderRadius: expanded ? "10px 10px 0 0" : "10px",
            transition: "border-radius"
          }}
        >
          <Typography variant="button" whiteSpace="nowrap">
            {t("estimated available bin counts")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ display: "flex", flexDirection: "column" }}>
          {isLoading && (
            <CircularProgress sx={{ alignSelf: "center" }} size={24} />
          )}
          {error && <ErrorPanel message={getMessageFromRtkError(error)} />}
          {!isLoading && !error && !availableBinReport?.length && (
            <Typography variant="body1">{t("no results")}</Typography>
          )}
          {(availableBinReport ?? [])
            .toSorted((br) => br.numberOfCompartments)
            .map((br) => (
              <Typography variant="body1" key={br.binConfigurationId}>
                {t(
                  `autostoreBin.${br.numberOfCompartments as CompartmentCount} compartments`
                )}
                : {br.binsAvailable}
              </Typography>
            ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
