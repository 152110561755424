import { LoadingButton } from "@mui/lab";
import { ButtonProps } from "@mui/material";

import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useAppDispatch } from "~/app/store";
import { setAndonLoginDefaults } from "~/features/andon/andon.slice";

import { loginAndon, loginQubit } from "~/features/login/login.slice";
import { mixpanelTrack } from "~/lib/mixpanel-tracking";

type OrganizationLoginButtonProps = {
  organizationName?: string;
} & ButtonProps;

export function OrganizationLoginButton(props: OrganizationLoginButtonProps) {
  const dispatch = useAppDispatch();
  const { organizationName, children, ...rest } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [searchParams] = useSearchParams();

  const handleClick = async () => {
    setIsLoading(true);
    mixpanelTrack({
      trackedPageName: "Organization Login",
      type: "Login",
      label: organizationName || ""
    });
    dispatch(setAndonLoginDefaults());

    if (searchParams.get("andon-login") === "true") {
      await dispatch(
        loginAndon({
          authorizationParams: {
            organization: organizationName
          }
        })
      );
    } else {
      await dispatch(
        loginQubit({
          authorizationParams: {
            organization: organizationName
          }
        })
      );
    }
    setIsLoading(false);
  };

  return (
    <LoadingButton
      variant="contained"
      color="primary"
      id="login-button"
      disabled={!organizationName}
      onClick={handleClick}
      loading={isLoading}
      {...rest}
    >
      {children || "Login"}
    </LoadingButton>
  );
}
