/**
 * If the colours from the Main collection don’t solve the situation you’re designing for,
 * pick colours from these collections. When a colour has black or white text on top, that
 * indicates the specific combination passes the accessibility tests.
 *
 * Used ~2% of the time.
 *
 * https://www.figma.com/design/hdLvs2exVK51yLFq4FGSU0/ASDS-%E2%80%93-Base?node-id=208-7&node-type=frame&m=dev
 */

export const basicColours = {
  mono: {
    white: "#FFFFFF",
    50: "#F6F6F6",
    100: "#EEEEEE",
    200: "#E2E2E2",
    300: "#CBCBCB",
    400: "#AFAFAF",
    500: "#757575",
    600: "#545454",
    700: "#333333",
    black: "#000000"
  },
  blue: {
    50: "#EFF3FE",
    100: "#D4E2FC",
    200: "#A0BFF8",
    300: "#5B91F5",
    400: "#276FEC",
    500: "#1E54B7",
    600: "#174291",
    700: "#102C60",
    main: "#276FEC"
  },
  green: {
    50: "#E6F2ED",
    100: "#ADDEC9",
    200: "#66D19E",
    300: "#06C167",
    400: "#15844B",
    500: "#03703C",
    600: "#03582F",
    700: "#10462D",
    main: "#15844B"
  },
  red: {
    50: "#FFEFED",
    100: "#FED7D2",
    200: "#F1998E",
    300: "#E85C4A",
    400: "#E11B22",
    500: "#AB1300",
    600: "#870F00",
    700: "#5A0A00",
    main: "#E11B22"
  },
  yellow: {
    50: "#FFFAF0",
    100: "#FFF2D9",
    200: "#FFE3AC",
    300: "#FFCF70",
    400: "#FFC043",
    500: "#956F23",
    600: "#835F19",
    700: "#674D1B",
    main: "#FFC043"
  }
};

/**
 * When picking colors for UI design, first try to pick a color from this collection. Used ~98% of the time.
 *
 * https://www.figma.com/design/hdLvs2exVK51yLFq4FGSU0/ASDS-%E2%80%93-Base?node-id=104-470&node-type=text&m=dev
 */
export const mainColours = {
  /**
   * Use for fills, backgrounds, shapes, frames, overlays, etc.
   */
  background: {
    primary: basicColours.mono.white,
    secondary: "#F0F0F0",
    hover: "#0000000D",
    active: "#00000017",
    inverse: {
      primary: basicColours.mono.black,
      hover: basicColours.mono[700]
    },
    accent: {
      primary: basicColours.blue.main,
      hover: "#185BD1"
    },
    accentLight: {
      primary: basicColours.blue[50],
      hover: "#E4ECFF"
    },
    success: {
      primary: basicColours.green.main,
      hover: "#0E733F"
    },
    successLight: {
      primary: basicColours.green[50],
      hover: "#DAEDE5"
    },
    error: {
      primary: basicColours.red.main,
      hover: "#C91218"
    },
    errorLight: {
      primary: basicColours.red[50],
      hover: "#FFE5E2"
    },
    warning: {
      primary: basicColours.yellow.main,
      hover: "#F0B135"
    },
    warningLight: {
      primary: basicColours.yellow[100],
      hover: "#F8E9CE"
    }
  },
  /**
   * Use for text, headlines, paragraphs, labels, titles, etc.
   */
  text: {
    primary: "#000000DE",
    secondary: "#00000099",
    disabled: "#0000004D",
    inactive: "#00000066",
    inactiveHover: "#00000099",
    inverse: {
      primary: basicColours.mono.white,
      secondary: "#FFFFFFA6",
      disabled: "#FFFFFF73"
    },
    accent: "#2260D3",
    success: "#137643",
    error: "#D2191F",
    warning: "#84621F"
  },
  /**
   * Use for icons.
   */
  icon: {
    primary: basicColours.mono.black,
    secondary: "#0000008A",
    disabled: "#0000004D",
    inactive: "#00000059",
    inactiveHover: "#00000099",
    inverse: {
      primary: basicColours.mono.white,
      disabled: "#FFFFFF73"
    },
    accent: "#2260D3",
    success: "#189A57",
    error: "#F51D00",
    warning: "#BA8A2C"
  },
  /**
   * Use for lines, borders, dividers, outlines, etc..
   */
  border: {
    primary: "#00000033",
    secondary: "#0000001A",
    inverse: {
      primary: "#FFFFFF59",
      secondary: "#FFFFFF40"
    },
    accent: "#2260D3",
    success: "#189A57",
    error: "#E11B22",
    warning: "#BA8A2C"
  },
  /**
   * Use for Tags (in Components) and other labels.
   */
  label: {
    1: "#BDD3FF",
    2: "#C8BDFF",
    3: "#E9BDFF",
    4: "#FFBDE9",
    5: "#FFBDBD",
    6: "#FFD3BD",
    7: "#FFE3AC",
    8: "#EBEB9D",
    9: "#DAF5A5",
    10: "#BAFFAC",
    11: "#ACFFD5",
    12: "#ACF1FF"
  },
  /**
   * Use for Parts (in Assets) and other representations of Grid parts.
   */
  part: {
    accessPoint: "#B66DFF",
    b1ChargePoint: "#FFBDBD",
    blocked: "#FFAEAE",
    buffer: "#D1EB68",
    port: "#04641A",
    portFrame: "#654F6F",
    r5ChargePoint: "#FFDB9C",
    serviceArea: "#FBE0B7",
    serviceWall: "#FFB5E6",
    stockArea: "#EDEDED",
    street: "#BADFFF",
    transfer: "#F8C43F",
    zeroCell: "#CFDEEB"
  },
  /**
   * Use for charts and other infographics.
   */
  infographic: {
    1: "#6DB6FF",
    2: "#006DDB",
    3: "#004949",
    4: "#DB6D00",
    5: "#924900",
    6: "#B66DFF",
    7: "#490092",
    8: "#FFB6DB",
    9: "#920000",
    10: "#B6DBFF",
    11: "#009292",
    12: "#FF6DB6",
    13: "#FFE500",
    14: "#24FF24",
    15: basicColours.mono.black
  },
  /**
   * Use to denote “pick” and “goods-in” Port modes.
   */
  mode: {
    goodsIn: "#9382FF",
    pick: "#FFD363"
  }
};
