import {
  CheckmarkCircle20Px,
  CloseCircle20Px,
  GridDesign20Px
} from "@locaisolutions/icons";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from "@mui/material";
import { useToast } from "@qubit/autoparts";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { AutostoreBin } from "~/features/autostoreBin";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  selectThisWorkstation,
  selectWorkstationAutostoreGridId
} from "~/redux/selectors/workstationsSelectors";
import { useReconfigureBinCompartmentsMutation } from "~/redux/warehouse/autostoreGrid.hooks";
import { useGetBinConfigurationsQuery } from "~/redux/warehouse/bin.hooks";

import { toggleBinReconfigurationModal } from "./binMaintenanceWorkstation.slice";

export function BinReconfigurationModal({
  binNumber,
  onConfirm
}: {
  binNumber: number;
  onConfirm: () => void;
}) {
  const {
    data: availableBinConfigurations,
    error: availableBinConfigurationsError
  } = useGetBinConfigurationsQuery();

  const { errorToast, successToast } = useToast();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { isBinReconfigurationModalOpen } = useAppSelector(
    (state) => state.binMaintenanceWorkstation
  );
  const selectedWorkstation = useAppSelector(selectThisWorkstation);
  const autostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const [reconfigureBinCompartments, { isLoading }] =
    useReconfigureBinCompartmentsMutation();

  const [selectedConfiguration, setSelectedConfiguration] = useState<
    null | number
  >(null);

  if (availableBinConfigurationsError)
    errorToast(getMessageFromRtkError(availableBinConfigurationsError));

  const handleReconfigureCompartments = async () => {
    if (!selectedConfiguration) return;
    const selectedBinConfiguration = availableBinConfigurations?.find(
      (bc) => bc.configurationType === selectedConfiguration
    );

    if (!selectedWorkstation || !autostoreGridId || !selectedBinConfiguration) {
      return;
    }
    try {
      await reconfigureBinCompartments({
        autostoreGridId,
        binNumber,
        configurationId: selectedBinConfiguration.configurationId,
        workstationId: selectedWorkstation.id
      }).unwrap();

      successToast(
        `Successfully reconfigured bin to have ${selectedBinConfiguration.numberOfCompartments} compartments`
      );
      dispatch(toggleBinReconfigurationModal());
      onConfirm();
    } catch (err) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  return (
    <Dialog
      open={isBinReconfigurationModalOpen}
      onClose={() => dispatch(toggleBinReconfigurationModal())}
      maxWidth="xl"
    >
      <DialogTitle>{t("misconfigured bin")} </DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={5} justifyContent="flex-start" m={2}>
          <Typography alignContent="center">
            {t("misconfigured bin")}
          </Typography>
          <Button
            variant="contained"
            size="large"
            startIcon={<GridDesign20Px />}
          >
            {t("reconfigure")}
          </Button>
        </Stack>
        <Stack direction="column" gap={2} mt={2} alignItems="center">
          <Typography variant="h5" mt={2}>
            {t("select bin configuration")}
          </Typography>
          <Stack direction="row" gap={1}>
            {availableBinConfigurations?.map((conf) => (
              <Fragment key={conf.configurationId}>
                <Box
                  aria-label={
                    selectedConfiguration === conf.configurationType
                      ? "selected-bin"
                      : "unselected-bin"
                  }
                  sx={{
                    padding: 1,
                    borderRadius: "0.5em",
                    border: `0.625em solid`,
                    borderColor:
                      selectedConfiguration === conf.configurationType
                        ? "primary.main"
                        : "white"
                  }}
                >
                  <AutostoreBin
                    state={"Port Closed"}
                    pickQuantity={0}
                    pickCompartment={null}
                    numberOfRows={conf.horizontalCompartmentCount}
                    numberOfColumns={conf.verticalCompartmentCount}
                    hideBinId
                    onBinClick={() =>
                      setSelectedConfiguration(conf.configurationType)
                    }
                  />
                </Box>
              </Fragment>
            ))}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ mb: 2 }}>
        <Stack justifyContent="center" direction="row" width="100%" gap={1}>
          <Button
            variant="subtle"
            size="large"
            startIcon={<CloseCircle20Px />}
            onClick={() => dispatch(toggleBinReconfigurationModal())}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            size="large"
            startIcon={<CheckmarkCircle20Px fill="white" />}
            disabled={!selectedConfiguration || isLoading}
            onClick={handleReconfigureCompartments}
          >
            {t("confirm")}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
