import { AppDispatch } from "~/app/store";
import {
  DeleteInventoryHoldFailureAction,
  DeleteInventoryHoldSuccessAction
} from "~/redux/actions/inventory";

import { inventoryApi } from "./inventory";

export const {
  useGetInventoryByAutostoreBinNumberQuery,
  useLazyGetInventoryByAutostoreBinNumberQuery,
  useGetInventorySummaryListQuery,
  useGetInventorySummariesQuery,
  useLazyGetInventorySummaryListQuery,
  usePatchInventoryNetAdjustmentMutation,
  usePostInventoryMoveMutation,
  useGetVariantInventorySummariesQuery,
  useGetInventoryByIdQuery,
  useLazyGetInventoryByIdQuery,
  useDeleteInventoryHoldMutation,
  useGetInventoryWithVariantInfoByAutostoreBinNumberQuery,
  usePostPutawayBinNotEmptyMutation,
  usePostFlagBinInventoryMovementsMutation,
  useGetAvailableHoldReasonCodesQuery
} = inventoryApi.enhanceEndpoints({
  endpoints: {
    patchInventoryNetAdjustment: {
      invalidatesTags: ["put away task", "inventory by autostore bin number"]
    },
    deleteInventoryHold: {
      // updating state in the redux store until it is removed
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          (dispatch as AppDispatch)<DeleteInventoryHoldSuccessAction>({
            type: "inventory/DELETE_INVENTORY_HOLD_SUCCESS"
          });
        } catch {
          (dispatch as AppDispatch)<DeleteInventoryHoldFailureAction>({
            type: "inventory/DELETE_INVENTORY_HOLD_FAILURE"
          });
        }
      }
    }
  }
});
