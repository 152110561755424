import {
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography
} from "@mui/material";
import {
  ASTableV2,
  ASTableV2Body,
  ASTableV2Cell,
  ASTableV2Header,
  ASTableV2Row,
  ASTableV2RowEmpty,
  ASTableV2RowSkeleton,
  ErrorPanel,
  globalDateTimeFormat
} from "@qubit/autoparts";

import { skipToken } from "@reduxjs/toolkit/query";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { useGetAutostoreGridsQuery } from "~/redux/warehouse/autostoreGrid.hooks";
import { useGetUnifyPortErrorsQuery } from "~/redux/warehouse/unify.openApi";

import { useGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";

dayjs.extend(relativeTime);

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 200
    }
  }
};

export const UnifyView = () => {
  const { t } = useTranslation();
  const { data: autostoreGrids } = useGetAutostoreGridsQuery();
  const workstation = useAppSelector(selectThisWorkstation);
  const [dropdownGridId, setDropdownGridId] = useState<Guid>("");
  const [dropdownWorkstationId, setDropdownWorkstationId] = useState<Guid>("");
  const { sortedWorkstations } = useGetWorkstationsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      sortedWorkstations: data?.toSorted(
        (a, b) =>
          a.autostoreGridName.localeCompare(b.autostoreGridName) ||
          a.deviceId.localeCompare(b.deviceId)
      )
    })
  });

  useEffect(() => {
    if (!workstation) return;

    setDropdownGridId(workstation.autostoreGridId);
    setDropdownWorkstationId(workstation.id);
  }, [workstation]);

  const {
    data: portErrors,
    isFetching: isUnifyFetching,
    error: unifyFetchError,
    refetch: refetchPortErrors
  } = useGetUnifyPortErrorsQuery(
    dropdownGridId
      ? {
          autostoreGridId: dropdownGridId,
          workstationId: dropdownWorkstationId,
          limit: null
        }
      : skipToken
  );

  return (
    <Stack sx={{ m: 3, gap: 2 }}>
      <Stack flexDirection="row" gap={2}>
        <Stack>
          <InputLabel id="grid-select" shrink>
            {t("autostore grid")}
          </InputLabel>
          <Select
            labelId="grid-select"
            style={{ width: 300, marginBottom: 10 }}
            value={autostoreGrids ? dropdownGridId : ""}
            onChange={(e: SelectChangeEvent) =>
              setDropdownGridId(e.target.value)
            }
            MenuProps={MenuProps}
          >
            {autostoreGrids?.map((grid) => (
              <MenuItem key={grid.autostoreGridId} value={grid.autostoreGridId}>
                {`${grid.autostoreGridName}`}
              </MenuItem>
            ))}
          </Select>
        </Stack>

        <Stack>
          <InputLabel id="workstation-select" shrink>
            {t("workstation")}
          </InputLabel>
          <Select
            labelId="workstation-select"
            style={{ width: 300, marginBottom: 10 }}
            value={sortedWorkstations ? dropdownWorkstationId : ""}
            onChange={(e: SelectChangeEvent) =>
              setDropdownWorkstationId(e.target.value)
            }
            MenuProps={MenuProps}
            displayEmpty
          >
            <MenuItem value="">
              <em>{t("all")}</em>
            </MenuItem>
            {sortedWorkstations?.map((workstation) => (
              <MenuItem key={workstation.deviceId} value={workstation.id}>
                {`${workstation.deviceId}`}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Stack>
      {unifyFetchError && (
        <ErrorPanel
          message={getMessageFromRtkError(unifyFetchError)}
          tryAgain={{
            onTryAgain: async () => {
              await refetchPortErrors();
            }
          }}
        />
      )}
      {!unifyFetchError && (
        <ASTableV2 gridTemplateColumns={"repeat(5, auto)"} isLoading={false}>
          <ASTableV2Header>
            <ASTableV2Row>
              <ASTableV2Cell>
                <Typography variant="tableBody">
                  {t("date and time")}
                </Typography>
              </ASTableV2Cell>
              <ASTableV2Cell>
                <Typography variant="tableBody">{t("port id")}</Typography>
              </ASTableV2Cell>
              <ASTableV2Cell>
                <Typography variant="tableBody">{t("error code")}</Typography>
              </ASTableV2Cell>
              <ASTableV2Cell>
                <Typography variant="tableBody">
                  {t("error description")}
                </Typography>
              </ASTableV2Cell>
              <ASTableV2Cell>
                <Typography variant="tableBody">{t("status")}</Typography>
              </ASTableV2Cell>
            </ASTableV2Row>
          </ASTableV2Header>
          <ASTableV2Body>
            {isUnifyFetching && <ASTableV2RowSkeleton columns={4} rows={1} />}
            {!isUnifyFetching && !portErrors?.length && (
              <ASTableV2RowEmpty>{t("no results")}</ASTableV2RowEmpty>
            )}
            {!isUnifyFetching &&
              portErrors
                ?.toSorted((a, b) =>
                  dayjs(a.item.timestamp).isBefore(dayjs(b.item.timestamp))
                    ? 1
                    : -1
                )
                .map((row, i) => (
                  <ASTableV2Row key={i}>
                    <ASTableV2Cell
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start"
                      }}
                    >
                      <Typography variant="tableBody">
                        {dayjs(row.item.timestamp).fromNow()}
                      </Typography>
                      <Typography>
                        {dayjs(row.item.timestamp).format(globalDateTimeFormat)}
                      </Typography>
                    </ASTableV2Cell>
                    <ASTableV2Cell>
                      <Typography variant="tableBody">
                        {row.item.portId}
                      </Typography>
                    </ASTableV2Cell>
                    <ASTableV2Cell>
                      <Typography variant="tableBody">
                        {row.item.portErrorCode}
                      </Typography>
                    </ASTableV2Cell>
                    <ASTableV2Cell>
                      <Typography variant="tableBody">
                        {row.item.portError}
                      </Typography>
                    </ASTableV2Cell>
                    <ASTableV2Cell>
                      <Typography variant="tableBody">
                        {row.item.status}
                      </Typography>
                    </ASTableV2Cell>
                  </ASTableV2Row>
                ))}
          </ASTableV2Body>
        </ASTableV2>
      )}
    </Stack>
  );
};
