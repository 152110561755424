import { Components, Theme } from "@mui/material";

export const muiMenuItem: Components<Omit<Theme, "components">> = {
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        ...(ownerState.selected && { color: theme.palette.primary.dark })
      })
    }
  }
};
