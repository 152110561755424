import {
  Alert,
  Box,
  Button,
  Container,
  Typography,
  styled
} from "@mui/material";

import { LoadingPage } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";

import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectWorkstationAutostoreGridId } from "~/redux/selectors/workstationsSelectors";
import { useEmptyBinReportQuery } from "~/redux/warehouse/autostoreGrid.hooks";
import { useGetBinConfigurationsQuery } from "~/redux/warehouse/bin.hooks";

import { BinConfiguration } from "./BinConfiguration";
import { StartBinReconfigurationButton } from "./StartBinReconfigurationButton";
import {
  setSourceBinConfigurationId,
  setTargetBinConfigurationId
} from "./binReconfiguration.slice";

const ViewContainer = styled(Container)({
  height: "calc(100vh - 56px)",
  display: "grid"
});

export const StartBinReconfiguration = ({
  viewTitle
}: {
  viewTitle: ViewNameTranslation;
}) => {
  useNavbar({ viewTitle });
  const dispatch = useAppDispatch();
  const siteAutostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);

  const {
    data: binConfigurations,
    isLoading: binConfigurationsLoading,
    error: binConfigurationsError,
    refetch: refetchBinConfigurations
  } = useGetBinConfigurationsQuery();

  const {
    data: emptyBinReport,
    isLoading: emptyBinReportLoading,
    error: emptyBinReportError,
    refetch: refetchEmptyBinReport
  } = useEmptyBinReportQuery(siteAutostoreGridId ?? skipToken);

  useEffect(() => {
    if (!emptyBinReport?.length) return;

    const binConfigurationWithLeastBins = emptyBinReport.reduce((r, e) =>
      r.binsWithNoInventory < e?.binsWithNoInventory ? r : e
    );
    const binConfigurationWithMostBins = emptyBinReport.reduce((r, e) =>
      r.binsWithNoInventory > e?.binsWithNoInventory ? r : e
    );

    dispatch(
      setSourceBinConfigurationId(
        binConfigurationWithMostBins.binConfigurationId
      )
    );
    dispatch(
      setTargetBinConfigurationId(
        binConfigurationWithLeastBins.binConfigurationId
      )
    );
  }, [emptyBinReport, dispatch]);

  if (binConfigurationsLoading || emptyBinReportLoading) return <LoadingPage />;

  if (binConfigurationsError || emptyBinReportError)
    return (
      <ViewContainer
        maxWidth="xl"
        sx={{
          placeContent: "center"
        }}
      >
        <Alert
          sx={{ gridColumn: "1/-1" }}
          variant="outlined"
          severity="error"
          action={
            <Button
              onClick={async () => {
                await refetchBinConfigurations();
                await refetchEmptyBinReport();
              }}
            >
              Retry
            </Button>
          }
        >
          {getMessageFromRtkError(
            binConfigurationsError ?? emptyBinReportError
          )}
        </Alert>
      </ViewContainer>
    );

  return (
    <Container
      maxWidth="xl"
      sx={{
        padding: 2,
        display: "grid",
        gridTemplateRows: "auto auto auto",
        gridTemplateColumns: "0.7fr",
        placeContent: "center",
        gap: 2
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, auto)",
          gridTemplateRows: "auto 1fr",
          gap: 2,
          p: 3
        }}
      >
        <Typography variant="h3" gridColumn="1/-1">
          I want to change
        </Typography>
        {binConfigurations?.map((bc) => (
          <BinConfiguration
            key={bc.configurationId}
            binConfigurationId={bc.configurationId}
            variant="source"
          />
        ))}
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, auto)",
          gridTemplateRows: "auto auto 1fr",
          gap: 2,
          p: 3
        }}
      >
        <Typography variant="h3" gridColumn="1/-1">
          and make them...
        </Typography>
        {binConfigurations?.map((bc) => (
          <BinConfiguration
            key={bc.configurationId}
            binConfigurationId={bc.configurationId}
            variant="target"
          />
        ))}
      </Box>

      <StartBinReconfigurationButton />
    </Container>
  );
};
