import { Components, Theme } from "@mui/material";

export const muiToolbar: Components<Omit<Theme, "components">> = {
  MuiToolbar: {
    styleOverrides: {
      root: {
        paddingLeft: "4px",
        height: "48px",
        minHeight: "32px",
        "@media (min-width: 600px)": {
          minHeight: "48px",
          paddingLeft: "4px"
        }
      }
    }
  }
};
