import { Plus20Px } from "@locaisolutions/icons";

import { Box, Button, SvgIcon } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import useFlag from "~/config/flags";
import { mixpanelTrack, useMixpanelPageName } from "~/lib/mixpanel-tracking";

import { CycleCountFrequencyModal } from "./components/modals/CycleCountFrequencyModal";

interface CycleCountFrequencyButtonProps {
  disabled: boolean;
  initialFrequency: number | null;
}

const CycleCountFrequencyButton: React.FC<CycleCountFrequencyButtonProps> = ({
  disabled,
  initialFrequency
}) => {
  const { t } = useTranslation();
  const showCycleCountFrequencyButton = useFlag().cycleCountOverhaul;
  const [isFrequencyModalOpen, setIsFrequencyModalOpen] = useState(false);
  const trackedPageName = useMixpanelPageName();
  return (
    <>
      {showCycleCountFrequencyButton && (
        <Box mt={2}>
          <Button
            variant="subtle"
            onClick={() => {
              setIsFrequencyModalOpen(true);
              mixpanelTrack({
                trackedPageName,
                type: "Button Click",
                label: "Add Cycle Count Frequency"
              });
            }}
            disabled={disabled}
            sx={{
              fontWeight: "normal"
            }}
            startIcon={
              <SvgIcon
                sx={{ height: "12px", width: "12px" }}
                viewBox="0 0 12 12"
                component={Plus20Px}
              />
            }
          >
            {t("add cycle count frequency")}
          </Button>
        </Box>
      )}
      <CycleCountFrequencyModal
        closeModalCb={() => {
          setIsFrequencyModalOpen(false);
          mixpanelTrack({
            trackedPageName,
            type: "Button Click",
            label: "Close Cycle Count Frequency Modal"
          });
        }}
        initialFrequency={initialFrequency}
        open={isFrequencyModalOpen}
      />
    </>
  );
};

export default CycleCountFrequencyButton;
