import WarningIcon from "@mui/icons-material/Warning";
import { Container, Grid, Box, Alert, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { getCompartmentNumbersWithStatus } from "~/lib/getCompartmentNumberWithStatus";
import { getEmptyCompartmentNumbers } from "~/lib/getEmptyBinCompartmentNumbers";

import { GetPortResponse } from "~/redux/actions";
import { NextBinInventory } from "~/redux/reducers/autostore";

import { selectBinsFlags } from "~/redux/selectors/autostoreSelectors";
import { WorkstationDto, NextEmptyBinResponse } from "~/types/api";

import { AutostoreBin } from "./AutostoreBin";

function MultiPort(props: {
  workstation: WorkstationDto | null;
  /** Empty bin info for each port in workstation */
  nextEmptyBinByPort?: {
    [portId: number]: NextEmptyBinResponse | null;
  };
  /** Non-Empty bin info for each port in workstation */
  nextBinInventoryByPort?: {
    [portId: number]: NextBinInventory;
  };
  /** Port state for each port in workstation */
  portStateByPort: {
    [portId: number]: {
      getPortResponse: GetPortResponse;
      timestamp: Date;
    };
  };
  currentSelectedBinId?: number | null;
  /** Qty to display in the selected compartment */
  pickQuantity?: number;
  selectedCompartment?: number;
  onBinClick?: (compartment?: number, binNumber?: number) => void;
  allowSelectingCompartmentsWithInventoryOnly?: boolean;
  allowSelectingCompartmentsWithoutInventoryOnly?: boolean;
  selectedBin?: NextEmptyBinResponse | null;
  setSelectedBinCallback?: (bin: NextEmptyBinResponse) => void;
  enableBinFullWarning?: boolean;
  showLoading?: boolean;
  selectedSummariesAtPort?: string[];
  showBinsFlags?: boolean;
}) {
  const {
    pickQuantity = 0,
    selectedCompartment,
    workstation,
    nextEmptyBinByPort,
    nextBinInventoryByPort,
    portStateByPort,
    currentSelectedBinId,
    onBinClick,
    allowSelectingCompartmentsWithInventoryOnly,
    allowSelectingCompartmentsWithoutInventoryOnly,
    selectedBin,
    setSelectedBinCallback,
    enableBinFullWarning,
    showLoading = false,
    selectedSummariesAtPort,
    showBinsFlags
  } = props;

  const binsFlags = useAppSelector(selectBinsFlags);

  const { t } = useTranslation();
  const binConfigMap: {
    [key: string]: {
      horizontalCompartmentCount: number;
      numberOfCompartments: number;
      verticalCompartmentCount: number;
    };
  } = {
    "whole bin": {
      horizontalCompartmentCount: 1,
      numberOfCompartments: 1,
      verticalCompartmentCount: 1
    },
    "half bin": {
      horizontalCompartmentCount: 1,
      numberOfCompartments: 2,
      verticalCompartmentCount: 2
    },
    "quarter bin": {
      horizontalCompartmentCount: 2,
      numberOfCompartments: 4,
      verticalCompartmentCount: 2
    }
  };

  return (
    <Container>
      <Grid container component="ul" sx={{ listStyleType: "none", pl: 0 }}>
        {workstation?.ports.map((port, i) => {
          const { portId } = port;
          /** Whole bin, half bin, quarter bin */
          const portConfig =
            (port.inductionBinConfiguration.length &&
              port.inductionBinConfiguration[0].toLowerCase()) ||
            "whole bin";
          const binConfigCompartments = binConfigMap[portConfig];
          const emptyBinAtThisPort = nextEmptyBinByPort
            ? nextEmptyBinByPort[portId]
            : null;
          const nextInventoryBinAtThisPort = nextBinInventoryByPort
            ? nextBinInventoryByPort[portId]
            : null;
          const isPortWaitingForBin =
            showLoading ||
            ((emptyBinAtThisPort || nextInventoryBinAtThisPort) &&
              !portStateByPort[portId]?.getPortResponse.isReady);
          const numberOfColumns =
            emptyBinAtThisPort?.autostoreBinConfiguration
              ?.verticalCompartmentCount ||
            nextInventoryBinAtThisPort?.nextBinConfiguration
              ?.verticalCompartmentCount ||
            binConfigCompartments.verticalCompartmentCount;
          const numberOfRows =
            emptyBinAtThisPort?.autostoreBinConfiguration
              ?.horizontalCompartmentCount ||
            nextInventoryBinAtThisPort?.nextBinConfiguration
              ?.horizontalCompartmentCount ||
            binConfigCompartments.horizontalCompartmentCount;
          const binId =
            emptyBinAtThisPort?.openBinResponse?.binId ||
            nextInventoryBinAtThisPort?.nextBinInventory[0]?.bin.autostoreBin
              ?.autostoreBinId;
          const isThisPortSelected =
            currentSelectedBinId && currentSelectedBinId === binId;

          // maybe refactor to use the function below?
          const emptyCompartmentsNumbers = nextInventoryBinAtThisPort
            ? getEmptyCompartmentNumbers(
                nextInventoryBinAtThisPort?.nextBinResponse
              )
            : getEmptyCompartmentNumbers(emptyBinAtThisPort);

          const compartmentNumbersWithstatuses =
            getCompartmentNumbersWithStatus(
              nextInventoryBinAtThisPort
                ? nextInventoryBinAtThisPort?.nextBinResponse
                : emptyBinAtThisPort
            );
          // get defaultSelected compartment number?
          const defaultCompartmentInventoryRecord = nextInventoryBinAtThisPort
            ? nextInventoryBinAtThisPort?.nextBinResponse?.inventory.find(
                (inventoryRecord) => {
                  if (
                    inventoryRecord?.inventoryId &&
                    inventoryRecord.bin?.autostoreCompartmentNumber
                  ) {
                    return selectedSummariesAtPort?.includes(
                      inventoryRecord?.inventoryId
                    ) && inventoryRecord.bin?.autostoreCompartmentNumber
                      ? inventoryRecord?.bin.autostoreCompartmentNumber
                      : undefined;
                  }
                  return undefined;
                }
              )
            : undefined;

          const defaultCompartmentNumber =
            defaultCompartmentInventoryRecord &&
            defaultCompartmentInventoryRecord?.bin?.autostoreCompartmentNumber
              ? defaultCompartmentInventoryRecord.bin
                  .autostoreCompartmentNumber - 1
              : undefined;

          const warningBinFullElement = enableBinFullWarning &&
            portStateByPort[portId]?.getPortResponse.isReady &&
            !emptyCompartmentsNumbers?.length && (
              <Box sx={{ margin: "5px" }}>
                <Alert
                  severity="warning"
                  icon={<WarningIcon sx={{ marginY: "auto" }} />}
                  variant="filled"
                >
                  {t("inventory full")}
                </Alert>
              </Box>
            );

          const binFlags = binsFlags?.find((b) => b.binId === binId)?.flags;
          const binBorder =
            (!selectedBin &&
              isThisPortSelected &&
              workstation.ports.length > 1) ||
            (selectedBin &&
              (selectedBin === emptyBinAtThisPort ||
                selectedBin === nextInventoryBinAtThisPort?.nextBinResponse))
              ? "primary.main"
              : "transparent";
          return (
            <Grid
              item
              xs={12 / workstation.ports.length}
              data-testid="binComponent"
              key={portId}
              component="li"
              aria-label={`port ${portId}`}
              aria-selected={!!isThisPortSelected}
            >
              {binFlags?.length && showBinsFlags ? (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignContent="center"
                  sx={{ ml: 3, position: "fixed" }}
                >
                  <Typography variant="h6">
                    {t("flag")}: {binFlags.join(", ")}
                  </Typography>
                </Box>
              ) : (
                ""
              )}
              <Stack
                alignItems="center"
                sx={{
                  mt:
                    binsFlags?.some((b) => b.flags?.length > 0) && showBinsFlags
                      ? 4
                      : 0,
                  padding: 1,
                  borderRadius: "0.5em",
                  border: `0.625em solid`,
                  borderColor: binBorder
                }}
                data-testid={`bin-container-${i}`}
              >
                <AutostoreBin
                  key={`${port.portId}${binId || "no-bin"}`}
                  state={isPortWaitingForBin ? "Waiting for Bin" : "Bin Opened"}
                  numberOfRows={numberOfRows}
                  numberOfColumns={numberOfColumns}
                  pickQuantity={pickQuantity}
                  pickCompartment={null}
                  hideBinId={false}
                  binId={binId}
                  emptyCompartments={emptyCompartmentsNumbers}
                  compartmentNumberWithStatuses={compartmentNumbersWithstatuses}
                  selectedCompartment={
                    isThisPortSelected
                      ? selectedCompartment
                      : defaultCompartmentNumber
                  }
                  onBinClick={(compartment) => {
                    if (compartment === undefined) {
                      return;
                    }
                    if (allowSelectingCompartmentsWithoutInventoryOnly) {
                      // Note: 'compartment' is numbered 0-3 while 'autostoreCompartmentNumber' is 1-4
                      const isCompartmentEmpty =
                        emptyBinAtThisPort?.autostoreBinCompartments.find(
                          (binCompartments) =>
                            binCompartments.autostoreCompartmentNumber ===
                            compartment + 1
                        )?.isAutostoreBinCompartmentEmpty;
                      if (!isCompartmentEmpty) {
                        return;
                      }
                    }
                    if (setSelectedBinCallback) {
                      if (emptyBinAtThisPort)
                        setSelectedBinCallback(emptyBinAtThisPort);
                      else if (nextInventoryBinAtThisPort)
                        setSelectedBinCallback(
                          nextInventoryBinAtThisPort.nextBinResponse
                        );
                    }

                    if (onBinClick && binId) {
                      onBinClick(compartment, binId);
                    }
                  }}
                  allowSelectingCompartmentsWithInventoryOnly={
                    allowSelectingCompartmentsWithInventoryOnly
                  }
                  data-testid={`binComponent-${portId}-compartment-${
                    selectedCompartment !== undefined
                      ? selectedCompartment
                      : "undefined"
                  }`}
                />

                {warningBinFullElement}
              </Stack>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}

export default MultiPort;
