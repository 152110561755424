import { Components, Theme } from "@mui/material";

export const muiAlert: Components<Omit<Theme, "components">> = {
  MuiAlert: {
    styleOverrides: {
      root: {
        fontSize: "1.5rem",
        lineHeight: "1.5rem",
        alignItems: "center",
        gap: "16px"
      },
      message: {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        padding: "16px 0"
      },
      icon: {
        fontSize: "2rem"
      },
      action: {
        padding: 0
      }
    }
  }
};
