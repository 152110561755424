import {
  CheckmarkCircle20Px,
  CloseCircle20Px,
  Warning20Px
} from "@locaisolutions/icons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useToast } from "@qubit/autoparts";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { AutostoreBin } from "~/features/autostoreBin";
import { ActivityState } from "~/features/autostoreBin/ActivityState";
import { useBarcodeScanner, useKeyDownHandler } from "~/lib/barCodeScan";
import { getBarcodeValue } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";

import {
  selectThisWorkstation,
  selectWorkstationAutostoreGridId
} from "~/redux/selectors/workstationsSelectors";
import { usePostBinNotEmptyInventoryMovementMutation } from "~/redux/warehouse/autostoreGrid.hooks";

import { GetBinResponse } from "~/types/api";

type BinNotEmptyButtonProps = {
  state: ActivityState;
  binId: number | undefined;
  numberOfRows: number;
  numberOfColumns: number;
  portId: number;
  binState: GetBinResponse | undefined;
};

export function BinNotEmptyButton(props: BinNotEmptyButtonProps) {
  const { state, binId, numberOfRows, numberOfColumns, binState } = props;
  const theme = useTheme();
  const { errorToast, successToast } = useToast();
  const { t } = useTranslation();
  const gridId = useAppSelector(selectWorkstationAutostoreGridId);
  const siteWorkstation = useAppSelector(selectThisWorkstation);

  const [barcodeInput, setBarcodeInput] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedCompartment, setSelectedCompartment] = useState<number | null>(
    null
  );

  const selectedCompartmentBinId =
    selectedCompartment !== null &&
    binState?.compartments[selectedCompartment].binId;

  const [setBinNotEmpty, { error, isSuccess }] =
    usePostBinNotEmptyInventoryMovementMutation();
  useEffect(() => {
    if (isSuccess) {
      successToast(`Hold placed on UPC ${barcodeInput}`);
    } else if (error) {
      errorToast(getMessageFromRtkError(error));
    }
  }, [error, isSuccess, errorToast, barcodeInput, successToast]);

  useKeyDownHandler();
  useBarcodeScanner({
    findScanMatch: (initialBuffer: string) => {
      const barcodeValue: string = getBarcodeValue(initialBuffer);
      setBarcodeInput(barcodeValue);
    },
    processScanMatch: () => null
  });
  useEffect(() => {
    if (!isModalOpen) setBarcodeInput("");
  }, [isModalOpen]);

  const handleConfirm = async () => {
    if (
      !binId ||
      !gridId ||
      !siteWorkstation ||
      !selectedCompartmentBinId ||
      !barcodeInput
    )
      return;
    await setBinNotEmpty({
      productIdentifier: barcodeInput,
      compartment: selectedCompartment + 1,
      gridId,
      workstationId: siteWorkstation.id
    });
  };
  return (
    <>
      <Button
        variant="subtle"
        size="large"
        startIcon={<Warning20Px />}
        onClick={() => setIsModalOpen(true)}
      >
        {t("bin not empty")}
      </Button>
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>
          <Stack direction="row" gap={1}>
            <Warning20Px
              style={{ alignSelf: "center" }}
              fill={theme.palette.primary.dark}
            />
            {t("bin not empty")}
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" gap={2} mt={2} alignItems="center">
            <AutostoreBin
              state={state}
              binId={binId}
              numberOfRows={numberOfRows}
              numberOfColumns={numberOfColumns}
              pickQuantity={""}
              pickCompartment={null}
              onBinClick={(compartment) =>
                setSelectedCompartment(compartment ?? null)
              }
              selectedCompartment={selectedCompartment ?? undefined}
            />
            <Typography variant="h5" mt={2}>
              {t("please scan the product in the bin")}
            </Typography>
            <TextField
              sx={{ maxWidth: "250px" }}
              id="barcodeInput"
              type="string"
              label="UPC"
              fullWidth
              value={barcodeInput}
              focused={!!barcodeInput}
              onChange={(event) => setBarcodeInput(event.target.value)}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ mb: 2 }}>
          <Stack justifyContent="center" direction="row" width="100%" gap={1}>
            <Button
              variant="subtle"
              size="large"
              startIcon={<CloseCircle20Px />}
              onClick={() => setIsModalOpen(false)}
            >
              {t("cancel")}
            </Button>
            <Button
              size="large"
              startIcon={<CheckmarkCircle20Px fill="white" />}
              disabled={!barcodeInput || !selectedCompartmentBinId}
              onClick={handleConfirm}
            >
              {t("confirm move")}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}
