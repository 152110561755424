import { Button } from "@mui/material";
import { ConfirmationModal, useToast } from "@qubit/autoparts";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  selectThisWorkstation,
  selectWorkstationAutostoreGridId
} from "~/redux/selectors/workstationsSelectors";
import {
  useDeleteBinFlagMutation,
  usePostSkipInventoryMovementMutation
} from "~/redux/warehouse/autostoreGrid.hooks";
import { BinMovementAssignment } from "~/types/api";

import { setCurrentBins } from "./manageFlaggedInventory.slice";

type Props = {
  tasks: BinMovementAssignment[] | undefined;
  inventoryActionsEnabled: boolean;
  selectedRow: BinMovementAssignment | undefined;
  handleCleanup: () => void;
};

export const UnflagBinButton = ({
  tasks,
  inventoryActionsEnabled,
  selectedRow,
  handleCleanup
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { successToast, errorToast } = useToast();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const workstationAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );
  const [deleteBinFlag] = useDeleteBinFlagMutation();
  const [skipInventoryMovement] = usePostSkipInventoryMovementMutation();

  const handleUnflag = async () => {
    if (!selectedRow || !workstationAutostoreGridId || !siteWorkstation) {
      return errorToast("Missing required data");
    }
    await Promise.all(
      selectedRow.flags.map(async (flag) => {
        if (flag) {
          await deleteBinFlag({
            autostoreGridId: workstationAutostoreGridId,
            binNumber: selectedRow.binId,
            reason: flag,
            workstationId: siteWorkstation.id
          }).unwrap();
        }
      })
    );
  };

  const handleSkip = async () => {
    if (!workstationAutostoreGridId || !siteWorkstation) {
      return errorToast("Missing required data");
    }

    try {
      const nextBins = await skipInventoryMovement({
        autostoreGridId: workstationAutostoreGridId,
        workstationId: siteWorkstation.id
      }).unwrap();
      dispatch(setCurrentBins(nextBins));
    } catch (err) {
      const errorMessage = getMessageFromRtkError(err);
      // error for last task skip (or last group of tasks for the same bin) is expected, don't show toast
      if (
        (tasks?.length === 1 ||
          new Set(tasks?.map((task) => task.binId)).size === 1) &&
        errorMessage.toLowerCase().includes("there is nothing left to pick")
      ) {
        navigate("/inventoryv2?autostore=true");
        return;
      }
      errorToast(errorMessage);
    }
  };

  const onConfirmUnflag = async () => {
    await handleUnflag();
    await handleSkip();
    successToast("Successfully unflagged bin");
    handleCleanup();
  };

  return (
    <>
      <Button
        size="large"
        onClick={() => setIsConfirmModalOpen(true)}
        disabled={!inventoryActionsEnabled}
        sx={{ fontWeight: "normal" }}
      >
        {t("unflag bin")}
      </Button>

      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        confirmCb={() => {
          setIsConfirmModalOpen(false);
          void onConfirmUnflag();
        }}
        closeCb={() => setIsConfirmModalOpen(false)}
        modalTitle={t("are you sure you want to unflag bin")}
      />
    </>
  );
};
