import { Edit20Px } from "@locaisolutions/icons";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  IconButton,
  Button
} from "@mui/material";
import { Stack } from "@mui/system";
import { useToast } from "@qubit/autoparts";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { ActivityState, AutostoreBin } from "~/features/autostoreBin";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { generateInventoryReasonCodeOptions } from "~/redux/actions/inventory";

import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import {
  selectWorkstationAutostoreGridId,
  selectWorkstationId
} from "~/redux/selectors/workstationsSelectors";
import { usePatchInventoryNetAdjustmentMutation } from "~/redux/warehouse/inventory.hooks";

import { InventoryDto } from "~/types/api";

type AdjustButtonProps = {
  portId: number;
  state: ActivityState;
  binId: number | undefined;
  numberOfRows: number;
  numberOfColumns: number;
  compartmentNumber?: number;
  binInventory: InventoryDto[] | undefined;
};

export function AdjustButton(props: AdjustButtonProps) {
  const { t } = useTranslation();
  const { errorToast, successToast } = useToast();
  const workstationId = useAppSelector(selectWorkstationId);
  const autostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const usersFulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const [patchNetAdjustment, { isLoading: isConfirmLoading }] =
    usePatchInventoryNetAdjustmentMutation();

  const {
    portId,
    state,
    binId,
    numberOfRows,
    numberOfColumns,
    binInventory,
    compartmentNumber
  } = props;

  const inventoryToMove = binInventory?.find(
    (inv) => inv.bin.autostoreCompartmentNumber === compartmentNumber
  );
  const clientFCReasonCodes =
    usersFulfillmentCenter?.clientConfiguration
      ?.clientSpecificInventoryAdjustmentReasonCodes;

  const initialQty = inventoryToMove?.count.value ?? null;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentQuantity, setCurrentQuantity] = useState<number | null>(
    inventoryToMove?.count.value ?? null
  );
  const [reasonCode, setReasonCode] = useState<string>("Counted");

  const reasonCodeField = (
    <TextField
      id="reasonCode"
      label={t("reason code")}
      type="string"
      select
      fullWidth
      variant="outlined"
      value={reasonCode || ""}
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{
        sx: { fontSize: "1rem" }
      }}
      SelectProps={{
        onChange: (e) => setReasonCode(e.target.value as string)
      }}
    >
      {generateInventoryReasonCodeOptions(clientFCReasonCodes).map(
        (choiceObj, i, arr) => (
          <MenuItem
            key={`uom-option-${choiceObj.value}`}
            className={`uom-option-${i}`}
            sx={{
              padding: 1,
              fontSize: "24px",
              borderBottomColor: "gray.light",
              borderBottom: i === arr.length - 1 ? "none" : `1px solid`
            }}
            value={choiceObj.value}
          >
            {choiceObj.label}
          </MenuItem>
        )
      )}
    </TextField>
  );

  const netAdjustmentQty =
    currentQuantity !== null &&
    initialQty !== null &&
    currentQuantity - initialQty;
  let confirmText = t("confirm");
  if (netAdjustmentQty && netAdjustmentQty > 0) {
    confirmText = t("add") + ` ${netAdjustmentQty}`;
  } else if (netAdjustmentQty && netAdjustmentQty < 0) {
    confirmText = t("remove") + ` ${netAdjustmentQty * -1}`;
  }

  const handleConfirm = async () => {
    if (!inventoryToMove || netAdjustmentQty === false) return;
    try {
      const netAdjustment = {
        units: inventoryToMove.count.units,
        value: netAdjustmentQty
      };

      await patchNetAdjustment({
        autostoreGridId,
        autostorePortId: portId,
        inventoryId: inventoryToMove.inventoryId,
        netAdjustment,
        netCommittedAdjustment: {
          units: inventoryToMove.count.units,
          value: 0
        },
        reasonCode,
        workstationId
      }).unwrap();

      successToast(t("inventory adjusted"));
      setIsModalOpen(false);
    } catch (err: unknown) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  return (
    <>
      <Button
        startIcon={<Edit20Px fill="white" />}
        size="large"
        onClick={() => setIsModalOpen(true)}
      >
        {t("adjust")}
      </Button>
      <Dialog
        open={isModalOpen}
        maxWidth="xl"
        onClose={() => setIsModalOpen(false)}
      >
        <DialogTitle>{t("adjust quantity")}</DialogTitle>
        <DialogContent sx={{ margin: "30px 30px 0" }}>
          <Stack direction="row" spacing={2} pt={1} mb={4}>
            <TextField
              label={t("quantity")}
              type="number"
              variant="outlined"
              value={currentQuantity}
              onChange={(e) => setCurrentQuantity(+e.target.value)}
              style={{ margin: "auto 0" }}
              slotProps={{
                htmlInput: {
                  role: "spinbutton",
                  sx: {
                    width: "200px",
                    textAlign: "center",
                    fontSize: 50,
                    padding: "35px 10px 35px 18px"
                  },
                  min: 0,
                  step: 1
                }
              }}
            />
            <Stack gap={1} justifyContent="center">
              <IconButton
                aria-label="increase quantity"
                onClick={(): void => {
                  setCurrentQuantity((currentQuantity ?? 0) + 1);
                }}
                size="large"
                sx={{ backgroundColor: "gray.dark", width: "fit-content" }}
              >
                <ExpandLessIcon
                  style={{
                    fontSize: 27,
                    fill: "black"
                  }}
                />
              </IconButton>

              <IconButton
                aria-label="decrease quantity"
                onClick={(): void => {
                  if (currentQuantity === 0) return;
                  setCurrentQuantity((currentQuantity ?? 0) - 1);
                }}
                size="large"
                sx={{
                  backgroundColor: "gray.dark",
                  width: "fit-content"
                }}
              >
                <ExpandMoreIcon
                  style={{
                    fontSize: 27,
                    fill: currentQuantity === 0 ? "gray" : "black"
                  }}
                />
              </IconButton>
            </Stack>
            {currentQuantity !== null && inventoryToMove && (
              <AutostoreBin
                state={state}
                binId={binId}
                pickQuantity={currentQuantity}
                pickCompartment={
                  inventoryToMove.bin.autostoreCompartmentNumber
                    ? inventoryToMove.bin.autostoreCompartmentNumber - 1
                    : null
                }
                numberOfRows={numberOfRows}
                numberOfColumns={numberOfColumns}
              />
            )}
          </Stack>
          {reasonCodeField}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", padding: "30px" }}>
          <Button
            size="large"
            variant="subtle"
            onClick={() => setIsModalOpen(false)}
          >
            {t("cancel")}
          </Button>
          <Button
            size="large"
            onClick={handleConfirm}
            disabled={
              isConfirmLoading || !inventoryToMove || currentQuantity === null
            }
          >
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
