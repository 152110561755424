import { Remove20Px } from "@locaisolutions/icons";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Button,
  Typography,
  styled,
  Tooltip,
  Stack,
  ClickAwayListener
} from "@mui/material";
import { ConfirmationModal, useToast } from "@qubit/autoparts";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";

import { useSetThisWorkstation } from "~/hooks/useSetThisWorkstation";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  selectDropdownWorkstation,
  selectIsCurrentDropdownWorkstationActivated
} from "~/redux/selectors/workstationConfigSelectors";
import { useDeleteWorkstationMutation } from "~/redux/warehouse/workstation.hooks";

const DeleteButton = styled(Button)(() => ({
  "&.Mui-disabled": {
    "& .MuiButton-startIcon": {
      opacity: 0.5
    }
  }
}));

export function DeleteWorkstationButton() {
  const { t } = useTranslation();
  const { errorToast, successToast } = useToast();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [showDeleteWorkstationTooltip, setShowDeleteWorkstationTooltip] =
    useState(false);

  const selectedDropdownWorkstation = useAppSelector(selectDropdownWorkstation);
  const isCurrentWorkstationSelected = useAppSelector(
    selectIsCurrentDropdownWorkstationActivated
  );

  const [deleteWorkstation] = useDeleteWorkstationMutation();
  const setThisWorkstation = useSetThisWorkstation();

  const handleDeleteWorkstation = async () => {
    if (!selectedDropdownWorkstation) {
      return errorToast("Missing data");
    }
    try {
      await deleteWorkstation({
        workstationId: selectedDropdownWorkstation.id
      }).unwrap();
      await setThisWorkstation(null, true);
      successToast("Workstation deleted");
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    }
  };

  return (
    <>
      <Stack alignItems="center" flexDirection="row">
        {isCurrentWorkstationSelected && (
          <ClickAwayListener
            onClickAway={() => setShowDeleteWorkstationTooltip(false)}
          >
            <Tooltip
              title={
                <Typography variant="body2">
                  {t("workstation delete button tooltip")}
                </Typography>
              }
              open={showDeleteWorkstationTooltip}
              disableHoverListener
              placement="right"
              aria-label="delete-workstation-tooltip"
            >
              <HelpOutlineIcon
                sx={{
                  cursor: "pointer",
                  marginRight: "10px"
                }}
                onClick={() =>
                  setShowDeleteWorkstationTooltip((prevState) => !prevState)
                }
              />
            </Tooltip>
          </ClickAwayListener>
        )}
        <DeleteButton
          onClick={() => setConfirmModalOpen(true)}
          variant="subtle"
          startIcon={<Remove20Px />}
          disabled={isCurrentWorkstationSelected}
        >
          <Typography>{t("delete workstation")}</Typography>
        </DeleteButton>
      </Stack>

      <ConfirmationModal
        isOpen={confirmModalOpen}
        confirmCb={() => {
          void handleDeleteWorkstation();
          setConfirmModalOpen(false);
        }}
        closeCb={() => {
          setConfirmModalOpen(false);
        }}
        modalTitle="Delete Workstation"
        modalText={`Are you sure you want to delete the workstation "${
          selectedDropdownWorkstation?.deviceId ?? "Unknown"
        }"? This action cannot be undone.`}
        loader={false}
      />
    </>
  );
}
