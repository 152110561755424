import { Box, Stack, styled, Typography } from "@mui/material";
import {
  ASTableV2,
  ASTableV2Body,
  ASTableV2Cell,
  ASTableV2Header,
  ASTableV2Row,
  ASTableV2RowEmpty,
  ErrorPanel,
  formatUtcDate
} from "@qubit/autoparts";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { extractUserNameFromAuth0Id } from "~/lib/helpers";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { useGetApiKeysQuery } from "~/redux/warehouse/apiKeys.hooks";

const ApiKeysTableContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.gray.dark}`,
  borderRadius: "10px",
  m: 2,
  overflowX: "scroll",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  scrollbarWidth: "none",
  msOverflowStyle: "none"
}));

const ApiKeysView = () => {
  const { t } = useTranslation();

  const [currentPage, setPage] = useState<number>(1);
  const limit = 10;

  const {
    data: apiKeysData = [],
    error,
    isFetching: areApiTokensFetching
  } = useGetApiKeysQuery(undefined, {
    refetchOnMountOrArgChange: true
  });

  const totalPageCount = apiKeysData.length
    ? Math.ceil(apiKeysData.length / limit)
    : 0;
  const apiKeysToDisplay = useMemo(() => {
    return apiKeysData.slice((currentPage - 1) * limit, limit * currentPage);
  }, [apiKeysData, currentPage]);

  if (error)
    return (
      <Stack
        sx={{
          p: 4,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <ErrorPanel message={getMessageFromRtkError(error)} width={"80%"} />
      </Stack>
    );

  return (
    <Stack spacing={4} p={2}>
      <Box>
        <Typography variant="h6">{t("organization api keys")}</Typography>
        <Typography variant="subtitle1">
          {t("organization api keys description")}
        </Typography>
      </Box>
      <ApiKeysTableContainer>
        <ASTableV2
          gridTemplateColumns={`repeat(5, auto)`}
          isLoading={areApiTokensFetching}
          pagination={{
            pageCount: totalPageCount,
            page: currentPage,
            setPage: (pageNumber) => {
              setPage(pageNumber);
            }
          }}
        >
          <ASTableV2Header>
            <ASTableV2Row>
              <ASTableV2Cell>
                <Typography variant="tableBody">{t("name")}</Typography>
              </ASTableV2Cell>
              <ASTableV2Cell>
                <Typography variant="tableBody">{t("created by")}</Typography>
              </ASTableV2Cell>
              <ASTableV2Cell>
                <Typography variant="tableBody">{t("roles")}</Typography>
              </ASTableV2Cell>
              <ASTableV2Cell>
                <Typography variant="tableBody">{t("created on")}</Typography>
              </ASTableV2Cell>
              <ASTableV2Cell>
                <Typography variant="tableBody">{t("expires on")}</Typography>
              </ASTableV2Cell>
            </ASTableV2Row>
          </ASTableV2Header>
          <ASTableV2Body>
            {!areApiTokensFetching && !apiKeysData.length && (
              <ASTableV2RowEmpty>{t("no api keys found")}</ASTableV2RowEmpty>
            )}
            {apiKeysToDisplay.map((apiKey) => {
              const { name, userId, expiresOn, createdOn, role } = apiKey;
              const createdOnDate = formatUtcDate(createdOn) || "-";
              const expiresOnDate = formatUtcDate(expiresOn) || "-";
              const roles = role.join(", ");
              return (
                <ASTableV2Row key={`${name}-key`}>
                  <ASTableV2Cell>
                    <Typography variant="tableBody">{name}</Typography>
                  </ASTableV2Cell>
                  <ASTableV2Cell>
                    <Typography variant="tableBody">
                      {extractUserNameFromAuth0Id(userId)}
                    </Typography>
                  </ASTableV2Cell>
                  <ASTableV2Cell>
                    <Typography variant="tableBody">{roles}</Typography>
                  </ASTableV2Cell>
                  <ASTableV2Cell>
                    <Typography variant="tableBody">{createdOnDate}</Typography>
                  </ASTableV2Cell>
                  <ASTableV2Cell>
                    <Typography variant="tableBody">{expiresOnDate}</Typography>
                  </ASTableV2Cell>
                </ASTableV2Row>
              );
            })}
          </ASTableV2Body>
        </ASTableV2>
      </ApiKeysTableContainer>
    </Stack>
  );
};

export default ApiKeysView;
