import { Bin24Px } from "@locaisolutions/icons";
import { Button, Typography, useTheme } from "@mui/material";
import { useToast } from "@qubit/autoparts";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  selectSitePortIds,
  selectWorkstationAutostoreGridId
} from "~/redux/selectors/workstationsSelectors";
import { useSkipInductionBinMutation } from "~/redux/warehouse/autostoreGrid.hooks";

export function GetNewBinButton({ onSuccess }: { onSuccess: () => void }) {
  const { t } = useTranslation();
  const { errorToast } = useToast();
  const { palette } = useTheme();

  const autostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const allPortIds = useAppSelector(selectSitePortIds);

  const [skipInductionBin] = useSkipInductionBinMutation();

  const handleGetNewBin = async () => {
    if (!autostoreGridId || !allPortIds?.[0]) {
      return errorToast(t("missing required data"));
    }
    try {
      await skipInductionBin({
        autostoreGridId,
        portId: allPortIds[0]
      }).unwrap();
      onSuccess();
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    }
  };

  return (
    <Button
      variant="outlined"
      sx={{
        background: palette.primary.light,
        borderWidth: "4px",
        borderColor: palette.darkBlue.main
      }}
      size="large"
      startIcon={<Bin24Px fill={palette.primary.main} />}
      onClick={handleGetNewBin}
    >
      <Typography>{t("get new bin")}</Typography>
    </Button>
  );
}
