import { Components, Theme } from "@mui/material";

export const muiAppBar: Components<Omit<Theme, "components">> = {
  MuiAppBar: {
    defaultProps: {
      elevation: 0
    },
    styleOverrides: {
      root: ({ theme }) => ({
        boxShadow: "none",
        backgroundColor: "#f0f0f0",
        color: "#00000099",
        border: "none",
        borderBottom: `1px solid ${theme.palette.border.main}`,
        height: "48px"
      })
    }
  }
};
