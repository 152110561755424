import { Typography } from "@mui/material";
import { Box } from "@mui/system";

export function AlertInfo({ message }: { message: string }) {
  return (
    <Box
      sx={{
        backgroundColor: "error.light",
        p: 1,
        mx: 4,
        my: 1,
        borderRadius: 1
      }}
    >
      <Typography color="error.main" variant="body1" textAlign="center">
        {message}
      </Typography>
    </Box>
  );
}
