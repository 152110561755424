import { Components, Theme } from "@mui/material";

export const muiTableHead: Components<Omit<Theme, "components">> = {
  MuiTableHead: {
    styleOverrides: {
      root: {
        height: "36px"
      }
    }
  }
};
