import {
  ArrowDoubleRight20Px,
  CheckmarkCircle20Px,
  CloseCircle20Px
} from "@locaisolutions/icons";
import { Box, Button, Stack } from "@mui/material";

import { useToast } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { AutostoreBin } from "~/features/autostoreBin";
import { usePortStatusQuery } from "~/hooks/usePortStatus";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectWorkstationAutostoreGridId } from "~/redux/selectors/workstationsSelectors";
import { useRequestNextBinMutation } from "~/redux/warehouse/autostoreGrid.hooks";
import { useGetInventoryByAutostoreBinNumberQuery } from "~/redux/warehouse/inventory.hooks";
import { InventoryMovementResponse } from "~/types/api";

import { AdjustButton } from "./AdjustButton";
import { BinNotEmptyButton } from "./BinNotEmptyButton";
import { FlagBinButton } from "./FlagBinButton";
import { UnflagBinButton } from "./UnflagBinButton";
import { setTaskState } from "./binMaintenanceWorkstation.slice";

export const BinMaintenancePort = ({
  portId,
  movementData
}: {
  portId: number;
  movementData: InventoryMovementResponse | undefined;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { errorToast } = useToast();

  const [requestNextBin] = useRequestNextBinMutation();

  const autostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const { movementTaskState } = useAppSelector(
    (state) => state.binMaintenanceWorkstation
  );

  const {
    horizontalCompartmentCount,
    verticalCompartmentCount,
    error,
    activityState,
    binState,
    refetch,
    ...portStatus
  } = usePortStatusQuery(portId);

  const { selectedBin: thisBinNumber } = portStatus;
  const { sourceBin, destinationBin } = movementData || {};
  const movingProduct = movementTaskState === "MovingProduct";
  const confirmingMovement = movementTaskState === "ConfirmingMovement";
  const isSourceBin =
    sourceBin && thisBinNumber && thisBinNumber === sourceBin.autostoreBinId;
  const isDestinationBin =
    destinationBin &&
    thisBinNumber &&
    thisBinNumber === destinationBin.autostoreBinId;

  const { data: sourceBinData } = useGetInventoryByAutostoreBinNumberQuery(
    autostoreGridId && sourceBin
      ? {
          autostoreGridId,
          binNumber: sourceBin.autostoreBinId
        }
      : skipToken
  );

  const compartmentNumberWithQuantities =
    sourceBinData &&
    ((isSourceBin && movingProduct) || (isDestinationBin && confirmingMovement))
      ? sourceBinData.map((inventory) => ({
          autostoreCompartmentNumber: inventory.bin.autostoreCompartmentNumber,
          quantity: inventory.count.value
        }))
      : undefined;
  const moveButtonDisabled = !movingProduct || !portStatus.isReady;
  const confirmButtonDisabled = !confirmingMovement || !portStatus.isReady;

  // request next bin for cleaning port
  useEffect(() => {
    if (!autostoreGridId) return;
    if (!isSourceBin && !isDestinationBin && portStatus.mode === "OPEN")
      requestNextBin({
        autostoreGridId,
        portId
      })
        .unwrap()
        .catch((err) => {
          errorToast(getMessageFromRtkError(err));
        });
  }, [
    isSourceBin,
    isDestinationBin,
    autostoreGridId,
    errorToast,
    requestNextBin,
    portId,
    portStatus.mode
  ]);

  useEffect(() => {
    if (sourceBin?.autostoreBinId || destinationBin?.autostoreBinId) {
      refetch().catch((error) => {
        errorToast(getMessageFromRtkError(error));
      });
    }
  }, [
    sourceBin?.autostoreBinId,
    destinationBin?.autostoreBinId,
    refetch,
    errorToast
  ]);

  return (
    <Stack width={"100%"}>
      {!error && (
        <Box sx={{ position: "relative", padding: 2 }}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              padding: 1,
              zIndex: 2,
              borderRadius: "0.5em",
              border: `0.625em solid`,
              borderColor:
                (isSourceBin && movingProduct) ||
                (isDestinationBin && confirmingMovement)
                  ? "primary.main"
                  : "transparent",
              animation:
                isDestinationBin && confirmingMovement
                  ? "slide-in 750ms"
                  : "none",
              "@keyframes slide-in": {
                from: {
                  transform: "translateX(-100%)"
                },
                to: {
                  transform: "translateX(0%)"
                }
              }
            }}
          />
          <AutostoreBin
            state={activityState}
            binId={thisBinNumber}
            pickQuantity={""}
            pickCompartment={null}
            numberOfRows={horizontalCompartmentCount ?? 1}
            numberOfColumns={verticalCompartmentCount ?? 1}
            highlightActiveCompartment
            compartmentNumberWithQuantities={
              (isSourceBin && movingProduct) ||
              (isDestinationBin && confirmingMovement)
                ? compartmentNumberWithQuantities
                : undefined
            }
            activeTaskGroupCompartments={
              compartmentNumberWithQuantities?.map((c, i) =>
                c.autostoreCompartmentNumber
                  ? c.autostoreCompartmentNumber - 1
                  : i
              ) ?? []
            }
          />
        </Box>
      )}
      {!!isSourceBin && (
        <Stack direction="column" alignItems="center" spacing={4} my={3}>
          <Button
            size="large"
            startIcon={<ArrowDoubleRight20Px fill="white" />}
            disabled={moveButtonDisabled}
            onClick={() => dispatch(setTaskState("ConfirmingMovement"))}
          >
            {t("move inventory")}
          </Button>
          {movingProduct && binState ? (
            <AdjustButton
              portId={portId}
              state={activityState}
              binId={thisBinNumber}
              numberOfRows={horizontalCompartmentCount ?? 1}
              numberOfColumns={verticalCompartmentCount ?? 1}
              binInventory={sourceBinData}
              compartmentNumber={sourceBin.compartmentNumber}
            />
          ) : (
            <FlagBinButton />
          )}
          <Button
            variant="subtle"
            size="large"
            startIcon={<CloseCircle20Px />}
            onClick={() => dispatch(setTaskState("MovingProduct"))}
          >
            Cancel Movement
          </Button>
        </Stack>
      )}
      {!!isDestinationBin && (
        <Stack direction="column" alignItems="center" spacing={4} my={3}>
          <Button
            size="large"
            startIcon={<CheckmarkCircle20Px fill="white" />}
            disabled={confirmButtonDisabled}
            // onClick={handleConfirmMove}
          >
            Confirm Move
          </Button>
          {confirmingMovement && binState ? (
            // TODO: this should have source bin data because we're still technically adjusting the source bin here
            <AdjustButton
              portId={portId}
              state={activityState}
              binId={thisBinNumber}
              numberOfRows={horizontalCompartmentCount ?? 1}
              numberOfColumns={verticalCompartmentCount ?? 1}
              binInventory={sourceBinData}
              compartmentNumber={destinationBin.compartmentNumber}
            />
          ) : (
            <BinNotEmptyButton
              state={activityState}
              binId={thisBinNumber}
              numberOfRows={horizontalCompartmentCount ?? 1}
              numberOfColumns={verticalCompartmentCount ?? 1}
              portId={portId}
              binState={binState}
            />
          )}
          <FlagBinButton />
        </Stack>
      )}
      {!!thisBinNumber && !isDestinationBin && !isSourceBin && (
        <Stack direction="column" alignItems="center" spacing={4} my={3}>
          <UnflagBinButton binState={binState} />
          <BinNotEmptyButton
            state={activityState}
            binId={thisBinNumber}
            numberOfRows={horizontalCompartmentCount ?? 1}
            numberOfColumns={verticalCompartmentCount ?? 1}
            portId={portId}
            binState={binState}
          />
          <FlagBinButton />
        </Stack>
      )}
    </Stack>
  );
};
