import { Components, Theme } from "@mui/material";

export const muiDialogTitle: Components<Omit<Theme, "components">> = {
  MuiDialogTitle: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.primary.light,
        display: "flex",
        justifyContent: "center",
        color: theme.palette.primary.dark,
        fontSize: "24px",
        borderBottom: `1px solid ${theme.palette.darkGray.light}`
      })
    }
  }
};
