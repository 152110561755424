import { GetApiTokensResponseDTO } from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const apiKeysApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    getApiKeys: build.query<GetApiTokensResponseDTO[], void>({
      query: () => ({
        url: `/configuration/api-tokens`,
        method: "GET"
      }),
      providesTags: ["api keys"]
    })
  })
});
