import { Components, Theme } from "@mui/material";

import { mainColours } from "./colours";

export const muiSvgIcon: Components<Omit<Theme, "components">> = {
  MuiSvgIcon: {
    styleOverrides: {
      colorSecondary: {
        color: mainColours.icon.secondary
      },
      colorDisabled: {
        color: mainColours.icon.disabled
      },
      colorAction: {
        color: mainColours.icon.accent
      },
      colorError: {
        color: mainColours.icon.error
      }
    }
  }
};
