import { Typography } from "@mui/material";

import { ProductInfoTypography } from "@qubit/autoparts/src/productCard/styles";

type Props = {
  count?: { value?: number; units: string };
};

/**
 * Renders the label "Count", followed by the count and units of a product.
 */
export function ProductCount({ count }: Props) {
  const countAndUnits = `${count?.value || ""} ${count?.units || ""}`;
  return (
    <div>
      <ProductInfoTypography variant="overline">Count</ProductInfoTypography>
      <Typography
        gutterBottom
        variant="h6"
        component="h6"
        sx={{ fontWeight: 400 }}
      >
        {countAndUnits}
      </Typography>
    </div>
  );
}
