import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography
} from "@mui/material";

import { skipToken } from "@reduxjs/toolkit/query";

import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { setSelectedAutostoreGridId } from "~/features/status/status.slice";

import { selectCells } from "~/redux/selectors/logPublisherSelectors";
import { selectStatusSelectedAutostoreGridId } from "~/redux/selectors/statusSelectors";
import {
  useGetAutostoreGridsQuery,
  useGetLogPublisherStateQuery
} from "~/redux/warehouse/autostoreGrid.hooks";

import { BinCell } from "./BinCell";
import { CellHighlight } from "./CellHighlight";
import { PortCell } from "./PortCell";

export const GridView = () => {
  const dispatch = useAppDispatch();

  const { data: autostoreGrids } = useGetAutostoreGridsQuery();
  const selectedAutostoreGridId = useAppSelector(
    selectStatusSelectedAutostoreGridId
  );

  const { cells, columns, isFetching } = useGetLogPublisherStateQuery(
    selectedAutostoreGridId || skipToken,
    {
      selectFromResult: ({ data, isFetching }) => ({
        ...selectCells(data),
        isFetching
      })
    }
  );

  const handleAutostoreGridChanged = useCallback(
    (event: SelectChangeEvent) => {
      dispatch(setSelectedAutostoreGridId(event.target.value));
    },
    [dispatch]
  );

  const template = `repeat(${columns}, 25px)`;

  return (
    <>
      <Stack alignItems={"flex-end"} py={2}>
        <Select
          size="small"
          value={selectedAutostoreGridId || ""}
          variant="outlined"
          onChange={handleAutostoreGridChanged}
        >
          {autostoreGrids &&
            autostoreGrids.map((autostoreGrid) => (
              <MenuItem
                key={autostoreGrid.autostoreGridId}
                value={autostoreGrid.autostoreGridId}
              >
                {autostoreGrid.autostoreGridName}
              </MenuItem>
            ))}
        </Select>
      </Stack>
      {isFetching && <CircularProgress />}
      {!isFetching && !cells && <Typography>No Data Found</Typography>}
      {!isFetching && cells && (
        <Stack
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-evenly"
        >
          <Box
            display="grid"
            gridTemplateColumns={template}
            sx={{ mr: 2, mb: 2 }}
          >
            {cells?.map((c) => {
              return c.isPort ? (
                <PortCell key={`${c.x}/${c.y}`} position={c} />
              ) : (
                <BinCell key={`${c.x}/${c.y}`} position={c} />
              );
            })}
          </Box>
          <CellHighlight />
        </Stack>
      )}
    </>
  );
};
