import { Paper, Typography, Box } from "@mui/material";
import { useRef } from "react";
import Draggable from "react-draggable";

import { useAppSelector } from "~/app/store";
import { useLogPublisherCell } from "~/hooks/useLogPublisherCell";
import {
  selectStatusHoverPosition,
  selectStatusSelectedAutostoreGridId
} from "~/redux/selectors/statusSelectors";

export const CellHighlight = () => {
  const draggableRef = useRef(null);
  const hoverPosition = useAppSelector(selectStatusHoverPosition);
  const selectedAutostoreGridId = useAppSelector(
    selectStatusSelectedAutostoreGridId
  );
  const { bins, ports } = useLogPublisherCell(
    selectedAutostoreGridId,
    hoverPosition
  );

  const sortedBins = bins
    .slice()
    .sort((a, b) => (a.depth || 0) - (b.depth || 0));

  const binsOpenAtPort = bins.filter((b) => b.binMode === "Open");

  if (!hoverPosition) return <></>;

  return (
    <Draggable nodeRef={draggableRef}>
      <Paper
        ref={draggableRef}
        role="status"
        sx={{ p: 3, borderRadius: 3, width: "250px" }}
      >
        <Typography role="heading">
          Cell -&gt; {hoverPosition.x}/{hoverPosition.y}
        </Typography>
        {!!ports.length && (
          <>
            <Typography>Port: {ports[0].portId}</Typography>
            <Typography>Port Mode: {ports[0].portMode}</Typography>
            <Typography>Selected Bin: {ports[0].selectedBin}</Typography>
            {!!binsOpenAtPort.length && (
              <Typography>Bin Mode: {binsOpenAtPort[0].binMode}</Typography>
            )}
            <Typography>Selected Task: {ports[0].selectedTask}</Typography>
          </>
        )}
        {!ports.length && !!sortedBins.length && (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "0.5fr repeat(2, 1fr)"
            }}
          >
            <Typography></Typography>
            <Typography>Bin Id</Typography>
            <Typography>Depth (mm)</Typography>
            {sortedBins.map((b, i) => (
              <Box role="row" key={b.binId} sx={{ display: "contents" }}>
                <Typography>{i}</Typography>
                <Typography>{b.binId}</Typography>
                <Typography>{b.depth}</Typography>
              </Box>
            ))}
          </Box>
        )}
      </Paper>
    </Draggable>
  );
};
