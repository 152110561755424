import { useEffect, useRef } from "react";

import { vibrate, makeSound } from "./device";

type OnMatchProduct<TMatch> =
  | ((productMatch: TMatch | null) => void)
  | ((productMatch: TMatch | null) => Promise<void>);

export type KeydownHandler = (event: KeyboardEvent) => void;

const keyDownHandlers: KeydownHandler[] = [];

function callLastKeydownHandler(event: KeyboardEvent): void {
  keyDownHandlers[keyDownHandlers.length - 1](event);
}

function registerEventHandler(handler: KeydownHandler): void {
  keyDownHandlers.push(handler);
}

function unregisterEventHandler() {
  keyDownHandlers.pop();
}

/**
 * Initialized once per page on the top most component.
 */
export function useKeyDownHandler(disabled = false): void {
  useEffect(() => {
    if (!disabled) {
      document.addEventListener("keydown", callLastKeydownHandler);
    }
    return (): void => {
      if (!disabled) {
        document.removeEventListener("keydown", callLastKeydownHandler);
      }
    };
  }, [disabled]);
}

/** Time in ms to ignore new scans after a successful scan. Useful to avoid
 * inaverdent double scans. */
const debounceLimit = 500;

/**
 * Reads input from a barcode scanner input device.
 * Performs vibrations and sounds on a device depending on the match result.
 * Barcode scanners generally work by sending a series of keypresses to the browser.
 * @param findScanMatch Callback to look up a product by the scan result
 * @param processScanMatch Callback when an item is matched
 * @param deps Object dependencies for useEffect keydown handlers
 * @param disabled optionally disable. Useful when multiple components are capturing scans and component loading order is not gauranteed (modals, etc)
 */
export function useBarcodeScanner<TMatch>({
  findScanMatch,
  processScanMatch,
  deps = [],
  disabled = false
}: {
  findScanMatch: (buffer: string) => TMatch | Promise<TMatch>;
  processScanMatch: OnMatchProduct<TMatch>;
  deps?: React.DependencyList;
  disabled?: boolean;
}): void {
  // Using useRef because useState is async and doesn't update in time for the next key event
  const lastTwoKeyPressesRef = useRef<KeyboardEvent[]>([]);
  const bufferRef = useRef("");
  const isScanningRef = useRef(false);
  const lastScanTime = useRef(0);

  const dependencies = [...deps, disabled];

  const startScanning = (): void => {
    const currentTime = Date.now();
    if (currentTime - lastScanTime.current < debounceLimit) {
      return;
    }

    // if scan is detected, unselect any input so that the scan is not captured by the text field
    (document.activeElement as HTMLElement).blur();

    isScanningRef.current = true;
    bufferRef.current = "";
    lastTwoKeyPressesRef.current = [];
  };

  const endScanning = (): void => {
    if (isScanningRef.current) {
      isScanningRef.current = false;
      bufferRef.current = "";
    }
  };

  /** Runs on each key press:
   *   1. Looks for specific key presses that signal the start of a scan
   *   2. Stores each key in a buffer
   *   3. Checks if scanning is complete
   */
  const handleKeyboardEvent = async (e: KeyboardEvent) => {
    // Check if scanning is complete
    if (e.key?.toLowerCase() === "enter" && isScanningRef.current) {
      const itemMatch =
        bufferRef.current.length && (await findScanMatch(bufferRef.current));
      endScanning();
      if (itemMatch) {
        lastScanTime.current = Date.now();
        vibrate(100);
        makeSound("sine", 261.6, 0.8);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        processScanMatch(typeof itemMatch === "boolean" ? null : itemMatch);
      } else {
        vibrate([300, 300]);
        makeSound("triangle", 87.31, 0.8);
      }
      // If scanning is in progress add the key to the buffer
    } else if (isScanningRef.current && e.key !== "Shift") {
      bufferRef.current = bufferRef.current + e.key;
    }

    // Check for Ctrl-B (the prefix that the ScanAvenger scanner transmits)
    if (e.ctrlKey && e.code?.toLowerCase() === "keyb") {
      startScanning();
    }

    // Track last two keypresses to see if we're starting to scan (zebra prefixes with 1-2 "unidentified" keypresses)
    if (!isScanningRef.current && e.key?.toLowerCase() !== "unidentified") {
      lastTwoKeyPressesRef.current.push(e);
      if (lastTwoKeyPressesRef.current.length > 2) {
        lastTwoKeyPressesRef.current.shift();
      }
    }

    // Check for Alt+Numpad0 and Alt+Numpad2 (the prefix that the Symcode Scanner transmits)
    if (
      !isScanningRef.current &&
      lastTwoKeyPressesRef.current.length === 2 &&
      lastTwoKeyPressesRef.current[0].code?.toLowerCase() === "numpad0" &&
      lastTwoKeyPressesRef.current[0].altKey &&
      lastTwoKeyPressesRef.current[1].code?.toLowerCase() === "numpad2" &&
      lastTwoKeyPressesRef.current[1].altKey
    ) {
      startScanning();
    }

    // Check for "*" (the prefix that the zebra transmits - must be manually set in datawedge app)
    if (
      !isScanningRef.current &&
      lastTwoKeyPressesRef.current.length === 2 &&
      lastTwoKeyPressesRef.current[0].key?.toLowerCase() === "shift" &&
      lastTwoKeyPressesRef.current[1].key === "*"
    ) {
      startScanning();
    }
  };

  useEffect(() => {
    if (!disabled) {
      registerEventHandler((event: KeyboardEvent) => {
        void (async () => {
          await handleKeyboardEvent(event);
        })();
      });
    }
    return (): void => {
      if (!disabled) unregisterEventHandler();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependencies]);
}
