import CheckIcon from "@mui/icons-material/Check";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { Stack } from "@mui/system";

import { useToast } from "@qubit/autoparts";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { BinFlag } from "~/api/warehouseTypes/bin";
import { useAppSelector } from "~/app/store";

import { mixpanelTrack, useMixpanelPageName } from "~/lib/mixpanel-tracking";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";

import {
  selectThisWorkstation,
  selectWorkstationAutostoreGridId,
  selectWorkstationAvailablePorts
} from "~/redux/selectors/workstationsSelectors";

import {
  useCloseBinMutation,
  useNextEmptyBinMutation,
  usePostFlagBinMutation
} from "~/redux/warehouse/autostoreGrid.hooks";
import { usePostFlagBinInventoryMovementsMutation } from "~/redux/warehouse/inventory.hooks";

import { AutostoreBinState } from "~/types/api";

import { MaintenanceFlagModalPort } from "./MaintenanceFlagModalPort";

type MaintenanceFlagModal = {
  isOpen: boolean;
  onClose: () => void;
};

export function MaintenanceFlagModal(props: MaintenanceFlagModal) {
  const { isOpen, onClose } = props;

  // misc. hooks
  const { t } = useTranslation();
  const trackedPageName = useMixpanelPageName();
  const { errorToast, successToast } = useToast();

  // api hooks
  const [postFlagBinInventoryMovements] =
    usePostFlagBinInventoryMovementsMutation();
  const [postFlagBin] = usePostFlagBinMutation();
  const [closeBin] = useCloseBinMutation();
  const [nextEmptyBin] = useNextEmptyBinMutation();

  // local state
  const [selectedFlag, setSelectedFlag] = useState<BinFlag[]>([]);
  const [selectedBinForFlag, setSelectedBinForFlag] =
    useState<AutostoreBinState | null>(null);

  // selectors
  const selectedGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const selectedWorkstation = useAppSelector(selectThisWorkstation);
  const workstationPorts = useAppSelector(selectWorkstationAvailablePorts);

  // consts
  const flaggedReasons = ["dirty", "damaged", "misconfigured"] as const;
  const selectedBinIsDestinationBin = false; // TODO: once we have movement data, we can determine this

  // functions
  const handleClose = () => {
    setSelectedFlag([]);
    setSelectedBinForFlag(null);
    onClose();
    mixpanelTrack({
      trackedPageName,
      type: "Button Click",
      label: "Close Flag Bin Modal"
    });
  };
  const flagDestinationBin = async () => {
    if (!selectedWorkstation || !selectedGridId) return;
    try {
      await Promise.all(
        selectedFlag.map((flag) => {
          return postFlagBinInventoryMovements({
            autostoreGridId: selectedGridId,
            reason: flag,
            workstationId: selectedWorkstation.id
          }).unwrap();
        })
      );

      // TODO: do we need to restart bin maintenance here after flagging the destination bin?
      handleClose();
      successToast("Inventory Movements Flagged");
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    }
  };

  const handleFlagBin = async () => {
    try {
      if (selectedWorkstation && selectedGridId && selectedBinForFlag) {
        const { binId, portId } = selectedBinForFlag;
        await Promise.all(
          selectedFlag.map((flag) => {
            return postFlagBin({
              autostoreGridId: selectedGridId,
              portId: selectedBinForFlag.portId,
              binId: selectedBinForFlag.binId,
              reason: flag
            }).unwrap();
          })
        );

        if (selectedFlag) {
          await closeBin({
            autostoreGridId: selectedGridId,
            portId,
            binNumber: binId
          });
          await nextEmptyBin({ portId, autostoreGridId: selectedGridId });
        }

        successToast(t("flag set successfully"));
        handleClose();
      }
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    }
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="lg" onClose={handleClose}>
      <DialogTitle>{t("flag bin")}</DialogTitle>
      <DialogContent sx={{ margin: "30px 30px 0" }}>
        <Stack direction="row" spacing={2}>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="space-evenly"
          >
            {flaggedReasons.map((option) => {
              const isButtonSelected = selectedFlag.includes(option);
              return (
                <Button
                  key={option}
                  fullWidth
                  startIcon={isButtonSelected ? <CheckIcon /> : null}
                  variant={isButtonSelected ? "contained" : "subtle"}
                  onClick={() =>
                    setSelectedFlag(
                      isButtonSelected
                        ? selectedFlag.filter((flag) => flag !== option)
                        : [...selectedFlag, option]
                    )
                  }
                >
                  {t(option)}
                </Button>
              );
            })}
          </Stack>
          {workstationPorts.map((portId) => {
            return (
              <MaintenanceFlagModalPort
                key={portId}
                portId={portId}
                isSelected={selectedBinForFlag?.portId === portId}
                setSelectedBin={setSelectedBinForFlag}
              />
            );
          })}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", padding: "30px" }}>
        <Button
          size="large"
          variant="contained"
          sx={{
            fontWeight: "normal"
          }}
          onClick={async () => {
            if (selectedWorkstation) {
              if (selectedBinIsDestinationBin) {
                await flagDestinationBin();
              } else if (selectedBinForFlag) {
                await handleFlagBin();
              }
            }
            mixpanelTrack({
              trackedPageName,
              type: "Button Click",
              label: "Flag Bin",
              eventProperties: {
                selectedBinForFlag,
                selectedFlag
              }
            });
            onClose();
          }}
          disabled={!selectedFlag.length || !selectedBinForFlag}
        >
          {t("set flag")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
