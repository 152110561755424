import { Components, Theme } from "@mui/material";

export const muiOutlinedInput: Components<Omit<Theme, "components">> = {
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        ...(ownerState.error && {
          backgroundColor: theme.palette.error.light
        })
      })
    }
  }
};
