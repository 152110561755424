import {
  Alert,
  Box,
  Button,
  InputLabel,
  Stack,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import { useToast } from "@qubit/autoparts";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import ReactJson from "react-json-view";

import { useAppSelector } from "~/app/store";
import { useSummarizedVariantInventorySummaries } from "~/hooks/useSummarizedVariantInventorySummaries";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { useCreatePepsiOrderMutation } from "~/redux/pepsi/pickOrder.hooks";
import { usePostOrdersCreateMutation } from "~/redux/public/orders.openApi";
import { selectUsersClientId } from "~/redux/selectors/authSelectors";
import { selectOrderCreationSelectedAutostoreGridId } from "~/redux/selectors/orderCreationSelectors";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import {
  usePostToolsOrdersGenerateMutation,
  GenerateOrders
} from "~/redux/warehouse/tools.openApi";

import { RangeFields } from "./Range";
import { translateToPickOrder } from "./translateToPepsiApi";

export const GenerateOrdersPage = () => {
  const { errorToast, successToast } = useToast();
  const autostoreGridId = useAppSelector(
    selectOrderCreationSelectedAutostoreGridId
  );
  const clientId = useAppSelector(selectUsersClientId);
  const fulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const [generateOrders, { data: orderData, isLoading }] =
    usePostToolsOrdersGenerateMutation();

  const { dev_useV2OrderCreation } = useAppSelector(selectClientConfig);
  const [createPickOrder, { isLoading: createPickOrderInProgress }] =
    useCreatePepsiOrderMutation();
  const [createOrder, { isLoading: createOrderInProgress }] =
    usePostOrdersCreateMutation();
  const [showOrderData, setShowOrderData] = useState(false);
  const [dryRun, setDryRun] = useState(false);

  const { totalAvailableInventory } =
    useSummarizedVariantInventorySummaries(autostoreGridId);

  const generateOrdersContext = useForm<GenerateOrders>({
    defaultValues: {
      numberOfOrders: 10,
      totesPerOrder: { min: 8, max: 10 },
      lineItemsPerTote: { min: 3, max: 5 },
      quantityPerLineItem: { min: 3, max: 5 }
    }
  });

  const handleGenerateOrders = async (orders: GenerateOrders) => {
    if (!clientId || !fulfillmentCenter || !autostoreGridId || !orders) return;

    try {
      const data = await generateOrders({
        generateOrders: {
          ...orders,
          gridIds: [autostoreGridId]
        }
      }).unwrap();

      if (dryRun) return;
      if (dev_useV2OrderCreation) {
        await Promise.all(
          data.map((o) =>
            createPickOrder({
              clientId,
              fulfillmentCenterId: fulfillmentCenter.fulfillmentCenterId,
              ...translateToPickOrder(o, fulfillmentCenter)
            })
          )
        );
      } else {
        await Promise.all(data.map((o) => createOrder({ createOrder: o })));
      }
      successToast(`Created ${data.length} order(s)`);
    } catch (err) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  const isGeneratedTotes =
    fulfillmentCenter?.toteConfiguration === "GeneratedTotes";
  const data = generateOrdersContext.watch();

  const quantityConsumedMin =
    data.numberOfOrders *
    data.totesPerOrder.min *
    (isGeneratedTotes ? 1 : data.lineItemsPerTote.min) *
    data.quantityPerLineItem.min;
  const quantityConsumedMax =
    data.numberOfOrders *
    data.totesPerOrder.max *
    (isGeneratedTotes ? 1 : data.lineItemsPerTote.max) *
    data.quantityPerLineItem.max;

  const numberOfOrdersErrorMessage =
    generateOrdersContext.formState.errors.numberOfOrders?.message;

  return (
    <>
      <FormProvider {...generateOrdersContext}>
        <form
          onSubmit={generateOrdersContext.handleSubmit(handleGenerateOrders)}
        >
          <Stack gap={2}>
            <Typography variant="h5">Generate Orders</Typography>
            <TextField
              {...generateOrdersContext.register(`numberOfOrders`, {
                required: {
                  value: true,
                  message: "Number of Orders is required"
                },
                min: {
                  value: 1,
                  message: "Number of Orders must be greater than zero"
                }
              })}
              sx={{ width: "150px" }}
              variant="standard"
              label="Number of Orders"
              type="number"
              error={!!numberOfOrdersErrorMessage}
            />
            {!!numberOfOrdersErrorMessage && (
              <Alert severity="error">
                <Typography>{numberOfOrdersErrorMessage}</Typography>
              </Alert>
            )}
            <Stack gap={2} flexDirection="row" flexWrap="wrap">
              <RangeFields<GenerateOrders>
                propertyName="totesPerOrder"
                label="Totes per Order"
              />
              {!isGeneratedTotes && (
                <RangeFields<GenerateOrders>
                  propertyName="lineItemsPerTote"
                  label="Line Items per Tote"
                />
              )}
              <RangeFields<GenerateOrders>
                propertyName="quantityPerLineItem"
                label="Quantity per LineItem"
              />
            </Stack>
            <Typography variant="h5">
              Quantity Consumed: between {quantityConsumedMin} and{" "}
              {quantityConsumedMax}
            </Typography>
            <Stack gap={2} flexDirection="row" alignItems="center">
              <Box>
                <InputLabel htmlFor="dry-run">Dry Run</InputLabel>
                <Switch
                  checked={dryRun}
                  onChange={(e) => setDryRun(e.target.checked || false)}
                />
              </Box>
              <Button
                variant="contained"
                type="submit"
                disabled={
                  isLoading ||
                  createPickOrderInProgress ||
                  createOrderInProgress ||
                  !autostoreGridId ||
                  quantityConsumedMax > totalAvailableInventory
                }
              >
                <Typography>Generate</Typography>
              </Button>
              {!autostoreGridId && (
                <Alert severity="warning">
                  <Typography>No Grid Selected</Typography>
                </Alert>
              )}
              {quantityConsumedMax > totalAvailableInventory && (
                <Alert severity="error">
                  <Typography>Not enough available inventory</Typography>
                </Alert>
              )}
            </Stack>
            {orderData && (
              <Box>
                <InputLabel htmlFor="show-data">Show OrderData</InputLabel>
                <Switch
                  checked={showOrderData}
                  onChange={(e) => setShowOrderData(e.target.checked || false)}
                />
              </Box>
            )}
            {showOrderData && orderData && <ReactJson src={orderData} />}
          </Stack>
        </form>
      </FormProvider>
    </>
  );
};
