import { Flag } from "@mui/icons-material";
import { Button } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "~/app/store";
import { mixpanelTrack, useMixpanelPageName } from "~/lib/mixpanel-tracking";
import { selectWorkstationAutostoreGridId } from "~/redux/selectors/workstationsSelectors";
import { useGetNumberOfFlaggedBinsQuery } from "~/redux/warehouse/autostoreGrid.hooks";

const ManageFlaggedInventoryButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const trackedPageName = useMixpanelPageName();
  const autostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);

  const {
    data: numberOfFlaggedBins,
    isSuccess: isGetNumberOfFlaggedBinsSuccess
  } = useGetNumberOfFlaggedBinsQuery(
    autostoreGridId ? { autostoreGridId } : skipToken,
    { refetchOnMountOrArgChange: true }
  );

  const handleClick = () => {
    navigate("/inventoryv2/manage-flagged?autostore=true");
    mixpanelTrack({
      trackedPageName,
      type: "Button Click",
      label: "Manage Flagged Inventory"
    });
  };

  return (
    <Button
      onClick={handleClick}
      startIcon={<Flag />}
      variant="contained"
      color="primary"
      sx={{ mb: 1, fontWeight: "normal" }}
      disabled={isGetNumberOfFlaggedBinsSuccess && !numberOfFlaggedBins}
    >
      {t("manage flagged bins")}
      {isGetNumberOfFlaggedBinsSuccess && numberOfFlaggedBins
        ? ` (${numberOfFlaggedBins})`
        : null}
    </Button>
  );
};

export default ManageFlaggedInventoryButton;
