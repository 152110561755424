import { Paper, Typography, Divider, Stack } from "@mui/material";
import { AlertBanner } from "@qubit/autoparts";
import { useTranslation } from "react-i18next";

import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";

import { BatchSettingsHeader } from "./BatchSettingsHeader";
import { RecurringSchedules } from "./RecurringSchedules";

type Props = { viewTitle?: ViewNameTranslation };

export function BatchSettings({ viewTitle }: Props) {
  const { t } = useTranslation();

  useNavbar({ viewTitle });

  return (
    <Stack spacing={4} pt={2} mx={2}>
      <AlertBanner severity="warning">
        {t("batch settings warning")}
      </AlertBanner>
      <Paper>
        <Stack padding={2} spacing={2}>
          <Typography variant="h6">
            {t("batch")} {t("nav.viewname.settings")}
          </Typography>
          <Divider />

          <BatchSettingsHeader />
        </Stack>
      </Paper>
      <RecurringSchedules />
    </Stack>
  );
}
