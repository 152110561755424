import {
  Box,
  Card,
  Container,
  Typography,
  Skeleton,
  CardActionArea
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { Stack } from "@mui/system";
import { CardMediaWithFallback, useToast } from "@qubit/autoparts";
import { ProductInfoTypography } from "@qubit/autoparts/src/productCard/styles";
import { ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ScanningVerification } from "~/api/scanningVerifyMethod";
import { useAppDispatch, useAppSelector } from "~/app/store";

import useFlag from "~/config/flags";
import { setPickedQuantityModalStatus } from "~/features/autostorePicking/confirmPickQuantityModal/confirmPickQuantityModal.slice";

import { displayPickData, matchExactUpcsOrSkus } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { setIsPickQuantityConfirmed } from "~/redux/actions";
import {
  selectBinIsPresent,
  selectIsPickQuantityConfirmed,
  selectPickingState
} from "~/redux/selectors/autostoreSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import {
  selectSelectedPortId,
  selectThisWorkstation
} from "~/redux/selectors/workstationsSelectors";
import { useVerifyPickMutation } from "~/redux/warehouse/pick.hooks";
import { AutostorePickingState } from "~/types/api";

import { BarcodeInput } from "./BarcodeInput";

const ProductName = styled(Typography)(() => ({
  fontSize: "1.4em"
}));
function UniversalProductCard(props: {
  productName: string;
  imageFileName: string;
  /** Renders quantity "on hand" below image */
  quantity?: number;
  /** Renders quantity inline with SKU and UPC */
  quantityInline?: string;
  style?: Record<string, unknown>;
  cardHeight?: string;
  disableGutters?: boolean;
  /** Optionally render a section under the image. Used for Putaway quantity and date fields */
  bottomSection?: ReactNode;
  displayEnterProductUpcButton?: boolean;
  children?: ReactNode;
}) {
  const { t } = useTranslation();

  const {
    productName,
    imageFileName,
    quantity,
    quantityInline,
    cardHeight,
    disableGutters,
    bottomSection,
    displayEnterProductUpcButton,
    children
  } = props;

  // local state
  const dispatch = useAppDispatch();
  const fulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const pickingState = useAppSelector(selectPickingState);
  const selectedWorkstation = useAppSelector(selectThisWorkstation);
  const sitePortId = useAppSelector(selectSelectedPortId);
  const isPickQuantityConfirmed = useAppSelector(selectIsPickQuantityConfirmed);
  const binIsPresent = useAppSelector(selectBinIsPresent);
  const simulateUpcScanFeatureFlag = useFlag().simulateUpcScan;
  const { errorToast } = useToast();
  const [verifyPick] = useVerifyPickMutation();

  const isSimulateUpcScanFeatureFlagEnabled =
    !!fulfillmentCenter &&
    fulfillmentCenter.pickQuantityConfirmationEnabled &&
    simulateUpcScanFeatureFlag;

  const isBarcodeScanEnabled =
    fulfillmentCenter?.pickQuantityConfirmationEnabled &&
    displayEnterProductUpcButton;

  const isBinReady =
    isSimulateUpcScanFeatureFlagEnabled &&
    binIsPresent &&
    !isPickQuantityConfirmed;

  const matchEnteredUpcOrSku = useCallback(
    (
      pickingState: AutostorePickingState | null,
      upcOrSku?: string,
      callback?: () => Promise<void> | void
    ) => {
      if (!pickingState) return;
      const pickId =
        pickingState.selectedPickId || pickingState.currentPicks[0]?.pickId;
      const currentPick = pickingState.currentPicks.find(
        (currentPick) => currentPick.pickId === pickId
      );
      if (!currentPick) return;
      const finalUpcOrSku = upcOrSku || currentPick.upc || currentPick.sku;
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      verifyPick({
        upcOrSku: finalUpcOrSku,
        pickId,
        verifyMethod: ScanningVerification.INPUT,
        workstationId: selectedWorkstation?.id
      })
        .unwrap()
        .catch((err) => errorToast(getMessageFromRtkError(err)));
      const allUpcsOrSkus = [...currentPick.allUpcs, currentPick.sku];
      const isScannedUpcCorrect = matchExactUpcsOrSkus(
        allUpcsOrSkus,
        finalUpcOrSku
      );
      if (!isScannedUpcCorrect) return;

      // When the pick quantity is 1, we don't want to show
      // The pick quantity confirmation modal
      if (currentPick.quantity?.value === 1) {
        // next pr
        dispatch(setIsPickQuantityConfirmed(true));
        dispatch(
          setPickedQuantityModalStatus({
            isShown: false,
            pickedQuantity: null,
            selectedBinId: null
          })
        );

        // The user should be able to complete the pick from PTL also
        if (sitePortId)
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          displayPickData({
            portId: sitePortId,
            pickId,
            batchId: currentPick.batchId || pickingState.batchId,
            gridId: selectedWorkstation?.autostoreGridId
          });
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        if (callback) callback();
        return;
      }
      // When the pick quantity is > 1, display the pick quantity confirmation modal
      dispatch(
        setPickedQuantityModalStatus({
          isShown: true,
          pickedQuantity: currentPick.quantity?.value,
          selectedBinId:
            currentPick.pickBin?.autostoreBin?.autostoreBinId || null
        })
      );

      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      if (callback) callback();
    },
    [
      dispatch,
      errorToast,
      verifyPick,
      selectedWorkstation?.autostoreGridId,
      selectedWorkstation?.id,
      sitePortId
    ]
  );

  const handleProductImageClick = () => {
    if (isBinReady) {
      matchEnteredUpcOrSku(pickingState);
    }
  };

  return (
    <Container disableGutters={disableGutters}>
      <Card
        style={{
          height: cardHeight || "inherit",
          position: "relative",
          padding: "16px"
        }}
      >
        <Stack
          id="productInfo"
          sx={{ paddingBottom: "16px" }}
          gap={1}
          height="100%"
        >
          <Typography role="heading" variant="h5">
            {productName}
          </Typography>
          {isBarcodeScanEnabled && (
            <BarcodeInput matchEnteredUpcOrSku={matchEnteredUpcOrSku} />
          )}
          {quantityInline && (
            <Box>
              <ProductInfoTypography variant="overline">
                Quantity
              </ProductInfoTypography>
              <Typography
                gutterBottom
                variant="h6"
                component="h6"
                style={{ fontWeight: 400 }}
              >
                {quantityInline}
              </Typography>
            </Box>
          )}
          {children}
          <CardActionArea
            aria-label="product"
            disabled={!isBinReady}
            onClick={handleProductImageClick}
            sx={{ overflow: "hidden" }}
          >
            <CardMediaWithFallback
              image={imageFileName}
              title="product"
              sx={{ height: "100%", maxHeight: "200px" }}
            />
          </CardActionArea>
          {quantity && (
            <Stack id="quantity" alignItems="flex-end">
              <ProductName
                style={
                  cardHeight ? { position: "absolute", bottom: "0.625em" } : {}
                }
              >
                <span
                  style={{ textDecoration: "underline" }}
                >{`${quantity}`}</span>
                {` ${t("on hand")}`}
              </ProductName>
            </Stack>
          )}
          {bottomSection}
        </Stack>
      </Card>
    </Container>
  );
}

export default UniversalProductCard;

export function PickInfoIsLoading(props: {
  height?: string | number;
  innerHeight?: string | number;
  marginTop?: string;
  disableGutters?: boolean;
}) {
  const { height, marginTop, innerHeight, disableGutters } = props;
  return (
    <Container disableGutters={disableGutters}>
      <Card
        style={{
          marginTop,
          paddingTop: 10,
          overflow: "visible",
          height
        }}
      >
        <Stack gap={1} padding={1}>
          <Skeleton height={40} variant="rectangular" animation={false} />
          <Skeleton height={40} variant="rectangular" animation={false} />
          <Skeleton height={40} variant="rectangular" animation={false} />
        </Stack>
        <Box height={height} padding={1}>
          <Skeleton
            variant="rectangular"
            animation={false}
            height={innerHeight || 265}
          />
        </Box>
      </Card>
    </Container>
  );
}
