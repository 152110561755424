import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField
} from "@mui/material";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import {
  ModalDialogTransition,
  useToast,
  getDayjsFromScheduleWindow
} from "@qubit/autoparts";
import dayjs, { Dayjs } from "dayjs";
import { t } from "i18next";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { OrderTypesDropdown } from "~/components/orderTypes/OrderTypesDropdown";

import { dayOfWeekAsString } from "~/lib/daysOfTheWeek";
import { numbersRegex } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";

import {
  useDeleteRecurringScheduleMutation,
  usePostRecurringScheduleMutation
} from "~/redux/warehouse/recurringSchedule.hooks";
import { useGetStagingAreasQuery } from "~/redux/warehouse/stagingArea.hooks";
import {
  CreateRecurringScheduleRequest,
  RecurringScheduleDto
} from "~/types/api";

type Props = {
  recurringSchedule: RecurringScheduleDto;
  onClose: () => void;
};

export default function EditRecurringScheduleModal(props: Props) {
  const { errorToast, successToast } = useToast();
  const { recurringSchedule, onClose } = props;
  const {
    batchDependency,
    dayOfWeek,
    intervalMinutes,
    minMaxOrdersPerWave,
    orderTypes,
    recurringScheduleId,
    scheduleWindow,
    shipmentConfig,
    stagingAreaId
  } = recurringSchedule;

  const { data: stagingAreas } = useGetStagingAreasQuery();

  const [deleteRecurringSchedule] = useDeleteRecurringScheduleMutation();
  const [postRecurringSchedule] = usePostRecurringScheduleMutation();

  const [startTime, setStartTime] = useState<Dayjs>(
    getDayjsFromScheduleWindow(scheduleWindow[0])
  );
  const [endTime, setEndTime] = useState<Dayjs>(
    getDayjsFromScheduleWindow(scheduleWindow[1])
  );

  const {
    register,
    getValues,
    control,
    formState: { isDirty, isValid }
  } = useForm<CreateRecurringScheduleRequest>({
    defaultValues: {
      intervalMinutes,
      minMaxOrdersPerWave,
      shipmentConfig,
      dayOfWeek,
      scheduleWindow,
      orderTypes,
      batchDependency,
      stagingAreaId: stagingAreaId || ""
    }
  });

  const handleSave = async () => {
    const newSchedule = getValues();
    try {
      await deleteRecurringSchedule(recurringScheduleId).unwrap();
      await postRecurringSchedule(newSchedule).unwrap();
      successToast(t("batch setting updated"));
      onClose();
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    }
  };

  return (
    <Dialog
      open={!!recurringSchedule}
      TransitionComponent={ModalDialogTransition}
      onClose={onClose}
    >
      <DialogTitle>
        {t("edit")} {dayOfWeekAsString(dayOfWeek)}
      </DialogTitle>

      <DialogContent>
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr"
          pt="20px"
          gap="20px 40px"
        >
          <Box>
            <InputLabel shrink>{t("start time")}</InputLabel>
            <Controller
              name="scheduleWindow.0"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DesktopTimePicker
                  value={startTime}
                  onChange={(time) => {
                    if (time) {
                      setStartTime(time);
                    }
                    field.onChange(dayjs(time).format("HH:mm:ss"));
                  }}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              )}
            />
          </Box>

          <Box>
            <InputLabel htmlFor="minimum" shrink>
              {t("batch minimum label")}
            </InputLabel>
            <TextField
              id="minimum"
              type="number"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t("orders").toLowerCase()}
                  </InputAdornment>
                )
              }}
              {...register("minMaxOrdersPerWave.0", {
                valueAsNumber: true,
                required: true
              })}
            />
          </Box>

          <Box>
            <InputLabel shrink>{t("end time")}</InputLabel>
            <Controller
              name="scheduleWindow.1"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DesktopTimePicker
                  value={endTime}
                  onChange={(time) => {
                    if (time) {
                      setEndTime(time);
                    }
                    field.onChange(dayjs(time).format("HH:mm:ss"));
                  }}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              )}
            />
          </Box>

          <Box>
            <InputLabel htmlFor="maximum" shrink>
              {t("batch maximum label")}
            </InputLabel>
            <TextField
              id="maximum"
              type="number"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t("orders").toLowerCase()}
                  </InputAdornment>
                )
              }}
              {...register("minMaxOrdersPerWave.1", {
                valueAsNumber: true,
                required: true
              })}
            />
          </Box>

          <Box>
            <InputLabel htmlFor="intervalMinutes" shrink>
              {t("batch interval label")}
            </InputLabel>
            <TextField
              id="intervalMinutes"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{t("minutes")}</InputAdornment>
                )
              }}
              {...register("intervalMinutes", {
                required: true,
                pattern: numbersRegex
              })}
            />
          </Box>

          <OrderTypesDropdown
            control={control}
            fieldName="orderTypes"
            tooltipText={t("batch order types tooltip")}
          />

          <Controller
            name="stagingAreaId"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                select
                label={t("staging area")}
                error={!!error}
                sx={{ mt: "8px" }}
                {...field}
              >
                <MenuItem value="">
                  <em>{t("none")}</em>
                </MenuItem>
                {stagingAreas?.map((sa) => (
                  <MenuItem key={sa.stagingAreaId} value={sa.stagingAreaId}>
                    {sa.stagingAreaName}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Box>

        <Controller
          name="shipmentConfig"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              label={t("use shipments checkbox")}
              sx={{ m: "32px 0 0" }}
              control={
                <Checkbox
                  checked={value.toLowerCase() === "useshipments"}
                  onChange={(_, checked) => {
                    onChange(checked ? "UseShipments" : "NoSpecifiedShipment");
                  }}
                />
              }
            />
          )}
        />

        <Controller
          name="batchDependency"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              label={t("batch dependency checkbox")}
              sx={{ m: "4px 0 0" }}
              control={
                <Checkbox
                  checked={value?.toLowerCase() === "warehouse on autostore"}
                  onChange={(_, checked) => {
                    onChange(
                      checked ? "Warehouse on Autostore" : "No Dependency"
                    );
                  }}
                />
              }
            />
          )}
        />
      </DialogContent>

      <DialogActions sx={{ gap: 1 }}>
        <Button
          onClick={onClose}
          variant="subtle"
          color="secondary"
          size="large"
          sx={{ fontWeight: "normal" }}
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSave}
          disabled={!isDirty || !isValid}
          size="large"
          sx={{ minWidth: 88, fontWeight: "normal" }}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
