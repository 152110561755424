import CheckIcon from "@mui/icons-material/Check";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Button,
  Card,
  DialogTitle,
  Grid,
  InputBaseComponentProps,
  MenuItem,
  SelectChangeEvent,
  styled,
  TextField,
  Typography
} from "@mui/material";
import { RoundIconButton, useToast } from "@qubit/autoparts";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectPutawayReasonCodeOptions } from "~/redux/selectors/inventorySelectors";
import { selectWorkstationId } from "~/redux/selectors/workstationsSelectors";
import { usePatchInventoryNetAdjustmentMutation } from "~/redux/warehouse/inventory.hooks";
import { PutAwayTaskSummaryDto } from "~/types/api";

const QuantityTextField = styled(TextField)`
  & input::-webkit-clear-button,
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    display: none;
  }
  width: 100%;
`;

const DropdownOption = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== "isBorderBottomShown"
})<{ isBorderBottomShown: boolean }>(({ isBorderBottomShown, theme }) => ({
  padding: "10px",
  fontSize: "24px",
  borderBottom: isBorderBottomShown
    ? `1px solid ${theme.palette.gray.light}`
    : "none"
}));

const AdjustQuantityButton = styled(RoundIconButton)(({ theme }) => ({
  color: "black",
  backgroundColor: theme.palette.gray.dark,
  ":hover": {
    backgroundColor: theme.palette.darkGray.light
  },
  border: "none"
}));

const quantityTextFieldInputProps: InputBaseComponentProps = {
  style: {
    fontSize: 50,
    padding: "35px 10px 35px 18px",
    textAlign: "center"
  }
};

export type Props = {
  closeModal: () => void;
  handleSuccess: () => void;
  putawayTask: PutAwayTaskSummaryDto;
  variant: "under-received" | "over-received";
};

/** Originally copied and modified from the PutawayAdjustModal. This modal has two variants, "Under
 * Received" and "Over Received" which have slightly different behavior. */
export function AdjustQuantityModal(props: Props) {
  const { t } = useTranslation();
  const { closeModal, handleSuccess, putawayTask, variant } = props;

  const { errorToast, successToast } = useToast();

  const isUnderReceive = variant === "under-received";
  const isOverReceive = variant === "over-received";
  const [adjustmentQuantity, setAdjustmentQuantity] = useState<number>(0);
  const [reasonCode, setReasonCode] = useState<string | null>(null);

  const putawayReasonCodeOptions = useAppSelector(
    selectPutawayReasonCodeOptions
  );
  const workstationId = useAppSelector(selectWorkstationId);

  const [
    patchNetAdjustment,
    {
      isLoading: isAdjustingInventory,
      isSuccess: hasAdjustedInventorySuccessfully
    }
  ] = usePatchInventoryNetAdjustmentMutation();

  const confirmText = `${
    isUnderReceive ? t("remove") : t("add")
  } ${adjustmentQuantity}`;

  const isConfirmButtonEnabled =
    adjustmentQuantity && ((isUnderReceive && reasonCode) || isOverReceive);

  const isDecreaseQuantityButtonDisabled =
    adjustmentQuantity <= 0 ||
    isAdjustingInventory ||
    hasAdjustedInventorySuccessfully;

  const handleReasonCodeChange = (event: SelectChangeEvent<unknown>) => {
    if (typeof event?.target?.value === "string") {
      setReasonCode(event.target.value);
    }
  };

  const isAdjustmentGreaterThanRemaining = (
    adjustmentQuantityParam: number | null,
    putawayTaskParam: { remaining: { value: number } | null }
  ) => {
    if (
      !putawayTaskParam ||
      !putawayTaskParam.remaining ||
      adjustmentQuantityParam === null
    ) {
      return false;
    }

    return putawayTaskParam.remaining.value < adjustmentQuantityParam;
  };

  const handleSubmit = async () => {
    try {
      const { inventoryId, receivedQuantity, quantity } = putawayTask;
      const qty = receivedQuantity ?? quantity;
      const adjustmentQuantityToSend = isUnderReceive
        ? -adjustmentQuantity
        : adjustmentQuantity;
      const netAdjustment = {
        units: qty.units,
        value: adjustmentQuantityToSend
      };
      const netCommittedAdjustment = { units: qty.units, value: 0 };
      // Reason code will always be "Over Received" if the variant is "Over Received"
      const reasonCodeToSend = isUnderReceive ? reasonCode : "Over Received";

      if (!reasonCodeToSend) {
        throw new Error("Reason code was unexpectedly not set");
      }
      if (!inventoryId) {
        throw new Error("Inventory ID was unexpectedly not set");
      }

      await patchNetAdjustment({
        inventoryId,
        netAdjustment,
        netCommittedAdjustment,
        reasonCode: reasonCodeToSend,
        workstationId
      }).unwrap();

      successToast(t("inventory adjusted"));
      handleSuccess();
    } catch (err: unknown) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  const quantityTextField = (
    <Grid>
      <QuantityTextField
        data-testid="quantity-text-field"
        label={t("quantity adjustment")}
        type="number"
        variant="outlined"
        value={adjustmentQuantity}
        onChange={(e) => {
          const newValue = e.target.value ? Number(e.target.value) : 0;
          if (
            isUnderReceive &&
            putawayTask?.remaining.value !== undefined &&
            newValue <= putawayTask?.remaining.value
          ) {
            setAdjustmentQuantity(newValue);
          } else if (!isUnderReceive) {
            setAdjustmentQuantity(newValue);
          }
        }}
        inputProps={quantityTextFieldInputProps}
        InputLabelProps={{ shrink: true }}
      />
      {isUnderReceive &&
        putawayTask?.remaining.value !== undefined &&
        adjustmentQuantity > putawayTask?.remaining.value && (
          <Typography style={{ color: "red" }}>
            {t("adjustment warning")}
          </Typography>
        )}
    </Grid>
  );

  const isRemoveButtonDisabled =
    isUnderReceive &&
    putawayTask !== null &&
    isAdjustmentGreaterThanRemaining(adjustmentQuantity, putawayTask);

  const quantityButtons = (
    <Grid
      container
      item
      direction="column"
      xs={1}
      spacing={1}
      wrap="nowrap"
      sx={{ position: "absolute", top: 28, right: 8 }}
    >
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <AdjustQuantityButton
          data-testid="increase-quantity-button"
          aria-label="increase quantity"
          onClick={() => setAdjustmentQuantity(adjustmentQuantity + 1)}
          disabled={isAdjustingInventory || hasAdjustedInventorySuccessfully}
          size="large"
        >
          <ExpandLessIcon sx={{ fontSize: 27 }} />
        </AdjustQuantityButton>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <AdjustQuantityButton
          data-testid="decrease-quantity-button"
          aria-label="decrease quantity"
          onClick={() => setAdjustmentQuantity(adjustmentQuantity - 1)}
          disabled={isDecreaseQuantityButtonDisabled}
          size="large"
        >
          <ExpandMoreIcon sx={{ fontSize: 27 }} />
        </AdjustQuantityButton>
      </Grid>
    </Grid>
  );

  const reasonCodeDropdown = (
    <TextField
      data-testid="reason-code-dropdown"
      label={t("reason code")}
      type="string"
      variant="outlined"
      value={reasonCode || ""}
      fullWidth
      select
      inputProps={{ style: { fontSize: "1rem" } }}
      InputLabelProps={{ shrink: true }}
      SelectProps={{
        onChange: handleReasonCodeChange,
        displayEmpty: true,
        renderValue: (value): ReactNode => {
          const item = putawayReasonCodeOptions.find(
            ({ value: v }) => v === value
          );
          return item?.label ?? t("select reason code");
        }
      }}
    >
      {putawayReasonCodeOptions.map((option, i, arr) => (
        <DropdownOption
          key={`reason-code-option-${option.value}`}
          data-testid={`reason-code-option-${i}`}
          value={option.value}
          isBorderBottomShown={i !== arr.length - 1}
        >
          {option.label}
        </DropdownOption>
      ))}
    </TextField>
  );

  const ConfirmButton = (
    <Button
      data-testid="submit-adjust-inventory-button"
      sx={{ width: "100%", fontWeight: "normal" }}
      startIcon={<CheckIcon />}
      onClick={handleSubmit}
      size="large"
      disabled={
        isAdjustingInventory ||
        isRemoveButtonDisabled ||
        hasAdjustedInventorySuccessfully ||
        !isConfirmButtonEnabled
      }
    >
      {confirmText}
    </Button>
  );

  const CancelButton = (
    <Button
      data-testid="cancel-adjust-inventory-button"
      variant="subtle"
      sx={{ width: "100%", fontWeight: "normal" }}
      size="large"
      startIcon={<HighlightOffIcon />}
      onClick={closeModal}
      disabled={isAdjustingInventory || hasAdjustedInventorySuccessfully}
    >
      {t("cancel")}
    </Button>
  );

  return (
    <Grid container item xs={12} direction="column" wrap="nowrap">
      <Grid item>
        <Card>
          <DialogTitle data-testid="adjust-quantity-modal-header">
            {isUnderReceive ? t("under received") : t("over received")}
          </DialogTitle>
          <Grid container spacing={2} alignItems="center" p="20px">
            <Grid item xs={12} position="relative">
              {quantityTextField}
              {quantityButtons}
            </Grid>
            {isUnderReceive && (
              <Grid item xs={12} md={12}>
                {reasonCodeDropdown}
              </Grid>
            )}
            <Grid container item spacing={2}>
              <Grid item xs={6}>
                {CancelButton}
              </Grid>
              <Grid item xs={6}>
                {ConfirmButton}
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
