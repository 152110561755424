import { Box, Paper, Typography } from "@mui/material";
import { ReactNode } from "react";

type SettingsInheritedProps = {
  panelTitle: string;
  children: ReactNode;
  enableFullHeight?: boolean;
};
type SettingsProps = SettingsInheritedProps;

function SettingsPanel(props: SettingsProps) {
  const { panelTitle, children, enableFullHeight } = props;

  return (
    <Box
      className="settings-panel"
      sx={{
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        ...(enableFullHeight && { height: "100%" }),
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "10px"
      }}
    >
      <Paper
        sx={{
          width: "100%",
          ...(enableFullHeight && { height: "100%" }),
          display: "flex",
          boxSizing: "border-box",
          flexDirection: "column",
          padding: "20px"
        }}
      >
        <Box sx={{ marginBottom: "10px" }}>
          <Typography variant="h6">{panelTitle}</Typography>
        </Box>
        {children}
      </Paper>
    </Box>
  );
}

export default SettingsPanel;
