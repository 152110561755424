import ErrorIcon from "@locaisolutions/icons/dist/icons24px/Error24Px";
import { Box, Button, CircularProgress, styled, Paper } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Center } from "@qubit/autoparts";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { OrganizationLoginButton } from "~/features/organizationLoginButton";
import { selectUsersFulfillmentCenterId } from "~/redux/selectors/storeSelectors";

import { getLoginResponseFromWarehouse, setLoginPath } from "./login.slice";

const StyledForm = styled("form")(() => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  margin: "40px auto",
  width: "80vw",
  maxWidth: 516
}));
const StyledTextfield = styled(TextField)(() => ({
  marginLeft: 1,
  marginRight: 1,
  width: "100%"
}));

export function BevClientLogin() {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const userFulfillmentCenterId = useAppSelector(
    selectUsersFulfillmentCenterId
  );
  const errorMessage = useAppSelector((state) => state.login.errorMessage);

  const [inputValues, setInputValues] = useState({
    operator: "",
    password: ""
  });
  const [loggingIn, setLoggingIn] = useState<boolean>(false);
  const [autoFillDetected, setAutofillDetected] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setLoginPath(location.pathname));
  }, [dispatch, location.pathname]);

  const enableSubmit =
    !loggingIn &&
    userFulfillmentCenterId &&
    ((inputValues.operator && inputValues.password) || autoFillDetected);

  const handleAutofill = (e: React.AnimationEvent<HTMLFormElement>): void => {
    const target = e.target as HTMLInputElement;

    if (e.animationName === "mui-auto-fill" && target.id === "standard-name") {
      setAutofillDetected(true);
    }
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    setLoggingIn(true);

    try {
      await dispatch(
        getLoginResponseFromWarehouse({
          operator: inputValues.operator,
          password: inputValues.password
        })
      ).unwrap(); // on success, returns value; on failure, throws error to prevent moving forward
      navigate("/callback");
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (_e) {
      // do nothing
    } finally {
      setLoggingIn(false);
    }
  };

  return (
    <Center height="100%">
      <Paper
        style={{
          width: "fit-content",
          margin: "16px auto",
          padding: "1px 16px"
        }}
      >
        <StyledForm
          noValidate
          autoComplete="on"
          onSubmit={(e) => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
            handleSubmit(e);
          }}
          onAnimationStart={handleAutofill}
        >
          <StyledTextfield
            id="standard-name"
            label="Operator"
            value={inputValues.operator}
            onChange={(e): void => {
              setInputValues({ ...inputValues, operator: e.target.value });
              setAutofillDetected(false);
            }}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
          />
          <StyledTextfield
            id="standard-password-input"
            label="Password"
            type="password"
            autoComplete="current-password"
            value={inputValues.password}
            onChange={(e): void => {
              setInputValues({ ...inputValues, password: e.target.value });
              setAutofillDetected(false);
            }}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
          />
          <Box position="relative">
            <Button
              variant="contained"
              color="primary"
              disabled={!enableSubmit}
              type="submit"
              id="login-button"
              sx={{
                width: 320,
                margin: "20px auto",
                fontSize: "1.5rem"
              }}
            >
              Log In as Operator
            </Button>
            {loggingIn && (
              <Box
                position="absolute"
                top="50%"
                left="50%"
                marginTop="-12px"
                marginLeft="-12px"
              >
                <CircularProgress size={24} />
              </Box>
            )}
          </Box>
          {!userFulfillmentCenterId && (
            <Typography
              className="login__warning"
              sx={{
                textAlign: "center",
                fontSize: "1.25rem"
              }}
            >
              This workstation has not been configured yet for this site.
              <br />
              Log in as administrator to configure.
            </Typography>
          )}
        </StyledForm>
      </Paper>
      <Box position="relative" style={{ textAlign: "center" }}>
        <OrganizationLoginButton
          sx={{
            width: 320,
            margin: "20px auto"
          }}
          organizationName={"pepsi"}
        >
          <Typography sx={{ fontSize: "1.5rem" }}>Log In as Admin</Typography>
        </OrganizationLoginButton>
      </Box>
      <Box
        sx={{
          height: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <ErrorIcon
          style={{
            stroke: "red",
            fill: "red",
            visibility: errorMessage ? "visible" : "hidden"
          }}
        />
        <Typography
          sx={{
            marginLeft: 1,
            color: "red",
            textAlign: "center",
            fontSize: "1.5rem"
          }}
        >
          {errorMessage}
        </Typography>
      </Box>
    </Center>
  );
}

export default BevClientLogin;
