import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from "@mui/material";

type Props = {
  upcs: string[];
};

/**
 * Renders a UPC on an accordion when collapsed (default), and
 * the remaining UPCs within the accordion when expanded.
 *
 * Intended for use as a child of UniversalProductCard.
 */
export function UpcList({ upcs }: Props) {
  const upcFieldName = upcs && upcs?.length === 1 ? "UPC" : "UPCS";

  return (
    <Box id="upcs" mx={-2} mt={-2} bgcolor="gray.light" py={2}>
      {upcs.length > 1 ? (
        <Accordion
          square
          disableGutters
          sx={{ boxShadow: "none", bgcolor: "gray.light" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="upcs-content"
            id="upcs-content"
            style={{
              minHeight: "20px",
              height: "20px"
            }}
          >
            <Typography
              gutterBottom
              variant="h6"
              component="h6"
              fontSize="1rem"
              style={{ padding: 0, margin: 0, fontWeight: 400 }}
            >
              {upcFieldName + ": " + upcs[0]}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: 0,
              margin: 0,
              pt: 0.5,
              bgcolor: "gray.light",
              paddingLeft: 8
            }}
          >
            {upcs.slice(1).map((upcRow: string, i: number) => (
              <Typography
                key={`upc-${i + 1}`}
                variant="h6"
                component="h6"
                fontSize="1rem"
                sx={{ fontWeight: 400 }}
              >
                {upcRow}
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>
      ) : (
        <Typography
          variant="h6"
          component="h6"
          fontSize="1rem"
          sx={{ fontWeight: 400, px: 2 }}
        >
          {upcFieldName + ": " + upcs[0]}
        </Typography>
      )}
    </Box>
  );
}
