import { useToast, LoadingPage } from "@qubit/autoparts";
import { t } from "i18next";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "~/app/store";
import { useFlag } from "~/config/flags";
import { clearEventData } from "~/features/andon/andon.slice";
import { useCloseWorkstationWithErrorToast } from "~/hooks/useCloseWorkstationWithErrorToast";
import { mixpanelLogout } from "~/lib/mixpanel-tracking";
import { sendUserEventAction } from "~/redux/actions/userEvents";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";

import {
  clearLoginState,
  qubitAuthClient,
  andonAuthClient,
  setUnauthorizedLogoutRequested
} from "./login.slice";
import { AuthMethod } from "./login.slice.interfaces";

export const Logout = () => {
  const dispatch = useAppDispatch();
  const { errorToast } = useToast();
  const navigate = useNavigate();

  const closeWorkstationWithErrorToast = useCloseWorkstationWithErrorToast();

  const usersFulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);

  const isBevClientLogoutDecouplingEnabled =
    useFlag().bevClientLogoutFlowDecoupling;

  const loginPath = useAppSelector((state) => state.site.loginPath) || "/login";
  const customLoginPath = useAppSelector((state) => state.site.customLoginPath);
  const authMethod = useAppSelector((state) => state.login.authMethod);
  const isUserLoggedIn = useAppSelector((state) => state.login.isUserLoggedIn);

  // used to preserve useEffect dependencies while ensuring only one logout action is taken
  const hasLoggedOut = useRef(false);

  const performAuthLogout = async (
    authMethod: AuthMethod | null,
    customLoginPath: string | undefined,
    loginPath: string
  ): Promise<void> => {
    let authClient = null;

    switch (authMethod) {
      case "QUBIT":
      case "E2E":
        authClient = qubitAuthClient;
        break;
      case "ANDON":
        authClient = andonAuthClient;
        break;
      case "BEV_QUBIT":
      default:
        authClient = null;
        break;
    }

    if (authClient) {
      const logoutParams = customLoginPath
        ? { returnTo: customLoginPath }
        : {
            federated: true,
            returnTo: `${window.location.origin}${loginPath}`
          };

      await authClient.logout({
        logoutParams
      });
    }
  };

  useEffect(() => {
    const handleLogout = async () => {
      if (hasLoggedOut.current) {
        return;
      }
      hasLoggedOut.current = true;
      dispatch(setUnauthorizedLogoutRequested(false));

      // Clear event data and workstation
      dispatch(clearEventData());

      if (usersFulfillmentCenter) {
        await closeWorkstationWithErrorToast();
      }

      // Send user event action
      try {
        await dispatch(
          sendUserEventAction(
            "UserLoggedOut",
            isBevClientLogoutDecouplingEnabled
          )
        );
      } catch {
        errorToast("Failed to send user event action");
      }

      // Logout of Mixpanel (error handled in function)
      mixpanelLogout();

      // Clear login state
      dispatch(clearLoginState());

      if (authMethod === "BEV_QUBIT") {
        navigate(loginPath);
      } else {
        await performAuthLogout(authMethod, customLoginPath, loginPath);
      }
    };

    if (isUserLoggedIn) {
      void handleLogout();
    } else {
      navigate(loginPath);
    }
  }, [
    dispatch,
    closeWorkstationWithErrorToast,
    isBevClientLogoutDecouplingEnabled,
    usersFulfillmentCenter,
    errorToast,
    customLoginPath,
    loginPath,
    navigate,
    authMethod,
    isUserLoggedIn
  ]);

  return <LoadingPage loadingMessage={t("logging off")} />;
};
