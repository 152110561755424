import { Components, Theme } from "@mui/material";

export const muiAlertTitle: Components<Omit<Theme, "components">> = {
  MuiAlertTitle: {
    styleOverrides: {
      root: {
        fontSize: "2rem",
        lineHeight: "2rem",
        margin: 0
      }
    }
  }
};
