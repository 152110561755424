import { Button, CircularProgress, ButtonProps } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useState } from "react";

import { mobileWidth } from "../theme/theme";

const xLargeButton = {
  fontSize: 20,
  minHeight: 100
};
const largeButton = {
  fontSize: 20,
  minHeight: 65
};
const mediumButton = {
  fontSize: 18,
  minHeight: 50,
  fontWeight: 400
};
const smallButton = {
  fontSize: 16,
  minHeight: 40,
  fontWeight: 200
};
const tinyButton = {
  fontSize: 12,
  minHeight: 30,
  fontWeight: 200
};
const footerButton = {
  fontSize: 20,
  minHeight: 100,
  width: 250
};

export type ProgressButtonProps = {
  /** Call to action, secondary action, link-type button */
  emphasis?: "high" | "medium" | "low";
  buttonSize?:
    | "footer"
    | "xLarge"
    | "large"
    | "medium"
    | "small"
    | "unset"
    | "tiny";
  /** If true, steps down a size on mobile */
  responsive?: boolean;
  color?: string;
  backgroundColor?: string;
  hideProgress?: boolean;
  onClick: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => Promise<unknown> | void | null;
} & ButtonProps;

/**
 * A component that renders a progress indicator while
 * an async task is in process.  Requires that onClick return a promise.
 */
export function ProgressButton(props: ProgressButtonProps) {
  const [progress, setProgress] = useState<boolean>(false);
  const {
    disabled,
    emphasis,
    buttonSize,
    responsive = false,
    className = "",
    sx,
    color,
    hideProgress,
    ...rest
  } = props;
  const isMobile = useMediaQuery(mobileWidth) && responsive;
  const isDisabled = disabled || progress;
  let variant: "contained" | "outlined" | "text";
  let sizeClassName: {
    fontSize?: number;
    minHeight?: number;
    fontWeight?: number;
  } | null;
  switch (emphasis) {
    case "high":
      variant = "contained";
      break;
    case "medium":
      variant = "outlined";
      break;
    case "low":
      variant = "text";
      break;
    default:
      variant = "contained";
  }

  switch (buttonSize) {
    case "xLarge":
      sizeClassName = isMobile ? largeButton : xLargeButton;
      break;
    case "large":
      sizeClassName = isMobile ? mediumButton : largeButton;
      break;
    case "medium":
      sizeClassName = isMobile ? smallButton : mediumButton;
      break;
    case "small":
      sizeClassName = smallButton;
      break;
    case "tiny":
      sizeClassName = tinyButton;
      break;
    case "footer":
      sizeClassName = isMobile ? largeButton : footerButton;
      break;
    case "unset":
    default:
      sizeClassName = null;
  }
  return (
    <span style={{ ...props.style, position: "relative" }}>
      <Button
        className={className}
        variant={variant}
        sx={{ ...sizeClassName, ...sx }}
        disabled={isDisabled}
        {...rest}
        onClick={(event): void => {
          const promise = props.onClick(event);
          if (promise && !hideProgress) {
            setProgress(true);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
            promise.finally(() => setProgress(false));
          }
        }}
        color={color}
      />
      {progress && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px"
          }}
        />
      )}
    </span>
  );
}
