import { Box, Paper, Stack, styled, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import CleaningBinSchedule from "~/features/cleaningBin/CleaningBinSchedule";
import { useGridSelectorWrapper } from "~/features/inventory/useGridSelectorWrapper";

import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";
import { selectUserIsAdmin } from "~/redux/selectors/authSelectors";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";

import BinCleaning from "./BinCleaning";
import BinFillCapacity from "./BinFillCapacity";
import BinMovements from "./BinMovements";
import BinRemoved from "./BinRemoved";
import FulfillmentCenterChip from "./FulfillmentCenterChip";

type Props = {
  viewTitle?: ViewNameTranslation;
};

const GridSettingsContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "2fr 1fr",
  gap: theme.spacing(2),
  alignItems: "stretch",
  justifyItems: "stretch",
  "@media (max-width: 700px)": {
    gridTemplateColumns: "1fr",
    gap: theme.spacing(1)
  }
}));

export function GridSettings(props: Props) {
  const { viewTitle } = props;
  const { t } = useTranslation();
  const clientConfig = useAppSelector(selectClientConfig);
  const isAdmin = useAppSelector(selectUserIsAdmin);
  const binFlaggingEnabled = useAppSelector(
    (state) => state.store.usersFulfillmentCenter?.binFlaggingEnabled
  );
  const { ap_binRemovedFlow, binCleaningEnabled } = clientConfig;

  useNavbar({ viewTitle });
  const { gridSelectorButton } = useGridSelectorWrapper();
  const isBinCleaningTabEnabled =
    binCleaningEnabled && isAdmin && binFlaggingEnabled;

  return (
    <Stack p={2} spacing={4}>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">{t("grid settings")}</Typography>
          {gridSelectorButton}
        </Stack>
        <FulfillmentCenterChip />
      </Stack>
      <GridSettingsContainer>
        {isBinCleaningTabEnabled && <CleaningBinSchedule />}
        <Paper
          sx={{
            margin: "0px"
          }}
        >
          <BinFillCapacity />
        </Paper>
        {binFlaggingEnabled && (
          <Paper sx={{ margin: "0px" }}>
            <BinMovements />
          </Paper>
        )}
        {binFlaggingEnabled && (
          <Paper sx={{ margin: "0px" }}>
            <BinCleaning />
          </Paper>
        )}
        {ap_binRemovedFlow && (
          <Paper sx={{ margin: "0px" }}>
            <BinRemoved />
          </Paper>
        )}
      </GridSettingsContainer>
    </Stack>
  );
}
