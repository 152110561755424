import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import { TabPanel as BaseTabPanel } from "@mui/base/TabPanel";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import {
  buttonClasses,
  dividerClasses,
  Divider,
  useTheme,
  Box,
  boxClasses
} from "@mui/material";

import { ComponentProps } from "react";

import { basicColours, mainColours } from "../theme/colours";
import { styled } from "../theme/theme";

const beforeTabSvgClass = "beforeTabSvg";
const afterTabSvgClass = "afterTabSvg";

const OutlineStyledTab = styled(BaseTab)(({ theme }) => {
  return {
    color: theme.palette.text.secondary,
    cursor: "pointer",

    border: "none",
    borderColor: "none",

    backgroundColor: theme.palette.background.secondary,
    display: "flex",
    width: "220px",
    height: "48px",
    justifyContent: "center",
    alignItems: "center",

    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    fontWeight: 400,

    "&:hover": {
      borderRadius: "12px 12px 0px 0px",
      backgroundColor: basicColours.mono[50],
      color: mainColours.text.inactiveHover
    },

    "&:focus-visible": {
      backgroundColor: theme.palette.primary.light,
      outline: "none"
    },

    [`&.${tabClasses.selected}`]: {
      borderRadius: "12px 12px 0px 0px",
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary
    },

    [`&.${tabClasses.selected}:focus-visible`]: {
      backgroundColor: theme.palette.primary.light,
      outline: "none"
    },

    [`&.${buttonClasses.disabled}`]: {
      opacity: "0.5",
      cursor: "not-allowed"
    }
  };
});

export const OutlineTabDivider = styled(Divider)(({ theme }) => {
  return {
    height: "28px",
    alignSelf: "center",
    borderLeft: `2px solid ${theme.palette.background.hover}`
  };
});

function BeforeTabSvg() {
  const theme = useTheme();

  return (
    <Box
      className={beforeTabSvgClass}
      position="relative"
      left="0px"
      top="7px"
      display="none"
      width="0px"
    >
      <Box position="relative" left="-10px">
        <svg
          width="11"
          height="41"
          viewBox="0 0 11 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 30.4893V41H0C5.52285 41 10 36.0121 10 30.4893Z"
            fill={theme.palette.background.default}
          />
        </svg>
      </Box>
    </Box>
  );
}

function AfterTabSvg() {
  const theme = useTheme();

  return (
    <Box
      className={afterTabSvgClass}
      position="relative"
      left="0px"
      top="7px"
      display="none"
      width="0px"
    >
      <Box position="relative" left="-3px">
        <svg
          width="11"
          height="41"
          viewBox="0 0 11 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 30.4893V41H11C5.47715 41 1 36.0121 1 30.4893Z"
            fill={theme.palette.background.default}
          />
        </svg>
      </Box>
    </Box>
  );
}

export function OutlineTab(props: ComponentProps<typeof OutlineStyledTab>) {
  return (
    <>
      <BeforeTabSvg />
      <OutlineStyledTab {...props} />
      <OutlineTabDivider role="none" />
      <AfterTabSvg />
    </>
  );
}

export const OutlineTabPanel = styled(BaseTabPanel)(({ theme }) => {
  return {
    width: "100%",
    background: theme.palette.background.default
  };
});

export const OutlineTabsList = styled(BaseTabsList)(({ theme }) => {
  return {
    backgroundColor: theme.palette.background.secondary,
    paddingTop: "4px",
    paddingLeft: "4px",
    paddingRight: "4px",
    display: "flex",
    borderRadius: "8px 8px 0px 0px",

    // curve before active tab
    [`.${beforeTabSvgClass}:has(+ .${tabClasses.selected}):not(:first-of-type)`]:
      {
        display: "inline-block"
      },
    // curve before active:focused tab
    [`.${beforeTabSvgClass}:has(+ .${tabClasses.selected}:focus-visible) > .${boxClasses.root} > svg > path`]:
      {
        fill: theme.palette.primary.light
      },
    // curve after active tab
    [`.${tabClasses.selected} + .${dividerClasses.root} + .${afterTabSvgClass}:not(:last-of-type)`]:
      {
        display: "inline-block"
      },
    // curve after active:focused tab
    [`.${tabClasses.selected}:focus-visible + .${dividerClasses.root} + .${afterTabSvgClass} > .${boxClasses.root} > svg > path`]:
      {
        fill: theme.palette.primary.light
      },
    // last inactive tab divider
    [`.${dividerClasses.root}:last-of-type`]: {
      display: "none"
    },
    // divider after active tab
    [`.${dividerClasses.root}:has(+ .${afterTabSvgClass} + .${beforeTabSvgClass} + .${tabClasses.selected})`]:
      {
        visibility: "hidden"
      },
    // divider after active tab
    [`.${tabClasses.selected} + .${dividerClasses.root}`]: {
      visibility: "hidden"
    }
  };
});
