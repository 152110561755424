import { Avatar, Box, Stack, Typography, useMediaQuery } from "@mui/material";
import {
  ErrorPage,
  formatDateTime,
  mobileWidth,
  PickStatusIcon,
  ScanningIndicator,
  useScanIndicator,
  useToast,
  TinyIconGenerator,
  ASTableV2,
  ASTableV2Body,
  ASTableV2Cell,
  ASTableV2Header,
  ASTableV2Row,
  ASTableV2RowCollapse,
  ASTableV2RowSkeleton,
  ASTableV2RowEmpty
} from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { CartDisplay } from "~/features/batch/Pick";
import { toteLabelZPL } from "~/features/batch/toteLabelZpl";
import PrintableToteLabels from "~/features/printableToteLabels/PrintableToteLabels";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";
import { usePrinter } from "~/hooks/usePrinter";
import { useBarcodeScanner, useKeyDownHandler } from "~/lib/barCodeScan";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  combineTotesAndLoosePicks,
  getPosition,
  getToteOrLoosePickId,
  getType,
  ToteOrLoosePick
} from "~/lib/toteOrLoosePick";

import {
  emptyLoosePickArray,
  emptyToteArray,
  selectedTotesAndLoosePicks as getSelectedTotesAndLoosePicks,
  singleSelectedToteOrLoosePick
} from "~/redux/selectors/stageTotesSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { useGetBatchQuery } from "~/redux/warehouse/batches.hooks";
import {
  useGetLoosePicksQuery,
  useStageLoosePicksMutation
} from "~/redux/warehouse/loosePicks.openApi";
import {
  useGetTotesQuery,
  useStageTotesMutation
} from "~/redux/warehouse/totes.hooks";
import { ToteSummaryDto } from "~/types/api";

import { CompletionModal } from "./CompletionModal";
import { StageTotesToolbar } from "./StageTotesToolbar";
import { LoosePick } from "./loosePick.type";
import {
  clearSelectedToteIds,
  getSelectedToteIds,
  selectToteIds
} from "./stageTotes.slice";

export type StageTotesProps = { viewTitle?: ViewNameTranslation };

export const StageTotes = (props: StageTotesProps) => {
  const { viewTitle } = props;
  const { batchName = "" } = useParams<{ batchName: string }>();
  const { errorToast } = useToast();

  const {
    data: batchResponse,
    error: batchError,
    isFetching: isBatchFetching
  } = useGetBatchQuery(
    { batchId: batchName },
    {
      refetchOnMountOrArgChange: true
    }
  );

  const batch = batchResponse?.batch;

  const {
    data: loosePicks,
    error: loosePicksError,
    isFetching: isLoosePicksFetching
  } = useGetLoosePicksQuery(batch ? { batchId: batch.batchId } : skipToken, {
    refetchOnMountOrArgChange: true
  });

  const {
    data: totes,
    error: totesError,
    isFetching: isTotesFetching
  } = useGetTotesQuery(batch ? { batchId: batch.batchId } : skipToken, {
    refetchOnMountOrArgChange: true
  });

  const [stageLoosePicks] = useStageLoosePicksMutation();
  const [stageTotes] = useStageTotesMutation();

  const fulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);

  const printerConfiguration = fulfillmentCenter?.printerConfiguration;
  const print = usePrinter();

  const { t } = useTranslation();
  const isMobile = useMediaQuery(mobileWidth);
  const dispatch = useAppDispatch();
  const { setToolbar, setMenuItems } = useNavbar({ viewTitle });

  const totesAndLoosePicks = useMemo(
    () =>
      combineTotesAndLoosePicks(
        totes || emptyToteArray,
        loosePicks || emptyLoosePickArray
      ),
    [totes, loosePicks]
  );
  const selectedTotesAndLoosePicks = useAppSelector((state) =>
    getSelectedTotesAndLoosePicks(state, totesAndLoosePicks)
  );
  const selectedToteIds = useAppSelector(getSelectedToteIds);
  // show "View Totes" button only if one order is selected
  const singleSelectedTote = useAppSelector((state) =>
    singleSelectedToteOrLoosePick(state, totesAndLoosePicks)
  );
  const unstagedTotes = useMemo(
    () =>
      totesAndLoosePicks.filter(
        (tote) =>
          tote.status.toLowerCase() === "picked" ||
          tote.status.toLowerCase() === "dropped"
      ),
    [totesAndLoosePicks]
  );

  const toteLabels = useMemo(
    () =>
      totes &&
      totes
        .slice()
        .sort((a, b) => (a.totePosition || 0) - (b.totePosition || 0))
        .map((tote) => {
          const pickForTote = batch?.picks.find(
            (pick) => pick.orderId === tote.orderId
          );
          return {
            orderId: tote.orderId,
            toteId: tote.toteId,
            externalOrderId: tote.externalOrderId || "",
            firstName: pickForTote?.firstName || "",
            lastName: pickForTote?.lastName || "",
            orderWindow: formatDateTime(
              tote.pickingEndTime,
              fulfillmentCenter?.timeZone
            ),
            position: tote.totePosition || 0,
            toteNumber: tote.toteNumber,
            zone: tote.temperatureZone,
            batchName: batch?.batchName || "",
            putwallPosition: `${tote.putWallIdentifier}${tote.putWallLaneIdentifier}`,
            orderType: tote.orderType
          };
        }),
    [batch?.batchName, batch?.picks, fulfillmentCenter?.timeZone, totes]
  );

  const printComponentRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePagePrint = useCallback(
    useReactToPrint({
      pageStyle: `
        @page {
          margin: 0
        }
      `,
      contentRef: printComponentRef
    }),
    [printComponentRef]
  );

  const handleBrowserPrint = useCallback(() => {
    if (!toteLabels) {
      return;
    }

    const labelsZPL = toteLabels.map((label) => {
      const {
        zone,
        position = "",
        toteId,
        firstName,
        lastName,
        externalOrderId,
        putwallPosition,
        orderType
      } = label;
      return toteLabelZPL({
        zone,
        cartPosition: `${position}`,
        toteId,
        putwallPosition,
        customerName: `${firstName || ""} ${lastName || ""}`,
        orderType,
        externalOrderId
      });
    });
    if (labelsZPL) {
      print(labelsZPL.join());
    }
  }, [toteLabels, print]);

  const handlePrint = useCallback(() => {
    if (printerConfiguration === "BrowserPrint") {
      handleBrowserPrint();
    } else if (printerConfiguration === "PagePrint") {
      handlePagePrint();
    }
  }, [handleBrowserPrint, handlePagePrint, printerConfiguration]);

  useEffect(() => {
    setMenuItems([
      {
        textContent: t("select all"),
        actionCb: () => {
          dispatch(
            selectToteIds(
              unstagedTotes.map((tote) => getToteOrLoosePickId(tote))
            )
          );
        }
      },
      {
        textContent: t("print labels"),
        actionCb: () => (handlePrint ? handlePrint() : null)
      }
    ]);
  }, [dispatch, handlePrint, setMenuItems, t, unstagedTotes]);

  // component did unmount
  useEffect(
    () => () => {
      dispatch(clearSelectedToteIds());
    },
    [dispatch]
  );

  const [completeModalIsOpen, setCompleteModalIsOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      !isTotesFetching &&
      !isLoosePicksFetching &&
      totesAndLoosePicks.length > 0 &&
      totesAndLoosePicks.filter((toteOrLoosePick) =>
        ["picked", "dropped"].includes(toteOrLoosePick.status.toLowerCase())
      ).length === 0
    ) {
      setCompleteModalIsOpen(true);
    }
  }, [isTotesFetching, isLoosePicksFetching, totesAndLoosePicks]);

  // combine tote, order, and areaIds

  const handleStageTotes = useCallback(async () => {
    try {
      if (!selectedTotesAndLoosePicks.length) return;
      const putWallIdsAndPutWallLaneIds = selectedTotesAndLoosePicks.map(
        (loosePick) => {
          return {
            putWallId: loosePick.putWallId,
            putWallLaneId: loosePick.putWallLaneId
          };
        }
      );
      const uniquePutWallIdsAndPutWallLaneIds =
        putWallIdsAndPutWallLaneIds.filter(
          (putWallIdAndPutWallLaneId, index) => {
            return (
              index ===
              putWallIdsAndPutWallLaneIds.findIndex(
                (putWall) =>
                  putWallIdAndPutWallLaneId.putWallId === putWall.putWallId &&
                  putWallIdAndPutWallLaneId.putWallLaneId ===
                    putWall.putWallLaneId
              )
            );
          }
        );

      for (const putWall of uniquePutWallIdsAndPutWallLaneIds) {
        if (putWall.putWallId && putWall.putWallLaneId) {
          const loosePickIds = selectedTotesAndLoosePicks
            .filter(
              (selectedToteAndLoosePick) =>
                selectedToteAndLoosePick.type.toLowerCase() === "loosepick" &&
                selectedToteAndLoosePick.putWallId === putWall.putWallId &&
                selectedToteAndLoosePick.putWallLaneId === putWall.putWallLaneId
            )
            .map((loosePick) => loosePick.toteOrLoosePickId);
          const toteIds = selectedTotesAndLoosePicks
            .filter(
              (selectedToteAndLoosePick) =>
                selectedToteAndLoosePick.type.toLowerCase() === "tote" &&
                selectedToteAndLoosePick.putWallId === putWall.putWallId &&
                selectedToteAndLoosePick.putWallLaneId === putWall.putWallLaneId
            )
            .map((tote) => tote.toteOrLoosePickId);
          if (loosePickIds.length)
            await stageLoosePicks({
              stageLoosePicks: {
                putWallId: putWall.putWallId,
                putWallLaneId: putWall.putWallLaneId,
                loosePickIds
              }
            }).unwrap();
          if (toteIds.length)
            await stageTotes({
              putWallId: putWall.putWallId,
              putWallLaneId: putWall.putWallLaneId,
              toteIds
            }).unwrap();
        }
      }

      dispatch(clearSelectedToteIds());
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    }
  }, [
    dispatch,
    errorToast,
    selectedTotesAndLoosePicks,
    stageLoosePicks,
    stageTotes
  ]);

  useEffect(() => {
    if (selectedToteIds.length > 0) {
      setToolbar(<StageTotesToolbar handleStageTotes={handleStageTotes} />);
    } else {
      setToolbar(undefined);
    }
  }, [handleStageTotes, selectedToteIds.length, setToolbar]);

  const toggleToteSelected = (toteId: Guid) => {
    if (selectedToteIds.includes(toteId)) {
      dispatch(selectToteIds(selectedToteIds.filter((id) => id !== toteId)));
    } else {
      dispatch(selectToteIds([...selectedToteIds, toteId]));
    }
  };

  const isBulk = batch?.batchType === "Bulk";

  const [scanState, setScanState] = useScanIndicator();
  const [scannedBarcode, setScannedBarcode] = useState<string | null>(null);

  useKeyDownHandler();
  useBarcodeScanner<ToteOrLoosePick | false>({
    findScanMatch: (buffer: string) => {
      setScannedBarcode(buffer);
      setScanState("success");
      // if tote is selected, assume scan is for putwall
      if (singleSelectedTote) {
        const expectedPutwallBarcode = `${singleSelectedTote.putWallIdentifier}${singleSelectedTote.putWallLaneIdentifier}`;

        const returnValue =
          expectedPutwallBarcode.toLowerCase() === buffer.toLowerCase()
            ? singleSelectedTote
            : false;
        return returnValue;
      }

      // if no tote is selected, look for matching tote/loose-pick
      const matchingToteOrPick = unstagedTotes.find(
        (tote) =>
          getToteOrLoosePickId(tote).toLowerCase() === buffer.toLowerCase()
      );
      if (matchingToteOrPick) {
        return matchingToteOrPick;
      }
      return false;
    },
    processScanMatch: (match): boolean => {
      if (!match) return false;

      // putwall scan
      if (singleSelectedTote) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        handleStageTotes();
        return true;
      }

      // tote scan
      dispatch(selectToteIds([getToteOrLoosePickId(match)]));
      return true;
    },
    deps: [totesAndLoosePicks, singleSelectedTote]
  });

  const pickedOrDroppedTotes = totesAndLoosePicks
    .filter(
      (tote: ToteOrLoosePick) =>
        tote.status.toLowerCase() === "picked" ||
        tote.status.toLowerCase() === "dropped"
    )
    .sort(
      (a, b) =>
        ((a as ToteSummaryDto).totePosition || 0) -
        ((b as ToteSummaryDto).totePosition || 0)
    );

  const error = batchError || loosePicksError || totesError;

  return error ? (
    <ErrorPage errorMessage={getMessageFromRtkError(error)} />
  ) : (
    <Box p={2}>
      <ASTableV2
        gridTemplateColumns={isMobile ? "repeat(3, auto)" : "repeat(4, auto)"}
        isLoading={isBatchFetching}
      >
        <ASTableV2Header>
          <ASTableV2Row>
            <ASTableV2Cell>
              <Typography variant="tableBody">{t("putwall")}</Typography>
            </ASTableV2Cell>
            <ASTableV2Cell>
              <Typography variant="tableBody">{t("order")}</Typography>
            </ASTableV2Cell>
            <ASTableV2Cell>
              <Typography variant="tableBody">
                {!isBulk ? t("position") : ""}
              </Typography>
            </ASTableV2Cell>
            {!isMobile && (
              <ASTableV2Cell>
                <Typography variant="tableBody">
                  {isBulk ? t("pick status") : t("tote status")}
                </Typography>
              </ASTableV2Cell>
            )}
          </ASTableV2Row>
        </ASTableV2Header>
        <ASTableV2Body>
          {isLoosePicksFetching && (
            <ASTableV2RowSkeleton columns={isMobile ? 3 : 4} rows={4} />
          )}
          {!isBatchFetching &&
            !isLoosePicksFetching &&
            !pickedOrDroppedTotes.length && (
              <ASTableV2RowEmpty>{t("no results")}</ASTableV2RowEmpty>
            )}
          {pickedOrDroppedTotes.map((t: ToteOrLoosePick) => {
            const pickForTote = batch?.picks.find(
              (pick) =>
                (isBulk && pick.pickId === (t as LoosePick).pickId) ||
                pick.assignedToteId === (t as ToteSummaryDto).toteId
            );
            return (
              <Fragment key={getToteOrLoosePickId(t)}>
                <ASTableV2Row
                  expanded={
                    isBulk &&
                    pickForTote &&
                    selectedToteIds.includes(getToteOrLoosePickId(t))
                  }
                  selected={selectedToteIds.includes(getToteOrLoosePickId(t))}
                  onClick={() => toggleToteSelected(getToteOrLoosePickId(t))}
                >
                  <ASTableV2Cell>
                    {t.putWallIdentifier ? (
                      <Avatar
                        sx={{
                          backgroundColor: "success.main",
                          width: 50,
                          height: 50
                        }}
                      >
                        {t.putWallIdentifier} {t.putWallLaneIdentifier}
                      </Avatar>
                    ) : (
                      <></>
                    )}
                  </ASTableV2Cell>
                  <ASTableV2Cell>
                    <Typography variant="tableBody">{`${t.customerName || ""}`}</Typography>
                  </ASTableV2Cell>
                  <ASTableV2Cell sx={{ display: "flex", flexWrap: "nowrap" }}>
                    {!isBulk && fulfillmentCenter ? (
                      getType(t) === "Tote" ? (
                        <CartDisplay
                          cartCapacity={fulfillmentCenter?.cartCapacity}
                          totePosition={getPosition(t) as number}
                          loading={isLoosePicksFetching || isTotesFetching}
                          batchTotes={totes || []}
                          toteBoxSize={isMobile ? "1em" : "2.2em"}
                        />
                      ) : (
                        <Typography variant="tableBody">
                          {getPosition(t)}
                        </Typography>
                      )
                    ) : (
                      <></>
                    )}
                  </ASTableV2Cell>
                  {!isMobile ? (
                    <ASTableV2Cell>
                      <TinyIconGenerator
                        tempZone={
                          t.temperatureZone.toLowerCase() as
                            | "ambient"
                            | "chilled"
                            | "frozen"
                        }
                        type={getType(t)}
                        key={getToteOrLoosePickId(t) || ""}
                        status={t.status}
                      />
                    </ASTableV2Cell>
                  ) : (
                    <></>
                  )}
                </ASTableV2Row>
                {isBulk && pickForTote && (
                  <ASTableV2RowCollapse
                    selected={selectedToteIds.includes(getToteOrLoosePickId(t))}
                    expanded={selectedToteIds.includes(getToteOrLoosePickId(t))}
                  >
                    <Stack
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <PickStatusIcon
                        status={pickForTote.status}
                        exception={
                          pickForTote.exception?.type.toLowerCase() as
                            | "outofstock"
                            | undefined
                        }
                      />
                      <Typography
                        variant="tableBody"
                        style={{ paddingLeft: 8 }}
                      >
                        {`${pickForTote?.name} x ${pickForTote?.quantity.value}`}
                      </Typography>
                    </Stack>
                  </ASTableV2RowCollapse>
                )}
              </Fragment>
            );
          })}
        </ASTableV2Body>
      </ASTableV2>
      <CompletionModal isOpen={completeModalIsOpen} />
      {printerConfiguration && printerConfiguration === "PagePrint" && (
        <div style={{ display: "none" }}>
          {!!toteLabels?.length && (
            <PrintableToteLabels labels={toteLabels} ref={printComponentRef} />
          )}
        </div>
      )}
      <ScanningIndicator
        scanState={scanState}
        scannedBarcode={scannedBarcode}
        placeholderText={
          singleSelectedTote ? "Scan Putwall" : "Scan Tote or Loose Pick"
        }
      />
    </Box>
  );
};
