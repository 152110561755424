import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type MovementTaskState = "MovingProduct" | "ConfirmingMovement";

type BinMaintenanceWorkstationState = {
  movementTaskState: MovementTaskState;
  isBinReconfigurationModalOpen: boolean;
};

const initialState: BinMaintenanceWorkstationState = {
  movementTaskState: "MovingProduct",
  isBinReconfigurationModalOpen: false
};

export const binMaintenanceWorkstationSlice = createSlice({
  name: "binMaintenanceWorkstation",
  initialState,
  reducers: {
    setTaskState(state, { payload }: PayloadAction<MovementTaskState>) {
      state.movementTaskState = payload;
    },
    toggleBinReconfigurationModal(state) {
      state.isBinReconfigurationModalOpen =
        !state.isBinReconfigurationModalOpen;
    }
  }
});
export const { setTaskState, toggleBinReconfigurationModal } =
  binMaintenanceWorkstationSlice.actions;
