import { Typography, Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import { PickStatusIcon } from "@qubit/autoparts";
import { Dispatch, SetStateAction } from "react";

import { PickDto } from "~/types/api";

export function OrderItemRow(props: {
  productName: string;
  productQty: number;
  itemId: Guid;
  isSelected?: boolean;
  setSelected?: Dispatch<SetStateAction<string | null>>;
  pick?: PickDto;
}) {
  const {
    productName,
    productQty,
    isSelected = false,
    itemId,
    setSelected,
    pick
  } = props;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <Box
      key={itemId}
      display="flex"
      justifyContent="space-between"
      onClick={() =>
        setSelected ? setSelected(isSelected ? null : itemId) : null
      }
      padding="6px 0"
      sx={{
        borderColor: isSelected ? "#2260D3" : "transparent",
        borderWidth: "3px",
        borderStyle: "solid"
      }}
    >
      <Box display="flex" alignItems="center">
        {pick && (
          <PickStatusIcon
            status={pick.status}
            exception={
              pick.exception?.type.toLowerCase() as "outofstock" | undefined
            }
          />
        )}
        <Avatar
          sx={{
            backgroundColor: "success.main",
            height: 28,
            width: 28,
            fontSize: 16
          }}
        >
          {productQty}
        </Avatar>
        <Typography variant="body2" style={{ paddingLeft: 8 }}>
          {`${productName}`}
        </Typography>
      </Box>
    </Box>
  );
}

export default OrderItemRow;
