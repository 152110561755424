import { publicApi as api } from "./publicApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postOrdersCreate: build.mutation<
      PostOrdersCreateApiResponse,
      PostOrdersCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/orders/create`,
        method: "POST",
        body: queryArg.createOrder
      })
    }),
    getOrdersByOrderId: build.query<
      GetOrdersByOrderIdApiResponse,
      GetOrdersByOrderIdApiArg
    >({
      query: (queryArg) => ({ url: `/orders/${queryArg.orderId}` })
    }),
    postOrdersByOrderIdCancel: build.mutation<
      PostOrdersByOrderIdCancelApiResponse,
      PostOrdersByOrderIdCancelApiArg
    >({
      query: (queryArg) => ({
        url: `/orders/${queryArg.orderId}/cancel`,
        method: "POST"
      })
    }),
    getOrdersByOrderIdFulfillmentStatus: build.query<
      GetOrdersByOrderIdFulfillmentStatusApiResponse,
      GetOrdersByOrderIdFulfillmentStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/orders/${queryArg.orderId}/fulfillment-status`
      })
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as ordersApi };
export type PostOrdersCreateApiResponse =
  /** status 202 Accepted */ CreateOrderResponse;
export type PostOrdersCreateApiArg = {
  createOrder: CreateOrder;
};
export type GetOrdersByOrderIdApiResponse = /** status 200 OK */ Order;
export type GetOrdersByOrderIdApiArg = {
  /** The Order ID generated and supplied by AutoStore */
  orderId: string;
};
export type PostOrdersByOrderIdCancelApiResponse = unknown;
export type PostOrdersByOrderIdCancelApiArg = {
  /** The Order ID generated and supplied by AutoStore */
  orderId: string;
};
export type GetOrdersByOrderIdFulfillmentStatusApiResponse =
  /** status 200 Order fulfillment status retrieved successfully */ OrderFulfillmentState;
export type GetOrdersByOrderIdFulfillmentStatusApiArg = {
  /** The ID of the order to retrieve the fulfillment status for. */
  orderId: string;
};
export type CreateOrderResponse = {
  /** Unique identifier for the order */
  orderId?: string;
};
export type ProblemDetails = {
  /** A URI reference that identifies the problem type */
  type?: string | null;
  /** A short, human-readable summary of the problem type */
  title: string;
  /** A human-readable explanation specific to this occurrence of the problem */
  detail?: string | null;
  /** A URI reference that identifies the specific occurrence of the problem */
  instance?: string | null;
};
export type OrderLineQuantity = {
  /** Units for the quantity ordered. Typically the same as the SaleUnit */
  unit: string;
  /** How many units the customer ordered */
  value: number;
};
export type OrderLineTote = {
  /** The Tote identifier */
  id: string;
  /** Layer in which to package the product. Determines priority of picking */
  priority?: number | null;
};
export type OrderLineAcceptableSubstitutions = {
  sku: string;
  quantity: {
    unit: string;
    value: number;
  };
};
export type OrderLine = {
  /** SKU identifier for this product */
  sku: string;
  /** Identifier for the order line. This is client facing */
  externalLineItemId: string;
  /** Customer notes on the order line */
  instructions?: string | null;
  /** True if this product requires prep */
  madeToOrder?: boolean;
  quantity: OrderLineQuantity;
  tote?: OrderLineTote;
  /** List of acceptable substitutions for this line item */
  acceptableSubstitutions?: OrderLineAcceptableSubstitutions[] | null;
};
export type CreateOrder = {
  /** AutoStore's primary identifier for the order. If an identifier is sent, matches an existing order, and that order has not been batched, the order will be updated with the data supplied in the request. */
  id?: string | null;
  /** Client system's primary identifier for the order. Identifiers cannot be reused. If the identifier sent matches an existing order and that order has not been batched, the order will be updated with the data supplied in the request. */
  externalOrderId: string;
  /** AutoStore's primary identifier for the fulfillment center where the order should be fulfilled. */
  fulfillmentCenterId: string;
  /** Label to distinguish the order types. Used during batching to group orders or time-box batching. */
  orderType: "Fresh" | "Shop";
  /** Express orders will be prioritized above standard orders during batching and picking. Defaults to Standard. */
  priority?: ("Standard" | "Express") | null;
  /** The date/time with relative UTC offset representing when the order was created. */
  createdDate?: string | null;
  /** First and last name of the customer, for displaying to Ops users */
  customerName?: string | null;
  /** This property is obsolete and no longer in use. */
  deliveryChannel?: string | null;
  /** The date/time with relative UTC offset representing when all picking should be complete for the order. Used for prioritization during batching and picking. */
  pickingEndTime: string;
  /** The date/time with relative UTC offset representing when picking can begin. Must be before the Picking End Time. */
  earliestPickingStartTime?: string | null;
  /** When the customer should receive the order. Note that the time zone offsets (-04:00 in this example) must match the FC time zone offset, including across Daylight Saving Time shifts. This field is deprecated. Please use PickingEndTime. */
  orderWindow?: string[] | null;
  /** Indicates whether or not the customer has enabled or disabled substitutions on this order. Use 'ForbidSubstitutes' or leave empty. */
  substitutionPreference?: (null | "ForbidSubstitutes") | null;
  /** Text field the customer entered with any notes */
  specialRequest?: string | null;
  /** List of line items */
  lineItems: OrderLine[];
};
export type Order = {
  /** Primary identifier for the order. This is the identifier used internally to AutoStore. This can be optionally provided at request time. Otherwise an orderId will be created. */
  id?: string;
  /** Order identifier from the client ecommerce system */
  externalOrderId?: string;
  /** Identifier of the MFC */
  fulfillmentCenterId?: string;
  /** label to distinguish the order types. */
  orderType?: string;
  /** Express orders will be prioritized above standard orders during waving and batching. This is relevant for picking, as express orders will be made available for picking sooner. */
  priority?: string;
  /** When the order was created. Note that the time zone offset (-04:00 in this example) must match the FC time zone offset, including across Daylight Saving Time shifts */
  createdDate?: string;
  /** First and last name of the customer, for displaying to Ops users */
  customerName?: string;
  /** This property is obsolete and no longer in use. Defaults to Delivery. */
  deliveryChannel?: string;
  /** Represents a logical picking “deadline”, i.e., the time when all picking should be complete */
  pickingEndTime?: string;
  /** If present, picking will only begin after this time. Must be before the Picking End Time (PET). */
  earliestPickingStartTime?: string | null;
  /** When the customer should receive the order. Note that the time zone offsets (-04:00 in this example) must match the FC time zone offset, including across Daylight Saving Time shifts. This field is deprecated. Please use PickingEndTime. */
  orderWindow?: string[];
  /** If the customer has enabled or disabled substitutions on this order. Acceptable values include: ForbidSubstitutes */
  substitutionPreference?: string;
  /** Text field the customer entered with any notes */
  specialRequest?: string | null;
  /** The status of the order. One of Received, Batched, Stalled, Unstalled, Canceled, or Picked. */
  status?: string;
  /** List of line items */
  lineItems?: OrderLine[];
};
export type OrderLineSub = {
  OrderLineSubstitutionIds?: string[];
  ProductId?: string;
  Sku?: string;
  quantity?: {
    unit: string;
    value: number;
  };
};
export type OrderLineProperties = {
  OrderLineIds?: string[];
  ReferenceIds?: string[];
  ProductId?: string;
  Sku?: string;
  quantity?: {
    unit: string;
    value: number;
  };
  AcceptableSubstitutions?: OrderLineSub[];
};
export type OrderProperties = {
  OrderId?: string;
  ReferenceId?: string;
  Priority?: string;
  PickingEndTime?: string;
  EarliestPickingStartTime?: string | null;
  OrderType?: string;
  OrderLines?: OrderLineProperties[];
};
export type CartonContent = {
  ProductId?: string;
  Sku?: string;
  quantity?: {
    unit: string;
    value: number;
  };
};
export type Carton = {
  ToteId?: string;
  ReferenceId?: string;
  ToteNumber?: number;
  Contents?: CartonContent[];
};
export type Pick = {
  PickId?: string;
  ProductId?: string;
  Sku?: string;
  quantity?: {
    unit: string;
    value: number;
  };
  BinId?: string;
  AutostoreGridId?: string | null;
  AutostoreBinNumber?: number | null;
  AutostoreCompartmentNumber?: number | null;
  ToteId?: string | null;
  Batch?: string;
  Status?: string;
};
export type OrderFulfillmentState = {
  Order?: OrderProperties;
  Cartons?: Carton[];
  PickingPlan?: Pick[];
};
export const {
  usePostOrdersCreateMutation,
  useGetOrdersByOrderIdQuery,
  usePostOrdersByOrderIdCancelMutation,
  useGetOrdersByOrderIdFulfillmentStatusQuery
} = injectedRtkApi;
