import Dashboard20Px from "@locaisolutions/icons/dist/icons20px/Dashboard20Px";
import { Box, ButtonBase, Stack, styled, SvgIcon } from "@mui/material";

import { useSideNav } from "~/hooks/useSideNav";
import { CornerSvg } from "~/icons/CornerSvg";

type TabButtonProps = {
  isSideNavOpen: boolean;
};

const TabButton = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== "isSideNavOpen"
})<TabButtonProps>(({ isSideNavOpen, theme }) => ({
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "10px",
  color: theme.palette.icon.main,
  padding: "10px",
  height: "100%",
  width: "48px",
  textAlign: "center",
  backgroundColor: isSideNavOpen ? theme.palette.background.default : undefined,
  marginLeft: isSideNavOpen ? "0px" : "1px"
}));

const Corner = styled(CornerSvg)({
  height: "48px",
  width: "7px",
  position: "absolute",
  top: "1px"
});

export function BurgerButton() {
  const { isSideNavOpen, setIsSideNavOpen } = useSideNav();

  return (
    <Stack
      direction="row"
      height="calc(100% + 2px)"
      justifyContent="center"
      alignItems="center"
    >
      {isSideNavOpen && (
        <Box width="1px" height="100%" color="background.default">
          <Corner
            sx={{
              left: "-1px"
            }}
          />
        </Box>
      )}
      <TabButton
        aria-label="open side navigation panel"
        onClick={(): void => {
          setIsSideNavOpen(!isSideNavOpen);
        }}
        isSideNavOpen={isSideNavOpen}
      >
        <SvgIcon
          component={Dashboard20Px}
          viewBox="0 0 16 16"
          color="secondary"
          sx={{
            height: "16px",
            width: "16px",
            display: "block",
            margin: "auto"
          }}
        />
      </TabButton>
      {isSideNavOpen && (
        <Box width="1px" height="100%" color="background.default">
          <Corner
            sx={{
              left: "52px",
              transform: "scale(-1, 1)"
            }}
          />
        </Box>
      )}
    </Stack>
  );
}
