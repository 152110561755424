import {
  Alert,
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Typography
} from "@mui/material";

import { skipToken } from "@reduxjs/toolkit/query";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { useGetAutostoreGridQuery } from "~/redux/warehouse/autostoreGrid.hooks";

export const ThisMachineInfo = () => {
  const { t } = useTranslation();
  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const siteFulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);

  const {
    data: selectedAutostoreGrid,
    error: selectedAutostoreGridError,
    refetch: refetchSelectedAutostoreGrid
  } = useGetAutostoreGridQuery(siteWorkstation?.autostoreGridId ?? skipToken);

  return (
    <Stack gap={2}>
      <Box
        display="grid"
        gridTemplateColumns="auto 1fr"
        gap={1}
        columnGap={2}
        alignItems="center"
      >
        {!!selectedAutostoreGridError && (
          <Alert
            sx={{ gridColumn: "1/-1" }}
            variant="outlined"
            severity="error"
            action={
              <Button onClick={refetchSelectedAutostoreGrid}>Retry</Button>
            }
          >
            {getMessageFromRtkError(selectedAutostoreGridError)}
          </Alert>
        )}
        <Typography variant="subtitle1" color="textSecondary">
          {t("fulfillment center")}
        </Typography>
        <Typography>{siteFulfillmentCenter?.name}</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {t("fulfillment center")} ID
        </Typography>
        <Typography>{siteFulfillmentCenter?.fulfillmentCenterId}</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {t("autostore grid")}
        </Typography>
        <Typography>{selectedAutostoreGrid?.autostoreGridName}</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {t("temperature zone")}
        </Typography>
        <Typography>{selectedAutostoreGrid?.temperatureZone}</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {t("workstation name")}
        </Typography>
        <Typography>{siteWorkstation?.deviceId}</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {t("workstation mode")}
        </Typography>
        <Typography>{siteWorkstation?.mode}</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {t("workstation orientation")}
        </Typography>
        <Typography>{siteWorkstation?.orientation}</Typography>
      </Box>
      <Divider />
      <Typography variant="h6">{t("ports")}</Typography>
      <Stack direction="row" gap={1}>
        {siteWorkstation?.ports.map((port) => (
          <Paper
            key={port.portId}
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              gap: 0.5,
              p: 2,
              alignItems: "center",
              columnGap: 2
            }}
          >
            <Typography variant="subtitle1" color="textSecondary">
              {t("autostore port")}
            </Typography>
            <Typography>{port.portId}</Typography>
            {port.parentPortId && (
              <>
                <Typography variant="subtitle1" color="textSecondary">
                  {t("autostore parent port")}
                </Typography>
                <Typography>{port.parentPortId}</Typography>
              </>
            )}
            {port.inductionBinConfiguration && (
              <>
                <Typography variant="subtitle1" color="textSecondary">
                  {t("bin configuration")}
                </Typography>
                <Typography>{port.inductionBinConfiguration}</Typography>
              </>
            )}
            {port.cleaningDirection &&
              port.cleaningDirection !== "No Direction" && (
                <>
                  <Typography variant="subtitle1" color="textSecondary">
                    {t("port direction")}
                  </Typography>
                  <Typography>{port.cleaningDirection}</Typography>
                </>
              )}
          </Paper>
        ))}
      </Stack>
    </Stack>
  );
};
