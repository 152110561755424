import { Card, SxProps, Theme } from "@mui/material";

type ProductCardContainerProps = {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

export function ProductCardContainer(props: ProductCardContainerProps) {
  const { children, sx } = props;
  return (
    <Card
      sx={{
        maxWidth: 368,
        minWidth: 315,
        overflow: "visible",
        ...sx
      }}
      data-testid="product-card-container"
    >
      {children}
    </Card>
  );
}
