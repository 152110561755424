import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
  MenuItem,
  DialogTitle,
  DialogActions,
  Skeleton,
  Stack
} from "@mui/material";

import { ProgressButton, RoundIconButton } from "@qubit/autoparts";
import { useTranslation } from "react-i18next";

import useAutostoreOutOfStock from "~/hooks/useAutostoreOufOfStock";
import { mixpanelTrack, mixpanelSetHoldConfirm } from "~/lib/mixpanel-tracking";

type Props = {
  handleMoveNextPick: (args: { reporter: string }) => Promise<void>;
};

function AutostoreOutOfStockDialog(props: Props) {
  const { t } = useTranslation();
  const { handleMoveNextPick } = props;

  const {
    backButtonShown,
    currentPickAmount,
    outOfStockReasonCode,
    outOfStockReasonCodes,
    ableToPickQty,
    showSkeleton,
    closeModalCallback,
    handleQuantityChange,
    handleButtonClick,
    handleOutOfStockReasonCodeChange,
    confirmOutOfStock,
    backButtonCallback
  } = useAutostoreOutOfStock({
    handleMoveNextPick
  });

  return (
    <Dialog
      key="autostore-out-of-stock-dialog"
      open
      onClose={backButtonShown ? undefined : () => closeModalCallback()}
      sx={{ padding: 0, margin: 0, fontFamily: "Roboto" }}
    >
      <DialogTitle>{t("out of stock")}</DialogTitle>
      <DialogContent>
        <Box>
          <Typography sx={{ marginBottom: 3, fontSize: "1.75em" }}>
            {showSkeleton.current ? (
              <Skeleton width="450px" animation="wave" />
            ) : (
              <>
                {`${t("how many units are you")} `}
                <Box
                  component="span"
                  display="inline"
                  sx={{ color: "success.main" }}
                >
                  {`${t("able")} `}
                </Box>
                {`${t("to pick")}?`}
              </>
            )}
          </Typography>

          <Grid container sx={{ mb: 3, width: "95%", mx: "auto" }}>
            <Grid item xs={3}>
              {showSkeleton.current ? (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="100%"
                  animation="wave"
                />
              ) : (
                <TextField
                  id="quantity"
                  label=""
                  type="text"
                  style={{ fontSize: "2em" }}
                  variant="outlined"
                  value={ableToPickQty}
                  onChange={handleQuantityChange}
                  inputProps={{
                    style: {
                      width: "100px",
                      height: "115px",
                      fontSize: "6em",
                      textAlign: "center"
                    },
                    "aria-label": "able-to-pick-quantity"
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: "6em",
                  textAlign: "center",
                  paddingLeft: 4
                }}
                id="pickAmount"
              >
                {showSkeleton.current ? (
                  <Skeleton sx={{ marginRight: "20px" }} animation="wave" />
                ) : (
                  `/  ${currentPickAmount}`
                )}
              </Typography>
            </Grid>
            <Stack spacing={2}>
              {showSkeleton.current ? (
                <Skeleton
                  variant="circular"
                  width="65px"
                  height="65px"
                  sx={{ marginBottom: "5px" }}
                  animation="wave"
                />
              ) : (
                <RoundIconButton
                  aria-label="increment-round-button"
                  onClick={() => handleButtonClick(1)}
                  data-testid="increment-button"
                  size="large"
                >
                  <ExpandLessIcon
                    sx={{ fonstSize: "27px" }}
                    data-testid="up-arrow"
                  />
                </RoundIconButton>
              )}
              {showSkeleton.current ? (
                <Skeleton
                  variant="circular"
                  width="65px"
                  height="65px"
                  animation="wave"
                />
              ) : (
                <RoundIconButton
                  aria-label="decrement-round-button"
                  onClick={() => handleButtonClick(-1)}
                  data-testid="decrement-button"
                  size="large"
                >
                  <ExpandMoreIcon
                    sx={{ fontSize: "27px" }}
                    data-testid="down-arrow"
                  />
                </RoundIconButton>
              )}
            </Stack>
          </Grid>
          <Grid
            container
            direction="column"
            style={{
              width: "100%"
            }}
          >
            <Grid item>
              <Typography sx={{ marginBottom: "15%", fontSize: "1.75em" }}>
                {showSkeleton.current ? (
                  <Skeleton animation="wave" />
                ) : (
                  `${t("why can't you pick the full amount?")}`
                )}
              </Typography>
            </Grid>
            {showSkeleton.current ? (
              <Skeleton
                variant="rectangular"
                height="50px"
                animation="wave"
                sx={{ marginBottom: "10px" }}
              />
            ) : (
              <Grid
                item
                style={{
                  width: "100%",
                  marginTop: "-50px"
                }}
              >
                <TextField
                  data-testid="out-of-stock-modal-dropdown"
                  fullWidth
                  value={outOfStockReasonCode}
                  onChange={(e) => {
                    handleOutOfStockReasonCodeChange(e.target.value);
                  }}
                  select
                  label={t("hold type")}
                  variant="outlined"
                  style={{ width: "100%", marginBottom: "50px" }}
                  inputProps={{
                    style: { fontSize: 30 },
                    "aria-label": "hold-types-dropdown"
                  }}
                >
                  {outOfStockReasonCodes.map((option, index) => (
                    <MenuItem
                      data-testid={`out-of-stock-modal-dropdown-${index}`}
                      key={option.value.toLocaleLowerCase()}
                      value={option.value.toLocaleLowerCase()}
                      style={{ padding: "1.5em 0 1.5em 0.5em" }}
                    >
                      <Typography variant="h6">{option.text}</Typography>
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
          </Grid>
          <DialogActions sx={{ justifyContent: "center" }}>
            {backButtonShown && (
              <ProgressButton
                onClick={() => {
                  mixpanelTrack({
                    trackedPageName: "Out of Stock Modal",
                    type: "Button Click",
                    label: "Back"
                  });
                  backButtonCallback();
                }}
                buttonSize="medium"
                color="primary"
                disabled={showSkeleton.current}
              >
                {t("back")}
              </ProgressButton>
            )}

            <ProgressButton
              data-testid="out-of-stock-confirm-button"
              onClick={() => {
                mixpanelSetHoldConfirm(
                  "Out of Stock Modal",
                  "Button Click",
                  "Confirm",
                  outOfStockReasonCode
                );
                // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                confirmOutOfStock();
              }}
              buttonSize="medium"
              color="primary"
              responsive
              fullWidth
              disabled={showSkeleton.current}
              hideProgress
            >
              {t("confirm")}
            </ProgressButton>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
export default AutostoreOutOfStockDialog;
