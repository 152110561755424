import { warehouseApi as api } from "./warehouseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUnifyPortErrors: build.query<
      GetUnifyPortErrorsApiResponse,
      GetUnifyPortErrorsApiArg
    >({
      query: (queryArg) => ({
        url: `/unify/port-errors`,
        params: {
          autostoreGridId: queryArg.autostoreGridId,
          workstationId: queryArg.workstationId,
          limit: queryArg.limit
        }
      })
    }),
    getUnifyRobotErrors: build.query<
      GetUnifyRobotErrorsApiResponse,
      GetUnifyRobotErrorsApiArg
    >({
      query: (queryArg) => ({
        url: `/unify/robot-errors`,
        params: {
          autostoreGridId: queryArg.autostoreGridId,
          workstationId: queryArg.workstationId,
          limit: queryArg.limit
        }
      })
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as unifyApi };
export type GetUnifyPortErrorsApiResponse =
  /** status 200 OK */ UnifyPortEvents;
export type GetUnifyPortErrorsApiArg = {
  autostoreGridId: string;
  workstationId?: string;
  /** Maximum number of errors to return.  Default is 200. */
  limit: number | null;
};
export type GetUnifyRobotErrorsApiResponse =
  /** status 200 OK */ UnifyRobotEvents;
export type GetUnifyRobotErrorsApiArg = {
  autostoreGridId: string;
  workstationId?: string;
  /** Maximum number of errors to return.  Default is 200. */
  limit: number | null;
};
export type UnifyEventPortErrorV1 = {
  case: "PortErrorV1";
  item: {
    gridId: string;
    multiPortId?: number | null;
    portError: string;
    portErrorCode: number;
    portId: number;
    portState: string;
    portType: string;
    status?: string | null;
    timestamp: string;
  };
};
export type UnifyPortEvents = UnifyEventPortErrorV1[];
export type UnifyEventRobotErrorV1 = {
  case: "RobotErrorV1";
  item: {
    gridId: string;
    robotId: number;
    robotError?: string;
    robotErrorCode?: number | null;
    positionX?: number | null;
    positionY?: number | null;
    robotHasBin?: boolean | null;
    portId?: number | null;
    binId?: number | null;
    robotInPort?: boolean | null;
    robotType?: string;
    status?: string | null;
    timestamp: string;
  };
};
export type UnifyRobotEvents = UnifyEventRobotErrorV1[];
export const { useGetUnifyPortErrorsQuery, useGetUnifyRobotErrorsQuery } =
  injectedRtkApi;
