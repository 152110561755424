import WarningIcon from "@mui/icons-material/Warning";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import MultiPort from "~/features/autostoreBin/MultiPort";
import { getFirstDefinedValue } from "~/lib/helpers";

import { GetPortResponse } from "~/redux/actions";
import { NextEmptyBinResponse, WorkstationDto } from "~/types/api";

type Props = {
  isOpen: boolean;
  onClose: (isChangeSuggestedBinModalOpen: boolean) => void;
  onConfirm: (bin: NextEmptyBinResponse, compartment: number) => void;
  /** adds a "Cancel" button to the bottom of modal, and disables onClose from firing by clicking out of the modal */
  onCancel?: () => void;
  cancelDisabled?: boolean;
  workstation: WorkstationDto | null;
  currentSelectedBin: NextEmptyBinResponse | null;
  currentSelectedCompartment: number | null;
  nextEmptyBinByPort: {
    [portId: number]: NextEmptyBinResponse | null;
  };
  portStateByPort: {
    [portId: number]: {
      getPortResponse: GetPortResponse;
      timestamp: Date;
    };
  };
  showInitialLoading?: boolean;
  newSelectedCompartment?: number | null;
  setNewSelectedCompartment?: (compartment: number | null) => void;
  suggestBinConfigurationErrorMessage?: string | null;
  allowSelectingCompartmentsWithoutInventoryOnly?: boolean;
  /** The title in the header of the modal */
  modalTitle?: string;
  /** If true will show compartment selection. If false will only show bin outline. */
  allowCompartmentSelection?: boolean;
};

function ChangeSuggestedBinModal(props: Props) {
  const {
    isOpen,
    onClose,
    nextEmptyBinByPort,
    portStateByPort,
    workstation,
    currentSelectedBin,
    currentSelectedCompartment,
    onConfirm,
    showInitialLoading = false,
    newSelectedCompartment: newSelectedCompartmentFromParent,
    setNewSelectedCompartment: setNewSelectedCompartmentFromParent,
    onCancel,
    cancelDisabled,
    suggestBinConfigurationErrorMessage,
    allowSelectingCompartmentsWithoutInventoryOnly = true,
    modalTitle,
    allowCompartmentSelection = true
  } = props;
  const { t } = useTranslation();
  const [newSelectedBin, setNewSelectedBin] =
    useState<NextEmptyBinResponse | null>(null);
  const [newSelectedCompartmentFromState, setNewSelectedCompartmentFromState] =
    useState<number | null>(null);

  // Induction V1 and V2 need the new compartment state in the parent component
  // while Inventory V2 uses the state in this component
  const newSelectedCompartment =
    newSelectedCompartmentFromParent ?? newSelectedCompartmentFromState;
  const setNewSelectedCompartment =
    setNewSelectedCompartmentFromParent || setNewSelectedCompartmentFromState;

  const getIsConfirmButtonDisabled = () => {
    const newSelectionHasBeenMade =
      newSelectedBin &&
      (newSelectedCompartment || newSelectedCompartment === 0);
    const isOriginalBinSelected =
      newSelectedBin?.openBinResponse.binId ===
      currentSelectedBin?.openBinResponse.binId;
    const isOriginalCompartmentSelected =
      newSelectedCompartment === currentSelectedCompartment;
    if (allowCompartmentSelection) {
      return (
        !newSelectionHasBeenMade ||
        (isOriginalBinSelected && isOriginalCompartmentSelected)
      );
    } else {
      return !newSelectedBin || isOriginalBinSelected;
    }
  };

  const warrningMessage = suggestBinConfigurationErrorMessage && (
    <Alert
      sx={{ m: 1 }}
      severity="warning"
      icon={<WarningIcon sx={{ marginY: "auto" }} />}
      variant="filled"
    >
      {suggestBinConfigurationErrorMessage}
    </Alert>
  );

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setNewSelectedBin(null);
        setNewSelectedCompartment(null);
        if (!onCancel) onClose(false);
      }}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle>{modalTitle || t("select new compartment")}</DialogTitle>
      <DialogContent sx={{ margin: "30px 30px 0" }}>
        <Grid container direction="column" alignItems="center">
          {warrningMessage}
          <MultiPort
            nextEmptyBinByPort={nextEmptyBinByPort}
            portStateByPort={portStateByPort}
            workstation={workstation}
            onBinClick={(compartment: number | undefined) => {
              if (compartment || compartment === 0)
                setNewSelectedCompartment(compartment);
            }}
            setSelectedBinCallback={(bin: NextEmptyBinResponse) =>
              setNewSelectedBin(bin)
            }
            selectedCompartment={
              allowCompartmentSelection
                ? getFirstDefinedValue(
                    [newSelectedCompartment, currentSelectedCompartment],
                    undefined
                  )
                : undefined
            }
            currentSelectedBinId={getFirstDefinedValue(
              [
                newSelectedBin?.openBinResponse.binId,
                currentSelectedBin?.openBinResponse.binId
              ],
              undefined
            )}
            allowSelectingCompartmentsWithoutInventoryOnly={
              allowSelectingCompartmentsWithoutInventoryOnly
            }
            showLoading={showInitialLoading}
          />
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", padding: "30px" }}>
        {onCancel && (
          <Button
            size="large"
            variant="contained"
            color="error"
            onClick={() => {
              onCancel();
            }}
            data-testid="cancel-bin-inv"
            disabled={cancelDisabled}
            sx={{
              fontWeight: "normal"
            }}
          >
            {t("cancel")}
          </Button>
        )}
        <Button
          size="large"
          variant="contained"
          disabled={getIsConfirmButtonDisabled()}
          onClick={() => {
            const selectedBin = newSelectedBin || currentSelectedBin;
            const selectedCompartment = getFirstDefinedValue(
              [newSelectedCompartment, currentSelectedCompartment],
              null
            );
            if (selectedBin && selectedCompartment !== null) {
              onConfirm(selectedBin, selectedCompartment);
              setNewSelectedBin(null);
            }
            onClose(false);
          }}
          data-testid="confirm-bin"
          sx={{
            fontWeight: "normal"
          }}
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ChangeSuggestedBinModal;
