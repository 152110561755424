import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { AutostoreBin } from "~/features/autostoreBin";

import { AlertInfo } from "./AlertInfo";

export function EscalateModal({
  open,
  onClose,
  onRelease
}: {
  open: boolean;
  onClose: () => void;
  onRelease: () => Promise<void>;
}) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} maxWidth="lg" onClose={onClose}>
      <DialogTitle>{t("bin escalation")}</DialogTitle>
      <DialogContent sx={{ margin: "30px 30px 0" }}>
        <Stack direction="column" spacing={2} alignItems="center">
          <AlertInfo
            message={t(
              "please remove all bin dividers in order to escalate the bin"
            )}
          />
          <AutostoreBin
            state="Bin Opened"
            pickQuantity={0}
            pickCompartment={null}
            numberOfRows={1}
            numberOfColumns={1}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button variant="subtle" size="large" onClick={onClose}>
          {t("cancel")}
        </Button>
        <Button size="large" onClick={onRelease}>
          {t("release bin")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
