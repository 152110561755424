import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { stringify } from "qs";

import { getUserClientId } from "~/api/usersTypes/auth0Profile";
import { getWarehouseServiceUrl } from "~/api/warehouse";

import {
  accessTokenFactory,
  setUnauthorizedLogoutRequested
} from "~/features/login/login.slice";
import { StoreState } from "~/redux/reducers";

import { selectUsersFulfillmentCenterId } from "~/redux/selectors/storeSelectors";

/** Add a tag to your query using 'providesTags' to take advantage of
 *  caching/invalidation. Tags must also be added here. For example, useful when
 *  you make a POST call and want the related GET call to update by itself.
 */
const tagTypes = [
  "autostore bin",
  "autostore grid",
  "autostore grid connection mode",
  "autostore grid status",
  "autostore task group",
  "available bin report",
  "api keys",
  "batch",
  "bin cleaning report",
  "bin configuration",
  "bin cubing suggestion",
  "bin location information",
  "bin maintenance",
  "bin status",
  "bins outside grid",
  "cycle count data",
  "empty bin report",
  "focused pick",
  "fulfillment center",
  "flagged bins",
  "inventory",
  "inventory by autostore bin number",
  "inventory movements",
  "inventory summary list",
  "loose pick",
  "number flagged bins",
  "order",
  "order types",
  "organization",
  "pick",
  "picking port state",
  "put away task",
  "port",
  "port status",
  "recurring schedule",
  "staging area",
  "start bin cleaning",
  "start inventory movements",
  "tote",
  "variant inventory summaries",
  "variant",
  "wave progress",
  "workstation"
] as const;

const warehouseApiBase = fetchBaseQuery({
  baseUrl: `${getWarehouseServiceUrl()}/api`,
  prepareHeaders: async (headers, api) => {
    const state = api.getState() as StoreState;
    const accessToken = await accessTokenFactory(
      state.login.accessToken,
      state.login.authMethod
    );
    headers.set("authorization", `Bearer ${accessToken}`);
  },
  paramsSerializer: (params) => stringify(params, { arrayFormat: "repeat" })
});

export const warehouseApiBaseWithRedirectOn401: typeof warehouseApiBase =
  async (args, api, extra) => {
    const state = api.getState() as StoreState;

    const argsWithDefaults =
      typeof args === "string"
        ? args
        : {
            ...args,
            params: {
              fulfillmentCenterId: selectUsersFulfillmentCenterId(state),
              clientId: state.login.profile
                ? getUserClientId(state.login.profile)
                : null,
              // we want any specified params to override the defaults above
              ...args.params
            }
          };

    const result = await warehouseApiBase(argsWithDefaults, api, extra);

    // if the server responds with a 401 response, redirect to the sign-in page
    if (
      result.error &&
      result.error.status === 401 &&
      !result.meta?.request?.url.includes("/pepsi/api/v1/user/login") &&
      !window.location.href.includes("/logout") &&
      !window.location.href.includes("/login")
    ) {
      api.dispatch(setUnauthorizedLogoutRequested(true));
    }
    return result;
  };

export const warehouseApi = createApi({
  baseQuery: warehouseApiBaseWithRedirectOn401,
  endpoints: () => ({}),
  tagTypes
});
