import { ChevronRight24Px, Plus24Px } from "@locaisolutions/icons";

import {
  Stack,
  TextField,
  IconButton,
  Paper,
  Typography,
  Button,
  useTheme,
  Collapse
} from "@mui/material";
import { RemoveIcon } from "@qubit/autoparts";
import { useState } from "react";

import {
  UseFieldArrayRemove,
  useFieldArray,
  useFormContext
} from "react-hook-form";

import { useAppSelector } from "~/app/store";

import {
  selectOrderCreationSelectedSku,
  selectOrderCreationSimplified
} from "~/redux/selectors/orderCreationSelectors";
import { ReceiveOrderDto } from "~/types/api";

import { ProductInfo } from "./ProductInfo";

export const LineItem = ({
  index,
  removeLineItem
}: {
  index: number;
  removeLineItem: UseFieldArrayRemove;
}) => {
  const { palette } = useTheme();
  const [showSubs, setShowSubs] = useState(false);
  const simplified = useAppSelector(selectOrderCreationSimplified);
  const selectedSku = useAppSelector(selectOrderCreationSelectedSku);

  const createOrderContext = useFormContext<ReceiveOrderDto>();
  const lineItem = createOrderContext.watch(`lineItems.${index}`);

  const {
    fields: acceptableSubstitutionFields,
    append: appendAcceptableSubstitution,
    remove: removeAcceptableSubstitution
  } = useFieldArray({
    control: createOrderContext.control,
    name: `lineItems.${index}.acceptableSubstitutions`
  });

  if (!lineItem) return <></>;

  return (
    <>
      <Stack direction="row" gap={2} my={2}>
        <Button
          aria-label="show substitutions"
          variant="text"
          disableRipple={true}
          onClick={() => setShowSubs(!showSubs)}
        >
          <ChevronRight24Px
            style={{
              rotate: showSubs ? "90deg" : "0deg",
              transition: "rotate 0.3s"
            }}
            fill={palette.text.primary}
          />
        </Button>
        <ProductInfo sku={lineItem.sku} />
        <TextField
          {...createOrderContext.register(`lineItems.${index}.quantity.value`, {
            required: true
          })}
          sx={{ flexBasis: "50px" }}
          variant="standard"
          label="Quantity"
          type="number"
        />
        {!simplified && (
          <TextField
            {...createOrderContext.register(
              `lineItems.${index}.externalLineItemId`,
              {
                required: true
              }
            )}
            sx={{ flexBasis: "120px" }}
            variant="standard"
            label="External Line Item Id"
          />
        )}
        <IconButton aria-label="delete" onClick={() => removeLineItem(index)}>
          <RemoveIcon />
        </IconButton>
      </Stack>
      <Collapse in={showSubs}>
        <Paper sx={{ p: 2, my: 1 }}>
          <Typography>Acceptable Substitutions</Typography>
          {acceptableSubstitutionFields.map((sub, subIndex) => (
            <Stack key={sub.id} direction="row" gap={2}>
              <ProductInfo sku={sub.sku} />
              <TextField
                {...createOrderContext.register(
                  `lineItems.${index}.acceptableSubstitutions.${subIndex}.quantity.value`,
                  {
                    required: true
                  }
                )}
                sx={{ flexBasis: "50px" }}
                variant="standard"
                type="number"
                label="Quantity"
              />
              <IconButton
                aria-label="delete"
                onClick={() => removeAcceptableSubstitution(index)}
              >
                <RemoveIcon />
              </IconButton>
            </Stack>
          ))}
          <Button
            sx={{ mt: 2 }}
            variant="outlined"
            color="primary"
            onClick={() =>
              appendAcceptableSubstitution({
                sku: selectedSku || "",
                quantity: { value: 1, unit: "ea" }
              })
            }
            aria-label={`Add Substitute Product to Line Item ${lineItem.externalLineItemId}`}
          >
            <Plus24Px
              style={{
                fill: palette.primary.main,
                marginRight: "8px"
              }}
            />{" "}
            Product
          </Button>
        </Paper>
      </Collapse>
    </>
  );
};
