import { putAwayTaskApi } from "./putAwayTasks";

export const {
  useGetPutawayTasksQuery,
  useGetPutawayTasksWithPagingQuery,
  usePatchDecantingRateMutation,
  usePostPutItemAwayMutation
} = putAwayTaskApi.enhanceEndpoints({
  endpoints: {
    postPutItemAway: {
      invalidatesTags: ["put away task", "available bin report"]
    }
  }
});
