import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { stringify } from "qs";

import { getWarehouseServiceUrl } from "~/api/warehouse";
import {
  accessTokenFactory,
  setUnauthorizedLogoutRequested
} from "~/features/login/login.slice";
import { StoreState } from "~/redux/reducers";

export const pepsiApiBase = fetchBaseQuery({
  baseUrl: `${getWarehouseServiceUrl()}/pepsi/api`,
  prepareHeaders: async (headers, api) => {
    const state = api.getState() as StoreState;
    const accessToken = await accessTokenFactory(
      state.login.accessToken,
      state.login.authMethod
    );
    headers.set("authorization", `Bearer ${accessToken}`);
  },
  paramsSerializer: (params) => stringify(params, { arrayFormat: "repeat" })
});

export const pepsiApiBaseWithRedirectOn401: typeof pepsiApiBase = async (
  args,
  api,
  extraOptions
) => {
  const result = await pepsiApiBase(args, api, extraOptions);
  if (
    result.error &&
    result.error.status === 401 &&
    !result.meta?.request?.url.includes("/pepsi/api/v1/user/login") &&
    !window.location.href.includes("/logout") &&
    !window.location.href.includes("/login")
  ) {
    api.dispatch(setUnauthorizedLogoutRequested(true));
  }
  return result;
};

export const pepsiApi = createApi({
  baseQuery: pepsiApiBaseWithRedirectOn401,
  endpoints: () => ({}),
  reducerPath: "pepsiApi"
});
