import { Components, Theme } from "@mui/material";

export const muiTableRow: Components<Omit<Theme, "components">> = {
  MuiTableRow: {
    styleOverrides: {
      root: {
        "& > td": {
          borderColor: "transparent",
          borderTopWidth: "3px",
          borderBottomWidth: "3px",
          borderStyle: "solid"
        },

        "&.Mui-selected > td": {
          background: "#EFF3FE",
          borderColor: "#2260D3"
        },
        "& > td:first-of-type": {
          borderLeftWidth: "3px"
        },
        "& > td:last-of-type": {
          borderRightWidth: "3px"
        }
      },
      head: {
        "& > th": { border: 0 }
      }
    }
  }
};
