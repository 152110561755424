import { Components, Theme } from "@mui/material";

export const muiTable: Components<Omit<Theme, "components">> = {
  MuiTable: {
    styleOverrides: {
      root: {
        borderSpacing: "0 6px",
        borderCollapse: "separate",
        padding: "0 6px",
        background: "#f0f0f0",
        borderRadius: "10px"
      }
    }
  }
};
