import { Components, Theme } from "@mui/material";

export const muiTableCell: Components<Omit<Theme, "components">> = {
  MuiTableCell: {
    styleOverrides: {
      root: {
        lineHeight: "1.25rem",
        fontSize: "1.25rem",
        fontWeight: 400
      },
      body: {
        background: "white",
        border: 0,
        "&:first-of-type": {
          borderTopLeftRadius: "10px",
          borderBottomLeftRadius: "10px"
        },
        "&:last-of-type": {
          borderTopRightRadius: "10px",
          borderBottomRightRadius: "10px"
        }
      },
      head: {
        backgroundColor: "#f0f0f0",
        padding: "0 16px",
        color: "rgba(0,0,0,0.65)"
      }
    }
  }
};
