import { Box, SxProps, Typography } from "@mui/material";
import { useState } from "react";

const trimmedSkuStyle: SxProps = {
  textOverflow: "ellipsis",
  overflow: "hidden",
  cursor: "pointer",
  maxHeight: "2rem",
  transition: "max-height 1s ease"
};
const expandedSkuStyle: SxProps = {
  wordBreak: "break-all",
  cursor: "pointer",
  maxHeight: "10rem",
  transition: "max-height 1s ease"
};

type Props = {
  sku: string;
};

/**
 * Renders a SKU on a grey background.  Intended for use as a child of UniversalProductCard.
 */
export function Sku({ sku }: Props) {
  const [isSkuCollapsed, setIsSkuCollapsed] = useState(true);

  if (!sku) {
    return <></>;
  }

  return (
    <Box mx={-2} bgcolor="gray.light" px={2} py={2}>
      <Typography
        variant="h6"
        component="h6"
        fontSize="1rem"
        sx={isSkuCollapsed ? trimmedSkuStyle : expandedSkuStyle}
        onClick={() => setIsSkuCollapsed(!isSkuCollapsed)}
        style={{ fontWeight: 400 }}
        role="button"
        tabIndex={0}
        aria-expanded={!isSkuCollapsed}
        aria-label={`SKU: ${sku}. Click to ${isSkuCollapsed ? "expand" : "collapse"}`}
        onKeyPress={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            setIsSkuCollapsed(!isSkuCollapsed);
          }
        }}
      >
        {`SKU: ${sku}`}
      </Typography>
    </Box>
  );
}
