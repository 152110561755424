import { Alert, Button, FormControl, FormLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Skeleton from "@mui/material/Skeleton";
import { useToast } from "@qubit/autoparts";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { clearAndonWorkstationsAndGrids } from "~/features/andon/andon.slice";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { updateUsersFulfillmentCenter } from "~/redux/actions/store";
import { setSiteFcId, setThisWorkstation } from "~/redux/actions/workstations";
import { useGetAllFulfillmentCentersQuery } from "~/redux/warehouse/fulfillmentCenter.hooks";

import { warehouseApi } from "~/redux/warehouse/warehouseApi";

export const FulfillmentCenterSelector = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { successToast, errorToast } = useToast();
  const fulfillmentCenterId = useAppSelector((state) =>
    state.workstations.siteFulfillmentCenterId
      ? state.workstations.siteFulfillmentCenterId
      : state.store.usersFulfillmentCenter
        ? state.store.usersFulfillmentCenter.fulfillmentCenterId
        : null
  );
  const {
    isFetching: fulfillmentCentersFetching,
    data: fulfillmentCenters,
    error: fulfillmentCentersFetchError,
    refetch: refetchFulfillmentCenters
  } = useGetAllFulfillmentCentersQuery();

  const handleSelectionSelected = async (fcId: string) => {
    try {
      // redux state, probably belongs in reducer?
      dispatch(setSiteFcId(fcId));
      dispatch(clearAndonWorkstationsAndGrids());
      await dispatch(setThisWorkstation(null, true));
      await dispatch(updateUsersFulfillmentCenter(fcId));

      dispatch(warehouseApi.util.invalidateTags(["autostore grid"]));
      // toast
      const selectedFc = fulfillmentCenters
        ? fulfillmentCenters.find((fc) => fc.fulfillmentCenterId === fcId)
        : null;

      const successMessage: string = selectedFc
        ? `Your Device's Fulfillment Center has been updated to ${selectedFc.name}`
        : // odd case where we couldn't actually find the fulfillment center
          "Your Device's Fulfillment Center has been updated";

      successToast("Fulfillment Center Updated", {
        description: successMessage
      });
    } catch (err) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  if (fulfillmentCentersFetching) {
    return (
      <Grid item xs={6}>
        <Skeleton variant="rectangular" />
      </Grid>
    );
  }

  if (fulfillmentCentersFetchError) {
    return (
      <Grid item xs={6}>
        <Alert
          variant="outlined"
          severity="error"
          action={
            <Button onClick={refetchFulfillmentCenters}>{t("retry")}</Button>
          }
        >
          {getMessageFromRtkError(fulfillmentCentersFetchError)}
        </Alert>
      </Grid>
    );
  }

  return (
    <FormControl sx={{ maxWidth: "sm" }}>
      <FormLabel
        id="fulfillment-center-label"
        htmlFor="fulfillment-center"
        sx={{ display: "inline" }}
      >
        {`${t("site fulfillment center")}`}
      </FormLabel>

      {fulfillmentCenters?.length && (
        <Select
          variant="outlined"
          id="fulfillment-center"
          value={fulfillmentCenterId ? fulfillmentCenterId : ""}
          labelId="fulfillment-center-label"
        >
          {fulfillmentCenters.map((fc) => (
            <MenuItem
              key={fc.fulfillmentCenterId}
              value={fc.fulfillmentCenterId}
              onClick={() => handleSelectionSelected(fc.fulfillmentCenterId)}
            >
              {fc.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};
