import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ProductInfoTypography } from "./styles";

type Props = {
  brandName: string;
};

export function Brand({ brandName }: Props) {
  const { t } = useTranslation();
  return (
    <div id="brandName">
      <ProductInfoTypography variant="overline">
        {t("brand")}
      </ProductInfoTypography>
      <Typography
        gutterBottom
        variant="h6"
        component="h6"
        style={{ fontWeight: 400 }}
      >
        {brandName || "N/A"}
      </Typography>
    </div>
  );
}
