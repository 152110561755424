import {
  createTheme,
  PaletteColor,
  PaletteColorOptions,
  ThemeOptions
} from "@mui/material/styles";
import { SimplePaletteColorOptions } from "@mui/material/styles/createPalette";
import { createStyled } from "@mui/system";

import { basicColours, mainColours } from "./colours";
import { muiAlert } from "./muiAlert";
import { muiAlertTitle } from "./muiAlertTitle";
import { muiAppBar } from "./muiAppBar";
import { muiButton } from "./muiButton";
import { muiButtonGroup } from "./muiButtonGroup";
import { muiCard } from "./muiCard";
import { muiDialogTitle } from "./muiDialogTitle";
import { muiMenuItem } from "./muiMenuItem";
import { muiOutlinedInput } from "./muiOutlinedInput";
import { muiPaper } from "./muiPaper";
import { muiSvgIcon } from "./muiSvgIcon";
import { muiTable } from "./muiTable";
import { muiTableCell } from "./muiTableCell";
import { muiTableHead } from "./muiTableHead";
import { muiTableRow } from "./muiTableRow";
import { muiToolbar } from "./muiToolbar";

declare module "@mui/material/styles" {
  interface TypeBackground {
    hover: string;
    lightBlue: string;
    gray: string;
    secondary: string;
  }
  interface TypographyVariants {
    tableBody: React.CSSProperties;
    stat: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    tableBody?: React.CSSProperties;
    stat: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    tableBody: true;
    stat: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    serious: true;
    subtle: true;
  }
}

/**
 * There are a few ways to reference theme colors:
 *
 * Using the sx prop on MUI elements provides access to the theme (colors, spacing, etc)
 * @example
 * <Box
 *     sx={{
 *       width: 300,
 *       height: 300,
 *       backgroundColor: 'primary.dark',
 *       '&:hover': {
 *         backgroundColor: 'primary.main',
 *         opacity: [0.9, 0.8, 0.7],
 *       },
 *     }}
 *   />
 *
 * For inline element styling, use the theme hook
 * @example
 * import { useTheme } from "@mui/material/styles";
 * const { palette } = useTheme();
 *
 * <Element style={{ color: palette.success.dark }} />
 *
 * When using the `styled` api,
 * we have access to the autostoreTheme defined below via theme.palette[option][variant]
 * @example
 * import { styled } from "@mui/material/styles";
 *
 * const CustomizedSlider = styled(Slider)(
 *  ({ theme }) => `
 *    color: ${theme.palette.primary.main};
 *
 *    :hover {
 *      color: ${darken(theme.palette.primary.main, 0.2)};
 *    }
 * );
 */

export const phoneWidth = 600;
export const tabletPortraitWidth = 768;

export const andonColors: { [key: string]: string } = {
  openGreen: "#6dae45",
  handRaisedYellow: "#fdc101",
  portFaultAmber: "#f07b35",
  gridDownPurple: mainColours.mode.goodsIn,
  safetyStopRed: "#bf0001",
  workstationActiveTeal: "#7e7e7e"
};
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    tablet: true; // adds the `tablet` breakpoint
  }
  interface Palette {
    gray: PaletteColor;
    darkGray: PaletteColor;
    statusGray: PaletteColor;
    statusBlue: PaletteColor;
    statusYellow: PaletteColor;
    statusGreen: PaletteColor;
    statusRed: PaletteColor;
    autostoreRed: PaletteColor;
    frozen: PaletteColor;
    chilled: PaletteColor;
    ambient: PaletteColor;
    warning: PaletteColor;
    darkBlue: PaletteColor;
    pickProgressBarGreen: PaletteColor;
    disabled: PaletteColor;
    icon: PaletteColor;
    border: SimplePaletteColorOptions;
    borderPrimary: SimplePaletteColorOptions;
  }

  interface PaletteOptions {
    gray: PaletteColorOptions;
    darkGray: PaletteColorOptions;
    statusGray: PaletteColorOptions;
    statusBlue: PaletteColorOptions;
    statusYellow: PaletteColorOptions;
    statusGreen: PaletteColorOptions;
    statusRed: PaletteColorOptions;
    autostoreRed: PaletteColorOptions;
    frozen: PaletteColorOptions;
    chilled: PaletteColorOptions;
    ambient: PaletteColorOptions;
    darkBlue: PaletteColorOptions;
    pickProgressBarGreen: PaletteColorOptions;
    disabled: PaletteColorOptions;
    icon: PaletteColorOptions;
    border: SimplePaletteColorOptions;
    borderPrimary: SimplePaletteColorOptions;
  }
}

const autostoreThemeObj: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: phoneWidth,
      tablet: tabletPortraitWidth,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  palette: {
    mode: "light",
    primary: {
      main: mainColours.background.accent.primary,
      dark: mainColours.background.accent.hover,
      light: basicColours.blue["50"]
    },
    secondary: {
      main: mainColours.background.secondary,
      dark: mainColours.background.hover,
      contrastText: mainColours.text.primary
    },
    icon: {
      main: mainColours.icon.primary
    },
    text: {
      primary: mainColours.text.primary,
      secondary: mainColours.text.secondary,
      disabled: mainColours.text.disabled
    },
    border: {
      main: mainColours.border.primary,
      light: mainColours.border.secondary
    },
    borderPrimary: {
      main: mainColours.border.accent
    },
    error: {
      main: mainColours.background.error.primary,
      dark: mainColours.background.error.hover,
      light: basicColours.red[50]
    },
    info: {
      main: mainColours.background.accent.primary,
      dark: mainColours.background.accent.hover,
      light: basicColours.blue[50]
    },
    warning: {
      main: mainColours.background.warning.primary,
      dark: mainColours.background.warning.hover,
      light: basicColours.yellow[100]
    },
    success: {
      main: mainColours.background.success.primary,
      dark: mainColours.background.success.hover,
      light: basicColours.green[50]
    },
    background: {
      default: mainColours.background.primary,
      secondary: mainColours.background.secondary,
      paper: mainColours.background.primary,
      hover: mainColours.background.hover,
      // todo: delete and use a semantic token
      lightBlue: basicColours.blue[50],
      // todo: delete and use a semantic token
      gray: basicColours.mono[50]
    },
    // custom colors:
    autostoreRed: {
      main: basicColours.red.main,
      contrastText: mainColours.text.inverse.primary
    },
    gray: {
      light: mainColours.background.secondary,
      main: "#f2f2f2",
      dark: "#e8e8e8"
    },
    darkGray: {
      dark: "#333",
      main: "#757575",
      light: "rgb(202, 202, 202)"
    },
    frozen: {
      main: mainColours.label[3],
      contrastText: basicColours.mono.black
    },
    chilled: {
      main: mainColours.label[1],
      contrastText: basicColours.mono.black
    },
    ambient: {
      main: mainColours.label[7],
      contrastText: basicColours.mono.black
    },
    statusGray: {
      main: mainColours.background.secondary,
      contrastText: basicColours.mono.black
    },
    statusBlue: {
      main: mainColours.label[1],
      contrastText: basicColours.mono.black
    },
    statusRed: {
      main: mainColours.label[6],
      contrastText: basicColours.mono.black
    },
    statusYellow: {
      main: mainColours.label[7],
      contrastText: basicColours.mono.black
    },
    statusGreen: {
      main: mainColours.label[9],
      contrastText: basicColours.mono.black
    },
    darkBlue: { main: "#2260D3" },
    pickProgressBarGreen: { main: "#189A57" },
    disabled: {
      main: "#00000014",
      dark: "#0000004D"
    }
  },
  components: {
    ...muiAlert,
    ...muiAlertTitle,
    ...muiAppBar,
    ...muiButton,
    ...muiButtonGroup,
    ...muiCard,
    ...muiDialogTitle,
    ...muiMenuItem,
    ...muiOutlinedInput,
    ...muiPaper,
    ...muiSvgIcon,
    ...muiTable,
    ...muiTableCell,
    ...muiTableHead,
    ...muiTableRow,
    ...muiToolbar
  },
  shape: {
    borderRadius: 10
  },
  typography: {
    fontFamily: 'CircularXX, "Helvetica Neue", Arial, Helvetica, sans-serif',
    tableBody: {
      fontSize: "1.25rem"
    },
    stat: {
      fontSize: "32px",
      fontFamily: 'CircularXX, "Helvetica Neue", Arial, Helvetica, sans-serif'
    },
    button: {
      textTransform: "none"
    }
  }
};

export const tabletWidth = "(max-width: 1200px)";
export const tabletPortrait = `(max-width: ${tabletPortraitWidth}px)`;
export const mobileWidth = `(max-width: ${phoneWidth}px)`;

export const autostoreTheme = createTheme(autostoreThemeObj);
export const styled = createStyled({ defaultTheme: autostoreTheme });
