import {
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps
} from "@mui/material";

import { useMemo } from "react";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { selectAutostoreGridIdOverride } from "~/redux/selectors/inventorySelectors";
import { useGetAutostoreGridsQuery } from "~/redux/warehouse/autostoreGrid.hooks";
import { AutostoreGridDto } from "~/types/api";

import { setAutostoreGridIdOverride } from "./inventory.slice";

/** Allows users without a workstation to select an Autostore Grid */
export function useGridSelector(props?: SelectProps<string | undefined>) {
  const autostoreGridOverride = useAppSelector(selectAutostoreGridIdOverride);
  const { data: autostoreGrids } = useGetAutostoreGridsQuery();
  const dispatch = useAppDispatch();

  const gridSelectorButton = useMemo(
    () => (
      <Select
        value={autostoreGridOverride || ""}
        onChange={(e: SelectChangeEvent) => {
          const selectedGrid = e.target.value;
          dispatch(setAutostoreGridIdOverride(selectedGrid || undefined));
        }}
        variant="outlined"
        size="small"
        displayEmpty
        sx={{
          minWidth: 300
        }}
        {...props}
      >
        <MenuItem key={"all-grids"} value="">
          No Grid Selected
        </MenuItem>
        {(autostoreGrids || []).map(
          ({ autostoreGridName, autostoreGridId }: AutostoreGridDto) => (
            <MenuItem key={autostoreGridId} value={autostoreGridId}>
              {autostoreGridName}
            </MenuItem>
          )
        )}
      </Select>
    ),
    [autostoreGridOverride, autostoreGrids, dispatch, props]
  );

  return { autostoreGridOverride, gridSelectorButton };
}
