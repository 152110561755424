import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "~/app/store";

const useUnauthorizedRedirect = () => {
  const navigate = useNavigate();
  const unauthorizedLogoutRequested = useAppSelector(
    (state) => state.login.unauthorizedLogoutRequested
  );

  useEffect(() => {
    if (unauthorizedLogoutRequested) {
      navigate("/logout");
    }
  }, [unauthorizedLogoutRequested, navigate]);
};

export default useUnauthorizedRedirect;
