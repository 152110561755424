import { Grid } from "@mui/material";
import { Fragment } from "react/jsx-runtime";

import { useAppSelector } from "~/app/store";
import { ternaryIff } from "~/lib/helpers";

import {
  selectPickingState,
  selectCurrentPick
} from "~/redux/selectors/autostoreSelectors";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";

import { useAddedTotes } from "./hooks/useAddedTotes";
import AutostoreTote from "./tote/AutostoreTote";

type AutostorePickingTotesProps = {
  handleToteClick: (args: {
    toteId: string;
    pickId: string;
    reporter?: string;
  }) => Promise<void>;
};

export const AutostorePickingTotes = ({
  handleToteClick
}: AutostorePickingTotesProps) => {
  const { ap_fusionPortScreenEnabled, ap_totesPlacement: totesPlacement } =
    useAppSelector(selectClientConfig);
  const pickingState = useAppSelector(selectPickingState);
  const currentPick = useAppSelector(selectCurrentPick);
  const { originalTotes, addedTotes } = useAddedTotes();

  const totesRowGapStyle = ap_fusionPortScreenEnabled
    ? { marginTop: "20px" }
    : { marginTop: "40px", rowGap: "200px" };

  return (
    <Grid
      container
      item
      direction="row"
      alignItems="flex-start"
      xs={totesPlacement === "split" ? 12 : 8}
      sx={totesRowGapStyle}
    >
      {pickingState &&
        originalTotes.map((tote, i) => {
          const isActiveTote =
            !!currentPick && currentPick.assignedToteId === tote.toteId;

          const currentPickToteIds = pickingState.currentPicks.map(
            (p) => p.assignedToteId
          );

          const findAllAddedTotesFromThisTote = addedTotes
            .filter((addedTote) => addedTote.oldToteId === tote.toteId)
            .sort((a, b) => {
              if (currentPickToteIds.includes(a.toteId)) {
                return 1;
              }
              if (currentPickToteIds.includes(b.toteId)) {
                return -1;
              }
              return 0;
            });

          return (
            <Fragment key={i}>
              <Grid
                item
                xs={totesPlacement === "split" ? 4 : 3}
                key={i}
                style={{
                  width: "100%",
                  marginBottom: 0,
                  position: "relative",
                  left: `${
                    ap_fusionPortScreenEnabled
                      ? ternaryIff(i % 2 === 0, "-4%", "4%")
                      : 0
                  }`,
                  minHeight: ap_fusionPortScreenEnabled ? "300px" : "370px"
                }}
              >
                {/*
                    Originate Tote -> same logic as before
                    New Tote Added During Picking -> we want to stack it on top of originate tote instead of displaying it in last row
                  */}
                {findAllAddedTotesFromThisTote.length === 0 ? (
                  <AutostoreTote
                    toteId={tote.toteId}
                    position={i + 1}
                    externalToteId={tote.externalToteId}
                    isActiveTote={isActiveTote}
                    onClickCb={async () => {
                      await handleToteClick({
                        toteId: tote.toteId,
                        pickId: (!!currentPick && currentPick.pickId) || ""
                      });
                      return null;
                    }}
                    isLastStackedTote
                  />
                ) : (
                  <Grid
                    item
                    style={{
                      position: "relative"
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        style={{
                          position: "absolute",
                          left: "50%",
                          transform: "translateX(-50%)",
                          width: "100%"
                        }}
                      >
                        <AutostoreTote
                          toteId={tote.toteId}
                          position={i + 1}
                          isActiveTote={isActiveTote}
                          onClickCb={async () => {
                            if (!currentPick) return null;
                            await handleToteClick({
                              toteId: tote.toteId,
                              pickId: currentPick.pickId
                            });
                            return null;
                          }}
                        />
                      </Grid>
                      {findAllAddedTotesFromThisTote.map(
                        (addedToteFromThisTote, index) => {
                          const firstCurrentPickAddedTotes =
                            pickingState.currentPicks.find(
                              (pick) =>
                                pick.assignedToteId ===
                                addedToteFromThisTote.toteId
                            );
                          const isActiveToteAddedTote =
                            !!firstCurrentPickAddedTotes;
                          const leftPosition = 50 + index + 1;
                          const topPosition = index + 5;

                          return (
                            <Grid
                              key={`toteId-${addedToteFromThisTote.toteId}`}
                              item
                              style={{
                                position: "absolute",
                                left: `${leftPosition}%`,
                                marginTop: `${topPosition}%`,
                                transform: "translateX(-50%)",
                                width: "100%"
                              }}
                            >
                              <AutostoreTote
                                toteId={addedToteFromThisTote.toteId}
                                position={i + 1}
                                externalToteId={
                                  addedToteFromThisTote.externalToteId
                                }
                                isActiveTote={isActiveToteAddedTote}
                                onClickCb={async () => {
                                  if (!firstCurrentPickAddedTotes) return null;
                                  await handleToteClick({
                                    toteId: addedToteFromThisTote.toteId,
                                    pickId: firstCurrentPickAddedTotes.pickId,
                                    reporter: "Added tote click"
                                  });
                                  return null;
                                }}
                                isLastStackedTote={
                                  index ===
                                  findAllAddedTotesFromThisTote.length - 1
                                }
                              />
                            </Grid>
                          );
                        }
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {/* create space for bin/product column */}
              {totesPlacement === "split" && (i + 1) % 2 === 1 && (
                <Grid item xs={4} />
              )}
            </Fragment>
          );
        })}
    </Grid>
  );
};
